export enum DashboardSection {
    Meetings = 'meetings',
    Integrations = 'integrations',
    Transcripts = 'transcripts',
    AskSpinach = 'ask-spinach',
    AskSpinachPage = 'ask',
    Summaries = 'summaries',
    Drafts = 'drafts',
    Actions = 'actions',
    Account = 'account',
    Admin = 'admin',
    Upload = 'upload',
    Backoffice = 'backoffice',
    Chapters = 'chapters',
}
