import { z } from 'zod';

export const DeepgramTranscriptionRequestSchema = z.object({
    audio_url: z.string(),
    callback_url: z.string().optional(),
    language: z.string().optional(),
    model: z.string().optional(),
    tier: z.string().optional(),
    version: z.string().optional(),
    punctuate: z.boolean().optional(),
    profanity_filter: z.boolean().optional(),
    diarize: z.boolean().optional(),
    smart_format: z.boolean().optional(),
    filler_words: z.boolean().optional(),
    multichannel: z.boolean().optional(),
    alternatives: z.number().optional(),
    numerals: z.boolean().optional(),
    search: z.array(z.string()).optional(),
    replace: z
        .array(
            z.object({
                search: z.string(),
                replace: z.string(),
            })
        )
        .optional(),
    keywords: z
        .array(
            z.object({
                word: z.string(),
                boost: z.number(),
            })
        )
        .optional(),
    detect_language: z.boolean().optional(),
    detect_topics: z.boolean().optional(),
    utterances: z.boolean().optional(),
    summarize: z.boolean().optional(),
    detect_entities: z.boolean().optional(),
    tag: z.string().optional(),
});

export const DeepgramTranscriptionResponseSchema = z.object({
    id: z.string(),
    status: z.enum(['queued', 'processing', 'completed', 'error']),
    audio_url: z.string(),
    text: z.string(),
    words: z.array(
        z.object({
            word: z.string(),
            start: z.number(),
            end: z.number(),
            confidence: z.number(),
            speaker: z.number().optional(),
        })
    ),
    utterances: z
        .array(
            z.object({
                speaker: z.number().optional(),
                text: z.string(),
                start: z.number(),
                end: z.number(),
                words: z.array(
                    z.object({
                        word: z.string(),
                        start: z.number(),
                        end: z.number(),
                        confidence: z.number(),
                        speaker: z.number().optional(),
                    })
                ),
            })
        )
        .optional(),
    confidence: z.number(),
    duration: z.number(),
    error: z.string().optional(),
});

export const DeepgramWebhookResultSchema = z.object({
    channels: z.array(
        z.object({
            alternatives: z.array(
                z.object({
                    transcript: z.string(),
                    confidence: z.number(),
                    words: z.array(
                        z.object({
                            word: z.string(),
                            start: z.number(),
                            end: z.number(),
                            confidence: z.number(),
                            speaker: z.number(),
                            speaker_confidence: z.number(),
                        })
                    ),
                })
            ),
        })
    ),
});

export const DeepgramWebhookSchema = z.object({
    metadata: z.object({
        request_id: z.string(),
        duration: z.number(),
    }),
    results: DeepgramWebhookResultSchema,
});

export type DeepgramTranscriptionRequest = z.infer<typeof DeepgramTranscriptionRequestSchema>;
export type DeepgramTranscriptionResponse = z.infer<typeof DeepgramTranscriptionResponseSchema>;
export type DeepgramWebhookPayload = z.infer<typeof DeepgramWebhookSchema>;
