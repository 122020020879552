import { Tab, Tabs } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';

import { TimelineItem, VideoAgentControlCommand } from '@spinach-shared/types';

import { AgendaContent } from './AgendaContent';
import { Assistant } from './Assistant';

interface TabContentProps {
    onCommand: (command: VideoAgentControlCommand) => void;
}

const TabContainer = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const StyledTabs = styled(Tabs)`
    margin-bottom: 16px;

    .MuiTabs-indicator {
        background-color: ${(props) => props.theme.primary.midnight};
    }
`;

const StyledTab = styled(Tab)`
    &.MuiTab-root {
        color: ${(props) => props.theme.primary.midnight};
        opacity: 0.7;

        &.Mui-selected {
            color: ${(props) => props.theme.primary.midnight};
            opacity: 1;
        }
    }
`;

export function TabContent({ onCommand }: TabContentProps) {
    const [currentTab, setCurrentTab] = useState(0);

    const [timeline, setTimeline] = useState<TimelineItem[]>([]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setCurrentTab(newValue);
    };

    return (
        <TabContainer>
            <StyledTabs value={currentTab} onChange={handleTabChange} centered>
                <StyledTab label="Agenda" />
                <StyledTab label="Assistant" />
            </StyledTabs>

            {currentTab === 0 ? <AgendaContent onCommand={onCommand} /> : null}
            {currentTab === 1 ? (
                <div style={{ flex: 1 }}>
                    <Assistant timeline={timeline} setTimeline={setTimeline} />
                </div>
            ) : null}
        </TabContainer>
    );
}
