import { z } from 'zod';

import { CheckoutBillingScope, CheckoutSessionPlanType } from '@spinach-shared/types';

export const BaseBillingPlanSchema = z.object({
    latestUsageForPeriodInMinutes: z.number(),
    latestUsageForPeriodInMinutesForUser: z.number(),
    currentPeriodStart: z.string().datetime(),
    currentPeriodEnd: z.string().datetime(),
    checkoutBillingScope: z.nativeEnum(CheckoutBillingScope),
});

export const BillingPlanSchema = z.discriminatedUnion('checkoutSessionPlanType', [
    BaseBillingPlanSchema.extend({
        checkoutSessionPlanType: z.literal(CheckoutSessionPlanType.SeatBased),
        numberOfSeats: z.number(),
    }),
    BaseBillingPlanSchema.extend({
        checkoutSessionPlanType: z.literal(CheckoutSessionPlanType.UsageBundle),
        usageBundleQuantityLeftInHours: z.number(),
    }),
    BaseBillingPlanSchema.extend({
        checkoutSessionPlanType: z.literal(CheckoutSessionPlanType.PayAsYouGo),
    }),
    BaseBillingPlanSchema.extend({
        checkoutSessionPlanType: z.literal(CheckoutSessionPlanType.ManuallyManaged),
    }),
]);

export type BillingPlan = z.infer<typeof BillingPlanSchema>;
