import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';

import { lightTheme, muiTheme } from '../../styles';
import { AbstractBackground } from '../onboarding';
import { AutoReloadingErrorBoundary } from './AutoReloadingErrorBoundary';
import { SpinachStartup } from './SpinachStartup';

// Create a client
const queryClient = new QueryClient();

export function SpinachApp(): JSX.Element {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <AutoReloadingErrorBoundary>
                    <RecoilRoot>
                        <ThemeProvider theme={lightTheme}>
                            <MuiThemeProvider theme={muiTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DndProvider backend={HTML5Backend}>
                                        <QueryClientProvider client={queryClient}>
                                            <SpinachStartup />
                                        </QueryClientProvider>
                                        <AbstractBackground />
                                    </DndProvider>
                                </MuiPickersUtilsProvider>
                            </MuiThemeProvider>
                        </ThemeProvider>
                    </RecoilRoot>
                </AutoReloadingErrorBoundary>
            </BrowserRouter>
        </React.StrictMode>
    );
}
