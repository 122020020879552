import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientEventType } from '@spinach-shared/types';

import { useActivityTracking, useGlobalVideoAgent } from '../../hooks';
import { lightTheme } from '../../styles/theme';
import { AgentCommandType } from '../../types/agent';
import { Row } from '../common';
import { DropDown } from '../common/DropDown';

interface MeetingOption {
    botId: string;
    videoToken: string;
    seriesId: string;
    meetingTitle: string;
}

interface MeetingSelectionProps {
    botId: string;
    availableMeetings: MeetingOption[];
}

export function MeetingSelection({ botId, availableMeetings }: MeetingSelectionProps) {
    const navigate = useNavigate();
    const trackActivity = useActivityTracking();
    const { session } = useGlobalVideoAgent();

    return (
        <Row centered={true} style={{ marginBottom: '20px' }}>
            <DropDown
                title="Select Meeting"
                values={availableMeetings.map((meeting) => ({
                    code: meeting.botId,
                    value: meeting.meetingTitle,
                    label: meeting.meetingTitle,
                }))}
                selected={availableMeetings.find((meeting) => meeting.botId === botId)?.meetingTitle}
                handleSelection={(newBotId) => {
                    if (typeof newBotId === 'string') {
                        navigate(`/control/${newBotId}`);
                        trackActivity(ClientEventType.VideoAgentActivity, 'Change Meeting', {
                            TriggeredBy: AgentCommandType.RemoteControl,
                            ...session?.analyticsPayload,
                        });
                    }
                }}
                style={{
                    minWidth: '200px',
                    marginBottom: '20px',
                    borderRadius: '4px',
                }}
                buttonProps={{
                    style: {
                        borderRadius: '4px',
                        border: `1px solid ${lightTheme.neutrals.grayDark}`,
                        padding: '8px 16px',
                        width: '100%',
                        boxShadow: 'none',
                    },
                }}
            />
        </Row>
    );
}
