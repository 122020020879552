import { TranscriptionService } from './Scribe';

export enum FileUploadStatus {
    Pending = 'pending',
    Transcribing = 'transcribing',
    Processing = 'processing',
    Error = 'error',
    Completed = 'completed',
}

export interface FileUploadParticipant {
    userId?: string;
    name: string;
}

export interface FileUpload {
    _id: string;
    status: FileUploadStatus;
    uploaderUserId?: string;
    participants: FileUploadParticipant[];
    transcript: AssemblyAITranscript | DeepgramTranscript;
    fileSize: number;
    errorMessage?: string;
    fileName?: string;
    generatedTitle?: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface AssemblyAITranscript {
    id: string;
    kind: TranscriptionService.AssemblyAI;
}

export interface DeepgramTranscript {
    id: string;
    kind: TranscriptionService.Deepgram;
}

export interface FileUploadResponse {
    s3PostResponse: {
        url: string;
        /*
            key, bucket, X-Amz-Algorithm, X-Amz-Credential, X-Amz-Date, Policy, X-Amz-Signature
        */
        fields: Record<string, string>;
    };
    fileUploadId: string;
}
