import { atom } from 'recoil';

import { AddScribeToAllMeetingKind } from '@spinach-shared/types';

export type MeetingAutomationInProgressState = {
    kind: AddScribeToAllMeetingKind | null;
};

export const atomMeetingAutomationInProgressState = atom<MeetingAutomationInProgressState>({
    key: 'meetingAutomationInProgressState',
    default: {
        kind: null,
    },
});
