import { Error } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { useSearchParams } from 'react-router-dom';

import { ClientUser } from '@spinach-shared/models';
import { ClientEventType, DemoVersion, IClientUser, WebUrlQuery } from '@spinach-shared/types';
import { getWebAppHost } from '@spinach-shared/utils';

import { postExperienceEvent, postVerifyGoogle } from '../../apis';
import { DemoModal } from '../../atoms';
import {
    useGlobalDemoState,
    useGlobalModal,
    useGlobalRouting,
    useGlobalUser,
    useLocationalSeriesId,
    useSeriesReality,
} from '../../hooks';
import { lightTheme } from '../../styles';
import { AnonymousUserTracker } from '../../utils';
import { TagManager } from '../../utils/TagManager';
import { getClientConfigValue } from '../../utils/getClientConfigValue';
import { Notification } from '../stand-up';
import { GoogleLogin } from './react-google-login';

export function LoginWithGoogle({
    label,
    variant,
    onSuccessCallback,
    buttonStyle,
}: {
    label?: string;
    variant?: 'orange' | 'default';
    onSuccessCallback?: (user: IClientUser) => void;
    buttonStyle?: React.CSSProperties;
}) {
    const [, setUser] = useGlobalUser();
    const [, setGlobalModal] = useGlobalModal();
    const [params] = useSearchParams();
    const { setDemoModal } = useGlobalDemoState();
    const { isDemoSeries } = useSeriesReality();
    const [isPrivate, setIsPrivate] = useState(false);
    const deepLinkedSeriesId = useLocationalSeriesId();
    const [notification, setNotification] = useState('');
    const { routeToVerify } = useGlobalRouting();

    useEffect(() => {
        if (notification) {
            setTimeout(() => {
                setNotification('');
            }, 5000);
        }
    }, [notification]);

    const onSuccessNew = async (user: IClientUser, isNewUser: boolean) => {
        if (onSuccessCallback) {
            onSuccessCallback(user);
        }
        setUser(user);

        const validatedUser = new ClientUser(user);

        AnonymousUserTracker.trackUser(user._id);
        AnonymousUserTracker.trackEvent(ClientEventType.UserEmailGoogleVerifySuccess);
        await postExperienceEvent({
            eventType: ClientEventType.UserEmailGoogleVerifySuccessBackend,
            payload: validatedUser.toUserIdentityPayload(),
        });

        if (isNewUser) {
            TagManager.trackSignUp(validatedUser);
            TagManager.trackGoogleWebAppScribeSignup(validatedUser);
        }

        if (validatedUser.shouldAuthBeforeDemo) {
            // hide auth modal and show context modal
            setDemoModal(DemoModal.PrepareCheckIn);
        } else if (user.metadata?.isAnonymousUser === false) {
            window.location.href = getWebAppHost();
        }
    };

    const onSuccess = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
        setNotification('');
        if ('tokenId' in res) {
            const response = await postVerifyGoogle(
                res.tokenId,
                params.get(WebUrlQuery.Experiment),
                deepLinkedSeriesId,
                (params.get(WebUrlQuery.DemoVersion) as DemoVersion) ?? undefined
            );
            if (response.user) {
                onSuccessNew(response.user, response.isNewUser ?? false);
            } else if (response.isSsoRequired) {
                routeToVerify(
                    undefined,
                    new URLSearchParams({
                        [WebUrlQuery.SSO]: 'true',
                    })
                );
            }

            // ensure that the web auth router is hidden for existing users logging in via play mode
            setGlobalModal(null);
        }
    };

    const onFailure = (res: { error: string }) => {
        if (res?.error === 'popup_closed_by_user' && isPrivate) {
            setNotification('Sign in with Google not supported on private browsing');
        } else {
            setNotification('Try signing in with Google again or using email verification');
        }
        AnonymousUserTracker.trackEvent(ClientEventType.UserEmailGoogleVerifyFailure, {
            responseCode: res.error,
        });
    };

    const buttonClick = () => {
        AnonymousUserTracker.trackEvent(ClientEventType.UserClickSignInWithGoogle);
    };

    const buttonText = isDemoSeries ? 'Sign up with Google' : 'Sign in with Google';

    return (
        <div
            id="signInButton"
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
            onClick={buttonClick}
        >
            <GoogleLogin
                label={label}
                variant={variant}
                clientId={getClientConfigValue('REACT_APP_GOOGLE_CLIENT_ID')}
                buttonText={buttonText}
                onSuccess={async (response) => {
                    await onSuccess(response);
                }}
                onSuccessNew={onSuccessNew}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn={false}
                onScriptLoadFailure={(...args: any[]) => {
                    args.forEach((arg) => {
                        if (arg?.error === 'idpiframe_initialization_failed') {
                            setIsPrivate(true);
                        }
                    });
                }}
                buttonStyle={buttonStyle}
            />
            <div style={{ position: 'relative', width: '100%' }}>
                <Notification
                    containerStyle={{ position: 'absolute', bottom: 'unset', top: '15px' }}
                    isOpen={!!notification}
                    onClose={() => null}
                    message={notification}
                    icon={<Error style={{ color: lightTheme.neutrals.white }} htmlColor={lightTheme.neutrals.white} />}
                />
            </div>
        </div>
    );
}
