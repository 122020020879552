import { useCallback } from 'react';

import { getCommandContents, getNewAgentTopic } from '@spinach-shared/models';
import { ClientEventType, PostVideoAgentChatMessageRequest } from '@spinach-shared/types';

import { AGENT_SAYINGS, patchVideoAgentSession, postVideoAgentChatMessage } from '../../../..';
import { AgentCommandType, SayFunction } from '../../../types/agent';
import { useActivityTracking } from '../../useExperienceTracking';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';

export function getTopicToAdd(message: string): string {
    const supportedCommandTriggers = [
        '-',
        'add ',
        '/topic add ',
        '/ta ',
        '/topic ',
        'topic:',
        '/spinach add ',
        'spinach add ',
    ];

    const { remainingCommand: topicTextToAdd } = getCommandContents(message, supportedCommandTriggers);

    return topicTextToAdd;
}

export function useAddTopic(say: SayFunction) {
    const {
        state: { session, config },
        setSession,
    } = useGlobalVideoAgent();
    const trackActivity = useActivityTracking();

    return useCallback(
        async (topicName: string, triggerType = AgentCommandType.Voice) => {
            if (!session) {
                return;
            }

            const newTopic = getNewAgentTopic(topicName);

            const updatedSession = session.withAddedTopic(newTopic);

            setSession(updatedSession);

            let chatPayload: PostVideoAgentChatMessageRequest | null = null;

            if (updatedSession?.agenda.currentTopicId) {
                chatPayload = {
                    message: `Got it! Here's the updated agenda:\n${updatedSession.agenda.topics
                        .map((topic, i) => `${i + 1}. ${topic.title}`)
                        .join(`\n`)}`,
                    botId: updatedSession.botId,
                };
                trackActivity(ClientEventType.VideoAgentActivity, 'Topic Added', {
                    When: 'After Agenda Started',
                    TriggeredBy: triggerType,
                    ...updatedSession?.analyticsPayload,
                });
            } else {
                chatPayload = {
                    message: `"${newTopic.title}" is on the agenda!`,
                    botId: updatedSession.botId,
                };
                trackActivity(ClientEventType.VideoAgentActivity, 'Topic Added', {
                    When: 'Before Agenda Started',
                    TriggeredBy: triggerType,
                    ...updatedSession?.analyticsPayload,
                });
            }

            await Promise.all([
                patchVideoAgentSession(updatedSession.toJSON()),
                // chatPayload ? postVideoAgentChatMessage(chatPayload) : undefined,
            ]);
        },
        [session, setSession, trackActivity, config]
    );
}
