import { Box } from '@material-ui/core';
import { CallMade } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';
import { isProductionStage } from '@spinach-shared/utils';

import { ReactComponent as SpinachLogo } from '../../../assets/logo-spinachio.svg';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalSearchShortcut,
    useSidebar,
    useStripeUpgradeInAppEnablement,
} from '../../../hooks';
import { useGlobalDrafts } from '../../../hooks/useGlobalDrafts';
import { lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { Column, Hairline, Row, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { DashboardSelectorIcons } from './DashboardSelectorIcons';
import { ProfileComponent } from './NavBar';
import { ShowcaseGuide } from './ShowcaseGuide';
import { DashboardComponent } from './types';
import { isSummariesAndTranscriptsSection } from './utils';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

function SearchBox(): JSX.Element {
    const { setIsSearchVisible, isSearchVisible } = useGlobalAiDashboard();
    const { isMac } = useGlobalSearchShortcut('');
    const track = useExperienceTracking();

    return (
        <Box
            style={{
                padding: 5,
                cursor: 'pointer',
                marginBottom: '20px',
                border: '1px solid rgba(160, 160, 160, 0.3)',
                minWidth: '60%',
                width: 'calc(100% - 10px)',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                backgroundColor: 'white',
                transition: 'background-color 0.2s ease',
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
            }}
            onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = 'white';
            }}
            onClick={() => {
                setIsSearchVisible(!isSearchVisible);
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: 'Search Meeting Notes in Sidebar',
                });
            }}
        >
            <SearchIcon style={{ color: '#A0A0A0' }} />
            <span style={{ color: 'gray', marginLeft: 5 }}>
                {`Search notes `}
                {isMac ? `(⌘ + K)` : `(Ctrl + K)`}
            </span>
        </Box>
    );
}

export function Sidebar({
    activeSection,
    setActiveSection,
    setIsAddingAdditionalMeetings,
    shouldShowShowcaseGuide,
}: DashboardComponent & {
    setIsAddingAdditionalMeetings: (val: boolean) => void;
    shouldShowShowcaseGuide: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [drafts] = useGlobalDrafts();
    const track = useExperienceTracking();
    const isStripeUpgradeInAppEnabled = useStripeUpgradeInAppEnablement();
    const { fullSidebarWidth, userShouldSeeBothApps, appBarWidth, sidebarWidth, isDesktopView } = useSidebar();

    return (
        <Row
            style={{
                width: fullSidebarWidth,
                height: '100%',
                marginRight: '20px',
            }}
        >
            {userShouldSeeBothApps && isDesktopView ? (
                <Column
                    style={{
                        height: '100%',
                        width: `${appBarWidth}px`,
                        backgroundColor: `#E2E8E8`,
                        zIndex: 1,
                        paddingRight: '5px',
                        paddingLeft: '5px',
                    }}
                >
                    <DashboardSelectorIcons />
                </Column>
            ) : null}
            <Column
                style={{
                    height: '100%',
                    width: `${sidebarWidth}px`,
                    backgroundColor: `${lightTheme.neutrals.grayLight}`,
                    zIndex: 1,
                    position: 'relative',
                }}
            >
                <SidebarColumn>
                    {isDesktopView ? (
                        <SpinachLogo
                            style={{
                                height: isDesktopView ? '35px' : '25px',
                                minHeight: isDesktopView ? '35px' : '25px',
                                cursor: 'pointer',
                                marginLeft: '-35px',
                                marginBottom: '20px',
                                marginTop: '5px',
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Sidebar Logo' });
                                setActiveSection(DashboardSection.Summaries);
                            }}
                        />
                    ) : null}
                    {isDesktopView ? (
                        <>
                            <ProfileComponent />
                            <Spacing factor={1 / 2} />
                        </>
                    ) : null}

                    {isDesktopView ? <SearchBox /> : null}

                    <SecondaryButton
                        title="Meeting History"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{
                            fontSize: '18px',
                            fontWeight: 700,
                            display: 'flex',
                            width: '100%',
                            textAlign: 'left',
                        }}
                        color={
                            isSummariesAndTranscriptsSection(activeSection)
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries & Transcripts Tab' });
                            setActiveSection(DashboardSection.Summaries);
                        }}
                    />
                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Future Meetings"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{
                            fontSize: '18px',
                            fontWeight: 700,
                            display: 'flex',
                            width: '100%',
                            textAlign: 'left',
                        }}
                        color={
                            activeSection === DashboardSection.Meetings
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Meetings Tab' });
                            setActiveSection(DashboardSection.Meetings);
                            setIsAddingAdditionalMeetings(false);
                        }}
                    />

                    {user.isAskSpinachPageEnabled ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <SecondaryButton
                                title="Ask Spinach"
                                buttonStyles={{ textDecoration: 'none' }}
                                labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                color={
                                    activeSection === DashboardSection.AskSpinachPage
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Ask Spinach Page' });
                                    setActiveSection(DashboardSection.AskSpinachPage);
                                }}
                            />
                        </>
                    ) : null}
                    {user.isEnabledForDraftsSection ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <SecondaryButton
                                title={
                                    <Row vCenter>
                                        Drafts{' '}
                                        {!!drafts?.length ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginLeft: '6px',
                                                    color: 'white',
                                                    width: '27px',
                                                    height: '27px',
                                                    borderRadius: '50%',
                                                    fontSize:
                                                        drafts.length < 10
                                                            ? '18px'
                                                            : drafts.length < 100
                                                            ? '16px'
                                                            : '12px',
                                                    backgroundColor: lightTheme.primary.greenLight,
                                                }}
                                            >
                                                {drafts.length > 99 ? '99+' : drafts.length}
                                            </div>
                                        ) : null}
                                    </Row>
                                }
                                buttonStyles={{ textDecoration: 'none' }}
                                labelStyles={{
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    display: 'flex',
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                                color={
                                    activeSection === DashboardSection.Drafts
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Drafts Tab',
                                        NumberOfDrafts: drafts?.length,
                                    });
                                    setActiveSection(DashboardSection.Drafts);
                                }}
                            />
                        </>
                    ) : null}
                    {user.isEnabledForFileUpload ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <SecondaryButton
                                title="Upload"
                                buttonStyles={{ textDecoration: 'none' }}
                                labelStyles={{
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    display: 'flex',
                                    width: '100%',
                                    textAlign: 'left',
                                }}
                                color={
                                    activeSection === DashboardSection.Upload
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Upload Tab' });
                                    setActiveSection(DashboardSection.Upload);
                                }}
                            />
                        </>
                    ) : null}
                    <Hairline style={{ width: '70%' }} />
                    <SecondaryButton
                        title="Settings"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                        color={
                            activeSection === DashboardSection.Integrations
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                            setActiveSection(DashboardSection.Integrations);
                        }}
                    />
                    {isStripeUpgradeInAppEnabled ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <SecondaryButton
                                title="Account"
                                buttonStyles={{ textDecoration: 'none' }}
                                labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                color={
                                    activeSection === DashboardSection.Account
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Account Tab' });
                                    setActiveSection(DashboardSection.Account);
                                }}
                            />
                        </>
                    ) : null}

                    {user.hasAdminSectionAccess ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <SecondaryButton
                                title="Admin"
                                buttonStyles={{ textDecoration: 'none' }}
                                labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                color={
                                    activeSection === DashboardSection.Admin
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Admin Tab' });
                                    setActiveSection(DashboardSection.Admin);
                                }}
                            />
                        </>
                    ) : null}

                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Help Center"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                        color={lightTheme.primary.midnight}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                            URLUtil.openURL(getClientConfigValue('INTERCOM_SUPPORT_URL'));
                        }}
                        endIcon={
                            <CallMade
                                style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                                htmlColor={lightTheme.primary.greenLight}
                            />
                        }
                    />
                    {isProductionStage() ? null : (
                        <>
                            <Spacing factor={1 / 2} />
                            <SecondaryButton
                                title="Backoffice"
                                buttonStyles={{ textDecoration: 'none' }}
                                labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                                color={
                                    activeSection === DashboardSection.Backoffice
                                        ? lightTheme.primary.greenLight
                                        : lightTheme.primary.midnight
                                }
                                onClick={() => {
                                    setActiveSection(DashboardSection.Backoffice);
                                    track(ClientEventType.AIDashboardClick, { ClickedOn: 'Backoffice Tab' });
                                }}
                            />
                        </>
                    )}
                </SidebarColumn>
                {shouldShowShowcaseGuide ? (
                    <div style={{ padding: '12px' }}>
                        <ShowcaseGuide />
                    </div>
                ) : null}
            </Column>
        </Row>
    );
}
