import { z } from 'zod';

export type AskSpinachLiveRequest = {
    freeform: string;
    botId: string;
    spinachUserId: string;
    seriesId: string;
    conversationHistory?: ConversationHistory;
};

export const ConversationHistorySchema = z
    .array(
        z.object({
            role: z.enum(['user', 'assistant']),
            content: z.string(),
            timestamp: z.string(),
        })
    )
    .max(21);

export type ConversationHistory = z.infer<typeof ConversationHistorySchema>;
