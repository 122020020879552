import { CallMade } from '@material-ui/icons';
import styled from 'styled-components';

import { DashboardSection } from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { ReactComponent as SpinachLogo } from '../../../assets/logo-spinachio.svg';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalRouting, useSidebar } from '../../../hooks';
import { lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { Column, Row, Spacing } from '../../common';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { DashboardSelectorIcons } from './DashboardSelectorIcons';
import { ProfileComponent } from './NavBar';
import { DashboardComponent } from './types';

const SidebarColumn = styled(Column)`
    padding: 20px;
    height: 100%;
    width: calc(100% - 40px);
`;

export function StandupAppSidebar({ activeSection, setActiveSection }: DashboardComponent): JSX.Element {
    const track = useExperienceTracking();
    const { routeToDemoExperience } = useGlobalRouting();
    const [user] = useGlobalAuthedUser();
    const { fullSidebarWidth, isDesktopView, userShouldSeeBothApps, appBarWidth, sidebarWidth } = useSidebar();

    return (
        <Row
            style={{
                width: fullSidebarWidth,
                height: '100%',
            }}
        >
            {userShouldSeeBothApps && isDesktopView ? (
                <Column
                    style={{
                        height: '100%',
                        width: `${appBarWidth}px`,
                        backgroundColor: `#E2E8E8`,
                        zIndex: 1,
                        paddingRight: '5px',
                        paddingLeft: '5px',
                    }}
                >
                    <DashboardSelectorIcons />
                </Column>
            ) : null}
            <Column
                style={{
                    height: '100%',
                    width: `${sidebarWidth}px`,
                    backgroundColor: `${lightTheme.neutrals.grayLight}`,
                    zIndex: 1,
                    marginRight: '20px',
                }}
            >
                <SidebarColumn>
                    {isDesktopView ? (
                        <SpinachLogo
                            style={{
                                height: '35px',
                                cursor: 'pointer',
                                marginLeft: '-35px',
                                marginBottom: '20px',
                                marginTop: '5px',
                            }}
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, { ClickedOn: 'Sidebar Logo' });
                                setActiveSection(DashboardSection.Summaries);
                            }}
                        />
                    ) : null}
                    {isDesktopView ? (
                        <>
                            <ProfileComponent />
                            <Spacing factor={1 / 2} />
                        </>
                    ) : null}
                    <SecondaryButton
                        title={`Your ${user.meetingWord}s`}
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{
                            fontSize: '18px',
                            fontWeight: 700,
                            display: 'flex',
                            width: '100%',
                            textAlign: 'left',
                        }}
                        color={
                            activeSection === DashboardSection.Meetings
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Standups Tab' });
                            setActiveSection(DashboardSection.Meetings);
                        }}
                    />
                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Summaries"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{
                            fontSize: '18px',
                            fontWeight: 700,
                            display: 'flex',
                            width: '100%',
                            textAlign: 'left',
                        }}
                        color={
                            activeSection === DashboardSection.Summaries
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Summaries Tab' });
                            setActiveSection(DashboardSection.Summaries);
                        }}
                    />
                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Integrations"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{
                            fontSize: '18px',
                            fontWeight: 700,
                            display: 'flex',
                            width: '100%',
                            textAlign: 'left',
                        }}
                        color={
                            activeSection === DashboardSection.Integrations
                                ? lightTheme.primary.greenLight
                                : lightTheme.primary.midnight
                        }
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Integrations Tab' });
                            setActiveSection(DashboardSection.Integrations);
                        }}
                    />
                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Practice Round"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                        color={lightTheme.primary.midnight}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Practice Round Tab' });
                            routeToDemoExperience();
                        }}
                        endIcon={
                            <CallMade
                                style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                                htmlColor={lightTheme.primary.greenLight}
                            />
                        }
                    />

                    <Spacing factor={1 / 2} />
                    <SecondaryButton
                        title="Help Center"
                        buttonStyles={{ textDecoration: 'none' }}
                        labelStyles={{ fontSize: '18px', fontWeight: 700 }}
                        color={lightTheme.primary.midnight}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, { ClickedOn: 'Help Center Link' });
                            URLUtil.openURL(getClientConfigValue('INTERCOM_SUPPORT_URL_STANDUP_APP'));
                        }}
                        endIcon={
                            <CallMade
                                style={{ fontSize: '16px', marginLeft: '5px', marginBottom: '-2px' }}
                                htmlColor={lightTheme.primary.greenLight}
                            />
                        }
                    />
                </SidebarColumn>
            </Column>
        </Row>
    );
}
