import { z } from 'zod';

import { AccountJSON, AccountTier } from './Account';
import { CheckoutBillingScope } from './Billing';
import { UsageBundleDocumentJSON } from './Bundles';
import { TeamSubscription } from './TeamSubscription';
import { User } from './User';

export const PlanTypeSchema = z.enum(['business', 'pro']);
export const PlanScopeSchema = z.enum([CheckoutBillingScope.Company, CheckoutBillingScope.Team]);
export const ProPlanTypeSchema = z.enum(['payAsYouGo', 'bundles']);

export enum CheckoutSessionPlanType {
    Free = 'free',
    Trial = 'trial',
    UsageBundle = 'usage-bundle',
    SeatBased = 'seat-based',
    OriginalSeatBased = 'original-seat-based',
    PayAsYouGo = 'pay-as-you-go',
    ManuallyManaged = 'manually-managed',
    OriginalCapped = 'original-capped',
}

export const BundleTypeSchema = z.object({
    priceId: z.string(),
    price: z.number(),
    hours: z.number(),
});
export const StripePlansSchema = z.object({
    business: z.object({
        priceId: z.string(),
    }),
    pro: z.object({
        payAsYouGo: z.object({
            priceId: z.string(),
            pricePerHour: z.number(),
        }),
        bundles: z.record(BundleTypeSchema),
    }),
});

export type PlanType = z.infer<typeof PlanTypeSchema>;
export type PlanScope = z.infer<typeof PlanScopeSchema>;
export type ProPlanType = z.infer<typeof ProPlanTypeSchema>;
export type BundleType = z.infer<typeof BundleTypeSchema>;

export type StripePlans = z.infer<typeof StripePlansSchema>;

type BasePlanContext = {
    user: User;
    stripeCustomerId?: string;
    stripeSubscriptionId?: string;
};

export type TeamPlanContext = {
    checkoutBillingScope: CheckoutBillingScope.Team;
    teamPayer: User;
    teamSubscription: TeamSubscription;
};

export type CompanyPlanContext = {
    checkoutBillingScope: CheckoutBillingScope.Company;
    rootDomain: string;
    account: AccountJSON;
};

export type IndividualPlanContext = {
    checkoutBillingScope: CheckoutBillingScope.Individual;
};

export type ScopedPlanContext = BasePlanContext & (TeamPlanContext | CompanyPlanContext | IndividualPlanContext);

export type UsageBundlePlanContext = {
    checkoutSessionPlanType: CheckoutSessionPlanType.UsageBundle;
    usageBundle: UsageBundleDocumentJSON;
};

export type SeatBasedPlanContext = {
    checkoutSessionPlanType: CheckoutSessionPlanType.SeatBased;
    numberOfSeats: number;
};

export type PayAsYouGoPlanContext = {
    checkoutSessionPlanType: CheckoutSessionPlanType.PayAsYouGo;
};

export type ManuallyManagedPlanContext = {
    checkoutSessionPlanType: CheckoutSessionPlanType.ManuallyManaged;
    tier: AccountTier;
};

export type OriginalSeatBasedPlanContext = {
    checkoutSessionPlanType: CheckoutSessionPlanType.OriginalSeatBased;
};

export type OriginalCappedPlanContext = {
    checkoutSessionPlanType: CheckoutSessionPlanType.OriginalCapped;
};

export type TypedPlanContext =
    | UsageBundlePlanContext
    | SeatBasedPlanContext
    | PayAsYouGoPlanContext
    | ManuallyManagedPlanContext
    | OriginalSeatBasedPlanContext
    | OriginalCappedPlanContext;

export type PlanContext = ScopedPlanContext & TypedPlanContext;
