import { useState } from 'react';

import { useGlobalAuthedUser } from '../../../..';
import { UserAccountButton, ViewContainer } from '../../../common';
import { OnboardingStep } from '../common';
import { StepTrackerOnboardingFlow } from '../step-tracker-onboarding/StepTrackerOnboardingFlow';
import { AgentGuide } from './AgentGuide';

export function AgentOnboardingFlowContainer(): JSX.Element {
    const STARTING_STEP = OnboardingStep.About;
    const [user] = useGlobalAuthedUser();
    const [onboardingStep, setOnboardingStep] = useState<OnboardingStep>(STARTING_STEP);
    const shouldShowAgentGuide =
        [OnboardingStep.About, OnboardingStep.AddSpinachToMeetings, OnboardingStep.CalendarPermissions].includes(
            onboardingStep
        ) && user.isEnabledForAgentOnboarding;

    return (
        <ViewContainer>
            <UserAccountButton isOnboardingFlow={true} />
            <StepTrackerOnboardingFlow
                onboardingStep={onboardingStep}
                setOnboardingStep={setOnboardingStep}
                startingStep={STARTING_STEP}
            />
            {shouldShowAgentGuide ? <AgentGuide onboardingStep={onboardingStep} /> : null}
        </ViewContainer>
    );
}
