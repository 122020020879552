export enum VideoAgentControlAction {
    Next = 'next',
    Previous = 'previous',
    AddTopic = 'addTopic',
    EditTopic = 'editTopic',
    DeleteTopic = 'deleteTopic',
    ToggleRecording = 'toggleRecording',
    ToggleRoundtable = 'toggleRoundtable',
    ToggleAudioAcknowledgementDisabled = 'toggleAudioAcknowledgementDisabled',
    ReorderTopics = 'reorderTopics',
    PlayVideo = 'playVideo',
    JumpToTopic = 'jumpToTopic',
    ToggleAudioTimeCheckDisabled = 'toggleAudioTimeCheckDisabled',
    Stop = 'stop',
}

export type VideoAgentControlCommand =
    | { action: VideoAgentControlAction.Next }
    | { action: VideoAgentControlAction.Previous }
    | { action: VideoAgentControlAction.AddTopic; topicName: string }
    | { action: VideoAgentControlAction.EditTopic; topicId: string; topicName: string }
    | { action: VideoAgentControlAction.DeleteTopic; topicId: string }
    | { action: VideoAgentControlAction.ToggleRecording }
    | { action: VideoAgentControlAction.ToggleRoundtable }
    | { action: VideoAgentControlAction.ToggleAudioAcknowledgementDisabled }
    | { action: VideoAgentControlAction.ReorderTopics; topics: string[] }
    | { action: VideoAgentControlAction.JumpToTopic; topicIndex: number }
    | { action: VideoAgentControlAction.ToggleAudioTimeCheckDisabled }
    | { action: VideoAgentControlAction.PlayVideo; videoUrl: string }
    | { action: VideoAgentControlAction.Stop };

export type TimelineItem = {
    requestId: string;
    botId: string;
    queryId: string;
    freeform?: string;
    createdAt: Date;
    response?: AskSpinachResponse;
};

export type AskSpinachResponse = { requestId: string; type: 'success' | 'error' | 'loading'; text?: string };
