import { Box, CircularProgress, IconButton, TextareaAutosize } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import moment from 'moment';
import { useRef, useState } from 'react';
import React from 'react';
import styled from 'styled-components';

import { TimelineItem } from '@spinach-shared/types';

import { ReactComponent as SpinachAvatar } from '../../assets/ask-spinach/Spinach_avatar.svg';
import { useGlobalVideoAgent } from '../../hooks';
import { ButtonSize, lightTheme } from '../../styles';
import { SetValue } from '../../types';
import { Row, Spacing } from '../common';
import {
    AskSpinachFrom,
    AskSpinachResponse,
    AskSpinachTextButton,
    AskSpinachUserQuerySection,
    renderMarkdown,
    useAskSpinachOptions,
} from '../spinach-ai/dashboard/summaries/AskSpinach';
import { OutlinedButton } from '../stand-up/OutlinedButton';

const Container = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 220px);
    height: calc(100vh - 40px);
    overflow: hidden;
    max-width: 860px;
`;

const ResponseContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px;
    height: calc(100% - 360px);
`;

const ControlsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    padding: 24px;
    border-top: 1px solid #e0e0e0;

    > div {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const TextAreaContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 0px;
`;

const SendButton = styled(IconButton)`
    position: absolute;
    bottom: 24px;
    right: 12px;
    color: ${lightTheme.primary.green};
    background-color: white;
    padding: 4px;
    &:hover {
        background-color: #f5f5f5;
    }
    z-index: 1;
`;

const FreeFormTextArea = styled(TextareaAutosize)`
    min-height: 80px;
    font-size: 17px;
    padding: 12px;
    padding-right: 48px; /* Make room for the send button */
    border-radius: 5px;
    border: 1px solid ${lightTheme.neutrals.midnight};
    outline: none;
    resize: vertical;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
`;

const QuickActionsRow = styled(Row)`
    justify-content: flex-start;
    margin-bottom: 24px;
    padding: 0 0px;

    @media (max-width: 768px) {
        justify-content: flex-start;
        flex-wrap: wrap;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;

    /* Target the direct child div that wraps the button */
    > div {
        width: auto;
        flex: 0 0 auto;
    }

    /* Target the Row component inside AskSpinachButtonRow */
    .MuiBox-root {
        width: auto;
    }
`;

const AskSpinachResponseSection = ({ askSpinachResponse }: { askSpinachResponse?: AskSpinachResponse }) => {
    return (
        <Box display="flex" flexDirection="column" marginTop="10px" maxWidth="90%">
            {askSpinachResponse ? (
                <Box>
                    {askSpinachResponse.type === 'loading' ? (
                        <Box width="50px" height="50px">
                            <CircularProgress />
                        </Box>
                    ) : askSpinachResponse.type === 'success' && askSpinachResponse.text ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            position="relative"
                            style={{
                                background: '#EEEEEE',
                                borderRadius: '5px',
                                overflow: 'hidden',
                            }}
                            paddingRight="10px"
                        >
                            <Box
                                display="flex"
                                position="relative"
                                style={{
                                    padding: '5px',
                                }}
                            >
                                <SpinachAvatar
                                    style={{
                                        height: '30px',
                                        width: '30px',
                                        marginRight: '10px',
                                        marginLeft: '10px',
                                        marginTop: '10px',
                                        flexShrink: 0,
                                    }}
                                />
                                <Box
                                    style={{
                                        overflow: 'auto',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {renderMarkdown(askSpinachResponse.text)}
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        <Box width="100%">{askSpinachResponse.text ?? 'Failed to get response from Spinach'}</Box>
                    )}
                </Box>
            ) : null}
        </Box>
    );
};

export function Assistant({
    timeline,
    setTimeline,
}: {
    timeline: TimelineItem[];
    setTimeline: SetValue<TimelineItem[]>;
}): JSX.Element {
    const { session } = useGlobalVideoAgent();
    const responseContainerRef = useRef<HTMLDivElement>(null);

    const [askSpinachFreeform, setAskSpinachFreeform] = useState<string | undefined>(undefined);

    const askSpinachOptions = useAskSpinachOptions({ isCurrentSeriesRecurring: true });

    const isFreeForm = true;

    const setAskSpinachResponse = (response: AskSpinachResponse) => {
        setTimeline((timeline) =>
            timeline.map((item) => (item.requestId === response.requestId ? { ...item, response } : item))
        );

        setTimeout(() => {
            if (responseContainerRef.current) {
                responseContainerRef.current.scrollTo({
                    top: responseContainerRef.current.scrollHeight,
                    behavior: 'smooth',
                });
            }
        }, 100);
    };

    const onQuerySubmitted = ({
        requestId,
        botId,
        queryId,
        freeform,
    }: {
        requestId: string;
        botId: string;
        queryId: string;
        freeform?: string;
    }) => {
        setTimeline((timeline) => [
            ...timeline,
            { requestId, botId, queryId, freeform, createdAt: new Date(), response: undefined },
        ]);
        setAskSpinachFreeform('');
    };

    const disableQuickActions = timeline.length > 0 && timeline[timeline.length - 1]?.response === undefined;

    return (
        <Container>
            <Spacing factor={1 / 2} />
            <ResponseContainer ref={responseContainerRef}>
                <Row key="welcome-message" style={{ justifyContent: 'flex-start', marginBottom: '8px' }}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        style={{
                            background: '#EEEEEE',
                            borderRadius: '5px',
                            padding: '10px',
                        }}
                    >
                        Hey, Ask Spinach about this meeting
                    </Box>
                </Row>
                {timeline.map((item) => (
                    <React.Fragment key={item.requestId}>
                        <Row key={`${item.requestId}-query`} style={{ justifyContent: 'flex-end' }}>
                            <AskSpinachUserQuerySection
                                queryName={
                                    askSpinachOptions.find((option) => option.id === item.queryId)?.name ?? item.queryId
                                }
                                freeform={item.freeform}
                                askSpinachFrom={AskSpinachFrom.VideoAgentAssistant}
                            />
                        </Row>
                        <Row
                            key={`${item.requestId}-response`}
                            style={{ justifyContent: 'flex-start', marginBottom: '8px' }}
                        >
                            <AskSpinachResponseSection askSpinachResponse={item.response} />
                        </Row>
                    </React.Fragment>
                ))}
            </ResponseContainer>

            <ControlsContainer>
                <QuickActionsRow>
                    {[
                        { label: 'Good questions to ask' },
                        { label: 'Catch me up' },
                        { label: 'Help me brainstorm ideas' },
                    ].map((action, i) => (
                        <OutlinedButton
                            key={i}
                            onClick={() => {
                                setAskSpinachFreeform(action.label);
                                setTimeout(() => {
                                    document.getElementById(`ask-spinach-button`)?.click();
                                    setAskSpinachFreeform('');
                                }, 100);
                            }}
                            title={action.label}
                            style={{
                                margin: '4px',
                                flex: window.innerWidth <= 768 ? '1 1 auto' : '0 0 auto',
                            }}
                            disabled={disableQuickActions}
                            size={ButtonSize.Small}
                        />
                    ))}
                </QuickActionsRow>

                {isFreeForm ? (
                    <TextAreaContainer>
                        <FreeFormTextArea
                            value={askSpinachFreeform}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    document.getElementById('ask-spinach-button')?.click();
                                    setAskSpinachFreeform('');
                                }
                            }}
                            onChange={(e) => {
                                const value = e.target.value;
                                setAskSpinachFreeform(value);
                            }}
                        />
                        <SendButton
                            style={{
                                position: 'absolute',
                                color: askSpinachFreeform ? lightTheme.primary.green : '#CCCCCC',
                            }}
                            size="small"
                            onClick={() => {
                                document.getElementById('ask-spinach-button')?.click();
                                setAskSpinachFreeform('');
                            }}
                        >
                            <Send fontSize="small" />
                        </SendButton>
                    </TextAreaContainer>
                ) : null}

                <ButtonContainer style={{ height: '0px' }}>
                    <Row style={{ zIndex: -1000 }}>
                        <AskSpinachTextButton
                            conversationHistory={timeline.slice(-20).flatMap((item) => [
                                {
                                    role: 'user',
                                    content: item.freeform ?? '',
                                    timestamp: item.createdAt.toISOString(),
                                },
                                {
                                    role: 'assistant',
                                    content: item.response?.text || '',
                                    timestamp: moment(item.createdAt).add(10, 'seconds').toISOString(),
                                },
                            ])}
                            from={AskSpinachFrom.VideoAgentAssistant}
                            isTalkWithSpinachSelected={false}
                            setIsTalkWithSpinachSelected={() => {}}
                            askSpinachQuery={'ask-spinach-live'}
                            isFreeForm={true}
                            isDisabled={false}
                            askSpinachFreeform={askSpinachFreeform}
                            setAskSpinachResponse={setAskSpinachResponse}
                            botId={session.botId}
                            onQuerySubmitted={onQuerySubmitted}
                        />
                    </Row>
                </ButtonContainer>
            </ControlsContainer>
        </Container>
    );
}
