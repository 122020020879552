import { Box } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useEffect, useState } from 'react';
import { z } from 'zod';

import { BillingPlan, BillingPlanSchema } from '@spinach-shared/schemas';
import { CheckoutBillingScope, CheckoutSessionPlanType, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../../../../apis';
import { useGlobalAiDashboard } from '../../../../hooks';
import { BodyRegularOnboard } from '../../../../styles';
import { LoadingSquares, Row } from '../../../common';

export const PayingAccountDetails = () => {
    const { setToastText } = useGlobalAiDashboard();
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState<BillingPlan | null>(null);
    useEffect(() => {
        (async () => {
            try {
                const payingUserBillingData = BillingPlanSchema.parse(
                    await getSpinachAPI(SpinachAPIPath.UserBilling, { throwOnError: true })
                );
                setData(payingUserBillingData);
            } catch (e) {
                setToastText('Error fetching data. Please try again later.');
            } finally {
                setLoading(false);
            }
        })();
    }, [setToastText]);

    if (isLoading) {
        return <LoadingSquares />;
    }

    if (!data) {
        return <></>;
    }

    return (
        <>
            <BodyRegularOnboard>
                You are on the{' '}
                <b>
                    {data.checkoutBillingScope === CheckoutBillingScope.Team ? 'Team' : 'Company'}{' '}
                    {data.checkoutSessionPlanType === CheckoutSessionPlanType.SeatBased ? 'Business' : 'Pro'}
                </b>{' '}
                plan.
            </BodyRegularOnboard>
            {data.checkoutSessionPlanType === CheckoutSessionPlanType.UsageBundle ? null : (
                <Row>
                    <BodyRegularOnboard>
                        <Box mt={2}>
                            Current Cycle:{' '}
                            <b>
                                {moment(data.currentPeriodStart).format('LL')} -{' '}
                                {moment(data.currentPeriodEnd).format('LL')}
                            </b>
                        </Box>
                    </BodyRegularOnboard>
                </Row>
            )}
        </>
    );
};
