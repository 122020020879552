import { Modal } from '@material-ui/core';
import { CalendarToday, Check as CheckIcon } from '@material-ui/icons';

import { ClientEventType } from '@spinach-shared/types';

import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { BodyBig, BodyRegular, BodyRegularOnboard, HeaderThreeOnboard } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Hairline, Row } from '../framing';

export function FinishedActivatingTrialModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();

    const closeModal = () => {
        setGlobalModal(null);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Finished Activating Trial Modal',
        });
    };

    if (globalModal !== GlobalModal.FinishedActivatingTrial) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.FinishedActivatingTrial} onClose={closeModal}>
            <SpinachModalContent style={{ overflow: 'hidden', minHeight: 'unset' }}>
                <Column style={{ alignItems: 'center' }}>
                    <HeaderThreeOnboard style={{ textAlign: 'center', marginBottom: '8px' }}>
                        You're all set 🥳
                    </HeaderThreeOnboard>
                    <BodyBig style={{ textAlign: 'center', marginBottom: '15px', fontWeight: 'bold' }}>
                        Spinach Pro features are enabled for the next {user.reverseTrialDaysLeft} days!
                    </BodyBig>

                    <Column style={{ gap: '5px', marginBottom: '32px', maxWidth: '400px' }}>
                        <Row>
                            <BodyRegular style={{ fontWeight: 600 }}>What you get:</BodyRegular>
                        </Row>
                        <Row style={{ alignItems: 'center', gap: '12px' }}>
                            <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                            <BodyRegularOnboard>Unlimited meeting hours</BodyRegularOnboard>
                        </Row>

                        <Row style={{ alignItems: 'center', gap: '12px' }}>
                            <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                            <BodyRegularOnboard>Advanced AI Summaries</BodyRegularOnboard>
                        </Row>

                        <Row style={{ alignItems: 'center', gap: '12px' }}>
                            <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                            <BodyRegularOnboard>Ask Spinach - AI assistant</BodyRegularOnboard>
                        </Row>

                        <Row style={{ alignItems: 'center', gap: '12px' }}>
                            <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                            <BodyRegularOnboard>All integrations</BodyRegularOnboard>
                        </Row>
                        <Hairline />

                        <Row style={{ alignItems: 'center', gap: '12px', marginBottom: '32px' }}>
                            <CalendarToday style={{ color: '#4CAF50', fontSize: '24px' }} />
                            <Column style={{ alignItems: 'flex-start' }}>
                                <BodyRegular style={{ fontSize: '18px' }}>
                                    Spinach has been added to your meetings
                                </BodyRegular>
                                <BodyRegular>
                                    (you can modify this in the <b>Future Meetings</b> page)
                                </BodyRegular>
                            </Column>
                        </Row>
                    </Column>

                    <PrimaryButton
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Confirmed Finished Activating Trial Modal',
                            });
                            closeModal();
                        }}
                        title="Let's go"
                    />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}
