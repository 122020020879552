import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';

import {
    CUSTOMER_SUCCESS_DEPARTMENT_CODE,
    MEETING_LANGAUGES,
    MEETING_LANGUAGE_SEPARATOR,
} from '@spinach-shared/constants';
import { CompanySize } from '@spinach-shared/constants';
import { ClientEventType, FeatureToggle } from '@spinach-shared/types';

import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useLandingAnalytic,
    useLanguageSelectionInDashboardEnablement,
    useWindowSize,
} from '../../../../hooks';
import { BodyBigOnboard, BodyRegularOnboard, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { URLUtil, withContentMasking } from '../../../../utils';
import { Anchor, Column, DropDown, Row, Spacing } from '../../../common';
import { useSpinachInputStyles } from '../../../input';
import { PrimaryButton } from '../../../stand-up/PrimaryButton';
import { StepProps } from '../common';
import { StepTracker } from './StepTracker';
import { addToAllOnboardingSteps, getString, onboardingSteps } from './constants';
import { Container, ContentContainer } from './shared';

export type AboutProps = StepProps & {
    isAgentOnboarding: boolean;
    firstName: string;
    setFirstName: (firstName: string) => void;
    lastName: string;
    setLastName: (lastName: string) => void;
    department: string;
    setDepartment: (role: string) => void;
    otherDepartment: string;
    setOtherDepartment: (otherRole: string) => void;
    howDidYouHear: string;
    setHowDidYouHear: (howDidYouHear: string) => void;
    howDidYouHearOther: string;
    setHowDidYouHearOther: (str: string) => void;
    defaultMeetingLanguage?: string;
    setDefaultMeetingLanguage: (str: string) => void;
    numberOfPeopleInCompany?: string;
    setNumberOfPeopleInCompany: (str: string) => void;
    onBack?: () => void;
    companyName: string;
    setCompanyName: (str: string) => void;
};

export const ValidationLabel = styled.span`
    color: ${(props) => props.theme.secondary.midnight};
    font-size: 12px;
    min-height: 12px;
    width: 100%;
    text-align: left;
`;

const TeamKindList = [
    {
        label: 'Product/Engineering/Design',
        code: 'product-engineering-design',
    },
    {
        label: 'Operations/Project Management',
        code: 'operations-project-management',
    },
    {
        label: 'IT',
        code: 'it',
    },
    {
        label: 'Customer Support',
        code: 'customer-support',
    },
    {
        label: 'Sales/Marketing',
        code: 'sales/marketing',
    },
    {
        label: 'Finance/Legal/HR',
        code: 'finance-legal-hr',
    },
    {
        label: 'Management',
        code: 'management',
    },
];

const TeamRoleList = [
    {
        label: 'Individual Contributor',
        code: 'ic',
    },
    {
        label: 'Team Leader',
        code: 'team-leader',
    },
    {
        label: 'Director',
        code: 'director',
    },
    {
        label: 'Executive',
        code: 'executive',
    },
];

const DEPARTMENT_OPTIONS_LIST = [
    {
        label: 'Product',
        code: 'product',
    },
    {
        label: 'Engineering',
        code: 'engineering',
    },
    {
        label: 'Information Technology (IT)',
        code: 'information-technology',
    },
    {
        label: 'Operations',
        code: 'operations',
    },
    {
        label: 'Founder/Owner',
        code: 'founder-owner',
    },
    {
        label: 'Design/Research',
        code: 'design-research',
    },
    {
        label: 'Sales',
        code: 'sales',
    },
    {
        label: 'Marketing',
        code: 'marketing',
    },
    {
        label: 'Finance',
        code: 'finance',
    },
    {
        label: 'Customer Success',
        code: CUSTOMER_SUCCESS_DEPARTMENT_CODE,
    },
    {
        label: 'HR/Recruiting',
        code: 'hr-recruiting',
    },
    {
        label: 'Legal',
        code: 'legal',
    },
    {
        label: 'Other',
        code: 'other',
    },
];

const HowDidYouHearList = [
    {
        label: 'Someone recommended',
        code: 'someone-recommended',
    },
    {
        label: 'Saw it on a meeting',
        code: 'saw-it-on-a-meeting',
    },
    {
        label: 'Google search',
        code: 'google-search',
    },
    {
        label: 'LinkedIn',
        code: 'linkedin',
    },
    {
        label: 'Superhuman Newsletter',
        code: 'superhuman',
    },
    {
        label: 'G2',
        code: 'G2',
    },
    {
        label: 'TikTok',
        code: 'tiktok',
    },
    {
        label: 'Instagram',
        code: 'instagram',
    },
    {
        label: 'YouTube',
        code: 'youtube',
    },
    {
        label: 'Other',
        code: 'other',
    },
];

export function StepTrackerAboutStep({
    firstName,
    setFirstName,
    lastName,
    setLastName,
    companyName,
    setCompanyName,
    loadingMessage,
    onSubmit,
    howDidYouHear,
    setHowDidYouHear,
    howDidYouHearOther,
    setHowDidYouHearOther,
    defaultMeetingLanguage,
    setDefaultMeetingLanguage,
    isAgentOnboarding,
    numberOfPeopleInCompany,
    setNumberOfPeopleInCompany,
    setDepartment,
    setOtherDepartment,
    department,
    otherDepartment,
}: AboutProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedAboutStep);
    const track = useExperienceTracking();
    const classes = useSpinachInputStyles({ value: '' });
    const { width, isDesktopView } = useWindowSize();
    const [user] = useGlobalAuthedUser();
    const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
    const onboardingPrivacyPolicyQuestion = user.featureToggles[FeatureToggle.OnboardingPrivacyPolicyCheckbox];
    const shouldShowCompanyName = user?.isEnabledForCompanyNameForPersonalUsers && user.isPersonal;

    const isDisabled =
        (onboardingPrivacyPolicyQuestion ? !acceptedPrivacyPolicy : false) ||
        !department ||
        (department === 'other' ? !otherDepartment.trim() : false) ||
        !howDidYouHear ||
        !firstName.trim() ||
        !lastName.trim() ||
        !defaultMeetingLanguage ||
        (user.isEnabledForNumberOfPeopleInCompanyQuestion ? !numberOfPeopleInCompany : false) ||
        (shouldShowCompanyName ? !companyName.trim() : false);
    const languageSelectionEnabled = useLanguageSelectionInDashboardEnablement();
    const languages = MEETING_LANGAUGES.map((lang) => ({
        label: lang,
        code: lang,
        disabled: MEETING_LANGUAGE_SEPARATOR === lang,
    }));
    const numberOfPeopleInCompanyOptions = Object.values(CompanySize).map((label) => ({
        label,
        code: label,
    }));

    const selectedOnboardingSteps = user.isEnabledForAddToAllOnboarding ? addToAllOnboardingSteps : onboardingSteps;

    const dropDownButtonProps = {
        style: {
            marginTop: '5px',
            width: width < 400 ? '250px' : '330px',
            transform: width < 400 ? 'scale(0.8)' : 'unset',
            marginLeft: width < 400 ? '-25px' : 'unset',
        },
    };
    return (
        <Container>
            <Row>
                <SpinachLogo
                    style={{
                        height: isDesktopView ? '55px' : '25px',
                    }}
                />
            </Row>

            <ContentContainer>
                <Row centered>
                    <StepTracker steps={selectedOnboardingSteps} currentStep={0} />
                </Row>
                <Spacing factor={2.5} />
                <HeaderThreeOnboard>{getString('AboutPageTitle', isAgentOnboarding)}</HeaderThreeOnboard>

                <Spacing factor={1 / 2} />

                <div style={{ maxWidth: '555px', display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
                    <Row>
                        <Column>
                            <BodyBigOnboard>Your name</BodyBigOnboard>
                            <div style={{ display: 'flex-inline' }}>
                                <TextField
                                    value={firstName}
                                    InputProps={{ classes: { root: classes.base } }}
                                    {...withContentMasking(classes.root)}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    style={{ marginRight: '10px' }}
                                    inputProps={{ maxLength: 50 }}
                                    autoFocus
                                    placeholder="First name"
                                />
                                <TextField
                                    value={lastName}
                                    InputProps={{ classes: { root: classes.base } }}
                                    {...withContentMasking(classes.root)}
                                    onChange={(e) => setLastName(e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    placeholder="Last name"
                                />
                            </div>
                        </Column>
                    </Row>

                    <Spacing />
                    {shouldShowCompanyName ? (
                        <>
                            <Row>
                                <BodyBigOnboard style={{ fontWeight: 500 }}>Company name</BodyBigOnboard>
                            </Row>
                            <Row>
                                <TextField
                                    placeholder="Acme co."
                                    value={companyName}
                                    onChange={(e) => {
                                        setCompanyName(e.target.value);
                                    }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    {...withContentMasking(classes.root)}
                                    inputProps={{ maxLength: 50 }}
                                    style={{ width: '70%' }}
                                />
                            </Row>
                            <Spacing />
                        </>
                    ) : null}

                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>What department do you work in?</BodyBigOnboard>
                    </Row>

                    <Row>
                        <DropDown
                            buttonProps={dropDownButtonProps}
                            title={'Select an option'}
                            values={DEPARTMENT_OPTIONS_LIST}
                            handleSelection={(value) => {
                                track(ClientEventType.UserSelectedDepartment, {
                                    IsAgentOnboarding: isAgentOnboarding,
                                    Department: value,
                                });
                                setDepartment(value);
                            }}
                            selected={DEPARTMENT_OPTIONS_LIST.find((t) => t.code === department)?.label || undefined}
                        />
                    </Row>

                    {department === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <BodyBigOnboard>Other department</BodyBigOnboard>

                            <Row>
                                <TextField
                                    placeholder="What department?"
                                    value={otherDepartment}
                                    onChange={(e) => {
                                        setOtherDepartment(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />

                    <Row>
                        <BodyBigOnboard style={{ fontWeight: 500 }}>
                            {getString('HowDidYouHear', isAgentOnboarding)}
                        </BodyBigOnboard>
                    </Row>

                    <Row>
                        <DropDown
                            buttonProps={dropDownButtonProps}
                            title={'Select an option'}
                            values={HowDidYouHearList}
                            handleSelection={(value) => {
                                track(ClientEventType.UserSelectedHowDidYouHear, {
                                    IsAgentOnboarding: isAgentOnboarding,
                                    HowDidYouHear: value,
                                });
                                setHowDidYouHear(value);
                            }}
                            selected={HowDidYouHearList.find((t) => t.code === howDidYouHear)?.label || undefined}
                        />
                    </Row>

                    {howDidYouHear === 'other' ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <Row>
                                <TextField
                                    placeholder="How did you hear?"
                                    value={howDidYouHearOther}
                                    onChange={(e) => {
                                        setHowDidYouHearOther(e.target.value);
                                    }}
                                    style={{ width: '88%', maxWidth: '356px' }}
                                    InputProps={{ classes: { root: classes.base } }}
                                    inputProps={{ maxLength: 50 }}
                                    {...withContentMasking(classes.root)}
                                />
                            </Row>
                        </>
                    ) : null}

                    <Spacing />
                    {languageSelectionEnabled ? (
                        <>
                            <Row>
                                <BodyBigOnboard style={{ fontWeight: 500 }}>
                                    What language are the majority of your meetings in?
                                </BodyBigOnboard>
                            </Row>

                            <Row>
                                <DropDown
                                    buttonProps={dropDownButtonProps}
                                    title={'Select an option'}
                                    values={languages}
                                    handleSelection={(value) => {
                                        track(ClientEventType.UserSelectedDefaultMeetingLanguageInOnboarding, {
                                            DefaultMeetingLanguage: value,
                                            IsAgentOnboarding: isAgentOnboarding,
                                        });
                                        setDefaultMeetingLanguage(value);
                                    }}
                                    selected={
                                        languages.find((t) => t.code === defaultMeetingLanguage)?.label || undefined
                                    }
                                />
                            </Row>
                            <Spacing />
                        </>
                    ) : null}

                    {!!user.isEnabledForNumberOfPeopleInCompanyQuestion ? (
                        <>
                            <Row>
                                <BodyBigOnboard style={{ fontWeight: 500 }}>
                                    How many people in your company?
                                </BodyBigOnboard>
                            </Row>

                            <Row>
                                <DropDown
                                    buttonProps={dropDownButtonProps}
                                    title={'Select an option'}
                                    values={numberOfPeopleInCompanyOptions}
                                    handleSelection={(value) => {
                                        track(ClientEventType.UserSelectedNumberOfPeopleInCompanyInOnboarding, {
                                            Selection: value,
                                        });
                                        setNumberOfPeopleInCompany(value);
                                    }}
                                    selected={
                                        numberOfPeopleInCompanyOptions.find((t) => t.code === numberOfPeopleInCompany)
                                            ?.label || undefined
                                    }
                                />
                            </Row>
                            <Spacing />
                        </>
                    ) : null}

                    {onboardingPrivacyPolicyQuestion ? (
                        <Box display="flex" flexDirection="row" alignSelf="start" alignItems="center">
                            <FormGroup>
                                <FormControlLabel
                                    style={{ margin: '0' }}
                                    control={
                                        <Checkbox
                                            style={{ width: '20px', padding: 0 }}
                                            checked={acceptedPrivacyPolicy}
                                            onChange={() => {
                                                const newState = !acceptedPrivacyPolicy;
                                                track(ClientEventType.UserPrivacyPolicyCheckboxAction, {
                                                    state: newState ? 'checked' : 'unchecked',
                                                    IsAgentOnboarding: isAgentOnboarding,
                                                });
                                                setAcceptedPrivacyPolicy(newState);
                                            }}
                                        />
                                    }
                                    label={
                                        <BodyRegularOnboard
                                            style={{ fontWeight: 400, marginLeft: '10px', fontSize: '14px' }}
                                        >
                                            {'I agree to the Spinach '}
                                            <Anchor
                                                style={{
                                                    color: lightTheme.primary.greenLight,
                                                    textDecoration: 'none',
                                                    fontWeight: 'normal',
                                                }}
                                                onClick={() => {
                                                    URLUtil.openURL('https://www.spinach.io/terms-of-service');
                                                }}
                                            >
                                                {'Terms of Service'}
                                            </Anchor>
                                            {' and '}
                                            <Anchor
                                                style={{
                                                    color: lightTheme.primary.greenLight,
                                                    textDecoration: 'none',
                                                    fontWeight: 'normal',
                                                }}
                                                onClick={() => {
                                                    URLUtil.openURL('https://www.spinach.io/privacy');
                                                }}
                                            >
                                                {'Privacy Policy'}
                                            </Anchor>
                                        </BodyRegularOnboard>
                                    }
                                />
                            </FormGroup>
                        </Box>
                    ) : null}

                    <Spacing />
                    <PrimaryButton
                        style={{ padding: '16px 32px', width: '176px', margin: '0 auto' }}
                        onClick={onSubmit}
                        disabled={!!loadingMessage || isDisabled}
                        isLoading={!!loadingMessage}
                        title={'Next'}
                    />
                </div>
            </ContentContainer>
        </Container>
    );
}
