import { CalendarEvent } from '@spinach-shared/types';

export const OLD_MICROSOFT_UID_PREFIX = '040000008200E00074C5B7101A82E0080000';
export const MICROSOFT_UID_PREFIX = '040000008200E00074C5B7101A82E008';
const NO_EXCEPTION_SEQUENCE = '00000000';

export function getCalUidPrefix(iCalUid: string) {
    let iCalUidPrefix = iCalUid;
    if (iCalUidPrefix.includes('@google.com')) {
        if (iCalUidPrefix.includes('_R20')) {
            iCalUidPrefix = iCalUidPrefix.split('_R20')[0];
        } else {
            iCalUidPrefix = iCalUidPrefix.split('@google.com')[0];
        }
    }
    return iCalUidPrefix;
}

function isMicrosoftCalUid(iCalUid: string) {
    return iCalUid.startsWith(MICROSOFT_UID_PREFIX);
}

// see https://learn.microsoft.com/en-us/openspecs/exchange_server_protocols/ms-oxocal/1d3aac05-a7b9-45cc-a213-47f0a0a2c5c1?redirectedfrom=MSDN
function getMicrosoftCalUidWithoutException(iCalUid: string) {
    if (isMicrosoftCalUid(iCalUid)) {
        return `${MICROSOFT_UID_PREFIX}${NO_EXCEPTION_SEQUENCE}${iCalUid.substring(
            MICROSOFT_UID_PREFIX.length + NO_EXCEPTION_SEQUENCE.length
        )}`;
    }
    return iCalUid;
}

export function doCalUidsBelongToTheSameEventSeries(seriesCalUid: string, eventCalUid: string): boolean {
    return (
        getCalUidPrefix(seriesCalUid) === getCalUidPrefix(eventCalUid) ||
        (isMicrosoftCalUid(seriesCalUid) &&
            getMicrosoftCalUidWithoutException(seriesCalUid) === getMicrosoftCalUidWithoutException(eventCalUid))
    );
}

export function getUniqueEvents<T extends CalendarEvent>(events: T[]): T[] {
    const uniqueEvents: T[] = [];
    const recurringEventIdSet = new Set();

    // google events have the same iCalUID for recurring events
    events.forEach((event) => {
        if (event.recurringEventId && recurringEventIdSet.has(event.recurringEventId)) {
            return;
        }

        uniqueEvents.push(event);
        if (event.recurringEventId) {
            recurringEventIdSet.add(event.recurringEventId);
        }
    });

    return uniqueEvents;
}

export function getMasterEventId(event: CalendarEvent): string {
    if (event.recurringEventId) {
        return event.recurringEventId;
    } else {
        return event.id!;
    }
}
