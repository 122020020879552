import { Box } from '@material-ui/core';
import { Lock } from '@material-ui/icons';

import { SubscriptionProperty, VideoAgentSession } from '@spinach-shared/models';
import { formatTime, isProductionStage } from '@spinach-shared/utils';

import { BodyRegular, BodyRegularOnboard, Column, HeaderTwo, Row, useGlobalVideoAgent, useTimer } from '../../../..';
import { ReactComponent as SpinachLogo } from '../../../assets/just-s-logo.svg';
import { ReactComponent as SpinachLongWhite } from '../../../assets/s-logo.svg';

interface LogoAndAgendaSectionProps {
    session: VideoAgentSession;
}

const SHORT_LINK = isProductionStage() ? 'spinach.ai/control' : 'internal.app.spinach.ai/control';

function LogoAndAgendaSection({ session }: LogoAndAgendaSectionProps): JSX.Element {
    const StarterContent = (
        <>
            <BodyRegularOnboard style={{ color: 'white', fontSize: '28px', textAlign: 'center', fontWeight: 600 }}>
                Add agenda topics at
            </BodyRegularOnboard>
            <div style={{ fontSize: '48px', fontWeight: 600, marginTop: '5px', color: 'white', textAlign: 'center' }}>
                {SHORT_LINK}
            </div>
        </>
    );

    const ProFeaturesContent = (
        <>
            <BodyRegularOnboard style={{ color: 'white', fontSize: '28px', fontWeight: 600, textAlign: 'center' }}>
                Meeting notes available on
            </BodyRegularOnboard>
            <div style={{ fontSize: '48px', fontWeight: 600, marginTop: '5px', color: 'white', textAlign: 'center' }}>
                spinach.ai
            </div>
        </>
    );

    const TextContent = session.hasProFeatures ? ProFeaturesContent : StarterContent;

    const shouldShowSpinachLogo = !session.raw.accountLogoUrl || !session.hasProFeatures;

    return (
        <Column style={{ alignItems: 'center', gap: '40px', width: '100%', height: '100%', justifyContent: 'center' }}>
            <Box
                bgcolor={'transparent'}
                borderRadius={16}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={230}
                height={230}
            >
                {!shouldShowSpinachLogo && session.raw.accountLogoUrl ? (
                    <img
                        src={session.raw.accountLogoUrl}
                        alt="Company Logo"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: '16px',
                        }}
                    />
                ) : (
                    <Column style={{ height: '100%' }}>
                        {session.hasProFeatures ? <div style={{ flexGrow: 1 }} /> : null}
                        <SpinachLogo style={{ height: '70%' }} />
                        {session.hasProFeatures ? (
                            <>
                                <div style={{ flexGrow: 1 }} />
                                <Row
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '8px',
                                        backgroundColor: 'transparent',
                                        borderRadius: 16,
                                        width: '100%',
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                    }}
                                >
                                    <BodyRegular style={{ color: 'white' }}>customize branding in settings</BodyRegular>
                                </Row>
                            </>
                        ) : null}
                    </Column>
                )}
            </Box>
            <Box>{TextContent}</Box>
        </Column>
    );
}

export function NoAgendaContent(): JSX.Element {
    const { session } = useGlobalVideoAgent();
    const timer = useTimer(session?.raw.createdAt);

    return (
        <Box
            position="absolute"
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="flex-start"
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width={'100%'}
                style={{ marginTop: '30px' }}
            >
                <Row style={{ justifyContent: 'space-between' }}>
                    <Box
                        style={{
                            fontSize: '24px',
                            color: 'white',
                            marginBottom: '8px',
                        }}
                    >
                        <HeaderTwo
                            style={{
                                color: 'white',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '650px',
                            }}
                        >
                            {session.raw.meetingTitle || ''}
                        </HeaderTwo>
                    </Box>
                    <Box
                        style={{
                            fontSize: '20px',
                            color: 'white',
                        }}
                    >
                        <HeaderTwo style={{ color: 'white', minWidth: '130px', textAlign: 'left' }}>
                            {formatTime(timer)}
                        </HeaderTwo>
                    </Box>
                </Row>
                <Row vCenter={true}>
                    <Box
                        component="span"
                        display="inline-block"
                        sx={{
                            width: 12,
                            height: 12,
                            bgcolor: session.isPaused ? '#FB1C29' : '#A7FF46',
                            borderRadius: '50%',
                            marginRight: '5px',
                        }}
                    />
                    {!session.isPaused ? (
                        <BodyRegularOnboard style={{ color: 'white', fontSize: '22px' }}>
                            Note taking in progress.{' '}
                            <i>
                                Type <b>pause</b> to stop recording
                            </i>
                        </BodyRegularOnboard>
                    ) : (
                        <BodyRegularOnboard style={{ color: 'white', fontSize: '22px' }}>
                            <span style={{ color: '#FB1C29', fontWeight: 600 }}>Recording paused</span>. Type{' '}
                            <b>resume</b> to continue
                        </BodyRegularOnboard>
                    )}
                    <div style={{ flexGrow: 1 }} />
                    <Box style={{ color: 'white', alignItems: 'center', display: 'flex', gap: '8px' }}>
                        <Lock />{' '}
                        <BodyRegular style={{ color: 'white', fontSize: '22px' }}>All data is encrypted</BodyRegular>
                    </Box>
                </Row>
            </Box>
            <Box
                bgcolor="#000000"
                borderRadius={24}
                padding={6}
                paddingBottom={0}
                paddingTop={0}
                width="110%"
                maxWidth={1000}
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="74%"
                flexDirection="column"
                style={{ marginTop: '20px' }}
            >
                <div style={{ flexGrow: 1 }} />
                <LogoAndAgendaSection session={session} />
                <div style={{ flexGrow: 1 }} />
                <Row
                    style={{
                        alignItems: 'center',
                        gap: '8px',
                        justifyContent: 'center',
                        backgroundColor: 'rgb(38 38 38)',
                        padding: '10px',
                        borderRadius: 16,
                        width: 'calc(100% + 80px)',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    }}
                >
                    <Box flexDirection="row" display="flex" alignItems="center" justifyContent="flex-end">
                        <BodyRegular style={{ color: 'white', fontSize: '18px' }}>Powered by</BodyRegular>
                        <Box>
                            <SpinachLongWhite style={{ height: '25px', marginLeft: '-80px', marginRight: '-70px' }} />
                        </Box>
                    </Box>
                </Row>
            </Box>
        </Box>
    );
}
