import { Box } from '@material-ui/core';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import lottie from 'lottie-web';
import pRetry from 'p-retry';
import { useEffect, useState } from 'react';

import {
    CheckoutBillingScope,
    ClientEventType,
    FeatureToggle,
    StripeSessionStatus,
    WebUrlQuery,
} from '@spinach-shared/types';

import { postCreateCheckoutSession } from '../../../../apis';
import { postCreateCustomerPortalSession } from '../../../../apis/postCreateCustomerPortalSession';
import celebrateLottieJSON from '../../../../assets/celebrate.json';
import { useDefaultUsageCycleDays, useExperienceTracking, useGlobalAiDashboard } from '../../../../hooks';
import { useGlobalAuthedUser, useStripeCustomerPortalEnablement, useStripePlans } from '../../../../hooks';
import { BodyRegularOnboard, HeaderThree, HeaderThreeOnboard } from '../../../../styles';
import { ClientLogger, URLUtil } from '../../../../utils';
import { getClientConfigValue } from '../../../../utils/getClientConfigValue';
import { Anchor, DropDown, LoadingSquares, Row, Spacing } from '../../../common';
import { AddTeamMemberModal } from '../../../common/modals/AddTeamMemberModal';
import { AccountManagementSection } from './AccountManagementSection';
import { BillingPortalBanner } from './BillingPortalBanner';
import { PaidUsersTable } from './PaidUsersTable';
import { PayingAccountDetails } from './PayingAccountDetails';
import { FreePlanBanner, TrialBanner } from './PlanBanner';
import { ProAndBusinessPlanSelector } from './ProAndBusinessPlanSelector';

const apiKey = getClientConfigValue('REACT_APP_STRIPE_PUBLIC_API_KEY') as string;
async function initStripe() {
    return await loadStripe(apiKey);
}

const stripePromise = pRetry(initStripe, { retries: 3 });

export function AccountSection() {
    const [clientSecret, setClientSecret] = useState('');
    const [isStripeInitialized, setIsStripeInitialized] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [user] = useGlobalAuthedUser();
    const plansInfo = useStripePlans();
    const plans = plansInfo?.plans || [];
    const isConsumptionBased = !!plansInfo?.isConsumptionBased;
    const dropdownOptions = plans
        .filter((p) => {
            // if the user is on a public domain, only show the team plan
            if (user.isPersonal) {
                return p.checkoutBillingScope === CheckoutBillingScope.Team;
            }
            return true;
        })
        .map((p) => ({
            code: p.planName,
            label:
                p.checkoutBillingScope === CheckoutBillingScope.Company
                    ? `${p.planName} (anyone at ${user.metadata.rootDomain?.slice(1)})`
                    : p.planName,
        }));
    const [selectedPlan, setSelectedPlan] = useState<string>(plans[0].planName);
    const track = useExperienceTracking();
    const [isNavigatingToCustomerPortalFromPlanBanner] = useState(false);
    const [isNavigatingToCustomerPortalFromBillingBanner, setIsNavigatingToCustomerPortalFromBillingBanner] =
        useState(false);
    const { setToastText } = useGlobalAiDashboard();
    const defaultCycleDuration = useDefaultUsageCycleDays();

    // portal shows to anyone that has ever paid, regardless of current paid/free status
    const isCustomerPortalEnabled = useStripeCustomerPortalEnablement();
    const showCustomerPortal = !!user.stripeCustomerId && isCustomerPortalEnabled;

    // show the support message if the customer portal is not enabled and the user is a paying user
    // otherwise there's a support message in the customer portal banner
    const showSupportMessage = !isCustomerPortalEnabled && user.isPayingUser;

    // show the upgrade if the user does not have the customer portal and is a not a paying user
    const showUpgrade = (user.isOnLiveReverseTrial || !user.hasProFeatures) && !user.isPayingUser;
    const planName = user.hasProFeatures ? 'Pro' : 'Starter';
    const daysLeftText = user.isOnManuallyManagedTrial ? '.' : ` for ${user.reverseTrialDaysLeft} days.`;
    let subtitle = user.isOnLiveReverseTrial ? (
        `You are trialing the Pro plan${daysLeftText}`
    ) : (
        <span>
            You are on the <b>{planName}</b> plan.
        </span>
    );
    if (user.isPersonal && !user.isOnLiveReverseTrial && !user.isEnabledForNewStarterForAll) {
        subtitle = 'Your trial is over, please upgrade to continue using Spinach';
    }

    async function navigateToCustomerPortalSession(stateSetter: (value: boolean) => void) {
        stateSetter(true);
        try {
            const response = await postCreateCustomerPortalSession();
            if (!!response?.sessionUrl) {
                URLUtil.openURL(response.sessionUrl);
            }
        } catch (e: any) {
            ClientLogger.error('Failed to get customer portal url', {
                errorMessage: e.message,
            });
            setToastText(
                <>
                    Oops, something went wrong, please try again later. If the problem persists, please contact support{' '}
                    <Anchor
                        id={'spinach_intercom'}
                        // dynamic id if we have no article
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Help for Bad Billing Portal URL`,
                            });
                        }}
                    >
                        here
                    </Anchor>
                </>
            );
        } finally {
            stateSetter(false);
        }
    }

    useEffect(() => {
        async function createCheckoutSession() {
            setIsStripeInitialized(false);
            if (showUpgrade) {
                try {
                    const response = await postCreateCheckoutSession(selectedPlan);
                    if (!!response?.clientSecret) {
                        setClientSecret(response.clientSecret);
                    } else {
                        throw new Error('Failed to get client secret from response');
                    }
                } catch (e: any) {
                    ClientLogger.error('Failed to create checkout session', { errorMessage: e.message });
                    track(ClientEventType.AIDashboardActivity, {
                        Activity: 'Failed to Create Checkout Session',
                    });
                    setHasError(true);
                }
            }

            try {
                await stripePromise;
            } catch (e: any) {
                ClientLogger.error('Failed to load stripe', { errorMessage: e.message });
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Failed to load stripe on account section',
                });
                setHasError(true);
                return;
            }

            setIsStripeInitialized(true);
        }

        createCheckoutSession();
    }, [showUpgrade, selectedPlan]);

    useEffect(() => {
        const container = document.querySelector('#banner-container');
        const urlParams = new URLSearchParams(window.location.search);
        const sessionStatus = urlParams.get(WebUrlQuery.StripeSessionStatus);
        if (!!container && sessionStatus === StripeSessionStatus.Complete) {
            const animation = lottie.loadAnimation({
                container,
                autoplay: false,
                loop: false,
                animationData: celebrateLottieJSON,
                rendererSettings: {
                    className: 'celebrate-pro-account-animation',
                },
            });
            animation.setSpeed(0.33);
            animation.play();
        }
    }, []);

    const shouldDisplayStarterCycleUsage = !!user.autoCappingCycleStartDate;

    return (
        <Box style={{ height: '100vh', overflowY: 'scroll' }}>
            <Spacing factor={1 / 2} />
            <Row>
                <HeaderThreeOnboard>Account</HeaderThreeOnboard>
            </Row>
            <Spacing />
            <Row>
                <HeaderThree>Plan</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />

            {!user.isPayingUser && user.isEnabledForUsageVisibility ? (
                <>
                    <BodyRegularOnboard>{subtitle}</BodyRegularOnboard>

                    <Spacing factor={1 / 2} />
                    <Row>
                        {user.personalUsageHours ? (
                            <BodyRegularOnboard>
                                Your usage in the last {defaultCycleDuration} days:{' '}
                                <b>{user.personalUsageHours} hours</b>
                            </BodyRegularOnboard>
                        ) : null}
                    </Row>
                    <Row>
                        {!user.isPersonal && user.accountUsageHours ? (
                            <BodyRegularOnboard>
                                Your company&apos;s usage in the last {defaultCycleDuration} days:{' '}
                                <b>{user.accountUsageHours} hours</b>
                            </BodyRegularOnboard>
                        ) : null}
                    </Row>
                    {shouldDisplayStarterCycleUsage ? (
                        <>
                            <Row>
                                <BodyRegularOnboard>
                                    Your {!user.isPersonal ? "company's " : ''} usage this cycle:{' '}
                                    <b>{user.usageHoursForCurrentLimitedCycle} hours</b>
                                </BodyRegularOnboard>
                            </Row>
                        </>
                    ) : null}
                </>
            ) : null}

            {user.isPayingUser && user.featureToggles[FeatureToggle.PaidAccountUsageDetails] ? (
                <Box mr={1}>
                    <PayingAccountDetails />
                    {user.isEnabledForPaidUsersTable ? <PaidUsersTable /> : null}
                </Box>
            ) : null}

            <Spacing factor={1 / 2} />
            {(!user.hasProFeatures && (!user.isPersonal || user.isEnabledForNewStarterForAll)) ||
            user.isOnLiveReverseTrial ? (
                <>
                    <Row>
                        {user.isOnLiveReverseTrial ? (
                            <TrialBanner isNavigatingToCustomerPortal={isNavigatingToCustomerPortalFromPlanBanner} />
                        ) : (
                            <FreePlanBanner isNavigatingToCustomerPortal={isNavigatingToCustomerPortalFromPlanBanner} />
                        )}
                    </Row>
                    <Spacing />
                </>
            ) : null}

            {showCustomerPortal ? (
                <Row>
                    <BillingPortalBanner
                        onBillingPortalClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Go To Billing Portal`,
                            });

                            navigateToCustomerPortalSession(setIsNavigatingToCustomerPortalFromBillingBanner);
                        }}
                        isNavigatingToCustomerPortal={isNavigatingToCustomerPortalFromBillingBanner}
                    />
                </Row>
            ) : null}
            {showSupportMessage ? (
                <Row>
                    <BodyRegularOnboard>
                        To cancel your subscription anytime, contact support{' '}
                        <Anchor
                            id={'spinach_intercom'}
                            // dynamic id if we have no article
                            onClick={() => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Cancel Subscription Help`,
                                });
                            }}
                        >
                            here
                        </Anchor>
                    </BodyRegularOnboard>
                </Row>
            ) : null}
            {showUpgrade ? (
                user.isEnabledForNewProAndBusinessPlans ? (
                    <>
                        <ProAndBusinessPlanSelector stripePromise={stripePromise} />
                    </>
                ) : (
                    <div id="checkout">
                        <Row>
                            <HeaderThree>Upgrade</HeaderThree>
                        </Row>

                        {isConsumptionBased && plans.length > 1 ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row
                                    style={{
                                        alignItems: 'center',
                                    }}
                                >
                                    <BodyRegularOnboard
                                        style={{
                                            marginRight: '16px',
                                        }}
                                    >
                                        Plan type:
                                    </BodyRegularOnboard>
                                    <DropDown
                                        style={{
                                            width: `${
                                                !!user.metadata.rootDomain?.length
                                                    ? 150 + user.metadata.rootDomain?.length * 14
                                                    : 250
                                            }px`,
                                        }}
                                        title={''}
                                        values={dropdownOptions}
                                        defaultValue={selectedPlan}
                                        selected={selectedPlan}
                                        handleSelection={(code) => {
                                            setSelectedPlan(code);
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Upgrade Plan Select',
                                                SelectedPlan: code,
                                            });
                                        }}
                                    ></DropDown>
                                </Row>
                            </>
                        ) : null}

                        {user.talkToSalesUrl ? (
                            <>
                                <Spacing factor={1 / 2} />
                                <Row vCenter={true}>
                                    Have questions?{' '}
                                    <Anchor
                                        style={{ marginLeft: '5px' }}
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Talk to Sales',
                                            });
                                            URLUtil.openURL(user.talkToSalesUrl);
                                        }}
                                    >
                                        Talk to sales
                                    </Anchor>
                                </Row>
                            </>
                        ) : null}

                        <Spacing />

                        {clientSecret && isStripeInitialized && showUpgrade ? (
                            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        ) : hasError ? (
                            <Row>
                                <BodyRegularOnboard>
                                    Oops, something went wrong, please try again later. If the problem persists, please
                                    contact support{' '}
                                    <Anchor
                                        id={'spinach_intercom'}
                                        onClick={() => {
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: `Issue Loading Stripe Help`,
                                            });
                                        }}
                                    >
                                        here
                                    </Anchor>
                                </BodyRegularOnboard>
                            </Row>
                        ) : (
                            <LoadingSquares />
                        )}
                    </div>
                )
            ) : null}
            <Spacing factor={1 / 2} />
            <AccountManagementSection />
            <Spacing />
            <AddTeamMemberModal />
        </Box>
    );
}
