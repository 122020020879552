import { Box } from '@material-ui/core';
import { InfoRounded } from '@material-ui/icons';
import React from 'react';

import { lightTheme } from '../../styles';

interface InformativeBannerProps {
    message: React.ReactNode;
    icon?: React.ReactNode;
    style?: React.CSSProperties;
}

export const InformativeBanner: React.FC<InformativeBannerProps> = ({
    message,
    icon = <InfoRounded style={{ color: lightTheme.primary.green, marginRight: '10px' }} />,
    style,
}) => {
    return (
        <Box
            style={{
                backgroundColor: lightTheme.tertiary.greenLight,
                padding: '12px',
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '30px',
                ...style,
            }}
            my={1}
        >
            {icon}
            {message}
        </Box>
    );
};
