import { Modal } from '@material-ui/core';

import { useGlobalAuthedUser, useGlobalModal } from '../../../..';
import RecallSchedulingDisclaimerSrc from '../../../assets/recall-scheduling-automation-disclaimer.png';
import { GlobalModal } from '../../../atoms';
import { useGlobalIntegrationSettingsModalKind } from '../../../hooks/useGlobalIntegrationSettingsModalKind';
import { BodyRegularOnboard, ResponsiveModalTitle } from '../../../styles';
import { useHoverRow } from '../../spinach-ai/dashboard/integrations';
import {
    GoogleCalendarIntegrationSection,
    MicrosoftCalendarIntegrationSection,
} from '../../spinach-ai/dashboard/integrations/CalendarIntegrationSection';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Row, Spacing } from '../framing';

export function UpgradeToRecallCalendarV2Modal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [, setModalKind] = useGlobalIntegrationSettingsModalKind();
    const hoverRowProps = useHoverRow();
    const closeModal = () => {
        setGlobalModal(null);
    };

    const title = 'Upgrade Calendar';
    const subtitle = 'Update your calendar to use our advanced scheduling customization';
    return (
        <Modal open={globalModal === GlobalModal.UpgradeToRecallCalendarV2} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: '300px' }}>
                <Column centered>
                    <Row centered>
                        <ResponsiveModalTitle>{title}</ResponsiveModalTitle>
                    </Row>
                    <Spacing factor={1 / 3} />
                    <Row centered>
                        <BodyRegularOnboard style={{ fontSize: '16px', textAlign: 'center', fontWeight: 500 }}>
                            {subtitle}
                        </BodyRegularOnboard>
                    </Row>
                    <Spacing factor={1 / 2} />
                    <Row style={{ display: 'flex', justifyContent: 'center' }}>
                        <img style={{ display: 'flex', height: '30vh' }} src={RecallSchedulingDisclaimerSrc} />
                    </Row>

                    <Spacing />

                    {user.integrationSettings?.microsoftSettings?.isAuthed ? (
                        <MicrosoftCalendarIntegrationSection
                            setModalKind={setModalKind}
                            hoverRowProps={hoverRowProps}
                        />
                    ) : (
                        <></>
                    )}
                    {user.integrationSettings?.googleSettings?.isAuthed ? (
                        <GoogleCalendarIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                    ) : (
                        <></>
                    )}
                    <Spacing factor={1 / 3} />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}
