import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Home } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import {
    ClientEventType,
    VideoAgentControlAction,
    VideoAgentControlCommand,
    VideoAgentSessionTopic,
} from '@spinach-shared/types';

import { useActivityTracking, useGlobalVideoAgent } from '../../hooks';
import { lightTheme } from '../../styles/theme';
import { AgentCommandType } from '../../types/agent';

const TopicListContainer = styled.div`
    min-height: 0;
    overflow: hidden;
    margin: 0 0 0px 0;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
`;

const TopicList = styled(List)`
    height: 100%;
    overflow-y: auto;
    background-color: transparent;
    border-radius: 4px;
    padding: 0;
    position: relative;

    /* Custom scrollbar styling */
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 4px;

        &:hover {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
`;

const StyledListItem = styled(ListItem)<{ isDragging: boolean; isSelected: boolean; isHovered?: boolean }>`
    background: ${(props) =>
        props.isSelected
            ? 'rgba(11, 103, 74, 0.1)'
            : props.isDragging
            ? 'rgba(11, 103, 74, 0.05)'
            : 'rgba(11, 103, 74, 0.02)'};
    color: ${(props) => props.theme.primary.midnight};
    margin: 5px 0;
    border-radius: 4px !important;
    position: relative;
    padding-left: 3px;
    border: ${(props) => (props.isSelected ? '1px solid #0B674A' : '1px solid rgba(11, 103, 74, 0.4)')} !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    transition: all 0.2s ease-in-out !important;
    padding-left: 8px !important;

    &:hover {
        ${(props) =>
            !props.isDragging &&
            `
            border-color: #0B674A !important;
            background: rgba(11, 103, 74, 0.1);
            transform: translateX(4px);
            width: calc(100% - 4px);
        `}
    }
`;

const StyledListItemText = styled(ListItemText)<{ isSelected: boolean }>`
    .MuiListItemText-primary {
        font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
        color: ${(props) => props.theme.primary.midnight};
    }
`;

const DragHandleStyled = styled.div<{ isHovered: boolean }>`
    color: ${(props) => props.theme.primary.midnight};
    cursor: grab;
    display: flex;
    align-items: center;
    margin-right: 8px;
    opacity: ${(props) => (props.isHovered ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;

    &:active {
        cursor: grabbing;
    }
`;

const TopicIndicator = styled.div<{ isActive: boolean }>`
    width: 3px;
    height: 100%;
    background-color: ${(props) => (props.isActive ? '#4CAF50' : 'transparent')};
    position: absolute;
    left: 0;
    top: 0;
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)<{ isHovered: boolean }>`
    .MuiIconButton-root {
        color: ${(props) => props.theme.primary.midnight};
        opacity: ${(props) => (props.isHovered ? 1 : 0)};
        transition: opacity 0.2s ease-in-out;
    }
`;

interface AgendaListProps {
    onCommand: (command: VideoAgentControlCommand) => void;
}

export function AgendaList({ onCommand }: AgendaListProps) {
    const { session } = useGlobalVideoAgent();
    const trackActivity = useActivityTracking();
    const [hoveredId, setHoveredId] = useState<string | null>(null);

    const handleEditTopic = (topicId: string, newName: string) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Edit Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        onCommand({ action: VideoAgentControlAction.EditTopic, topicId, topicName: newName });
    };

    const handleDeleteTopic = (topicId: string) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Delete Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        onCommand({ action: VideoAgentControlAction.DeleteTopic, topicId });
    };

    const handleTopicClick = (index: number) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Jump To Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        onCommand({ action: VideoAgentControlAction.JumpToTopic, topicIndex: index });
    };

    const reorder = (list: VideoAgentSessionTopic[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination || !session?.topics) {
            return;
        }

        const items = reorder(session.topics, result.source.index, result.destination.index);

        trackActivity(ClientEventType.VideoAgentActivity, 'Reorder Topics', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });

        onCommand({
            action: VideoAgentControlAction.ReorderTopics,
            topics: items.map((topic) => topic.id),
        });
    };

    return (
        <TopicListContainer>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="topicList">
                    {(provided, snapshot) => (
                        <TopicList ref={provided.innerRef} {...provided.droppableProps}>
                            <StyledListItem
                                isDragging={false}
                                isSelected={session.isLobbyPhase ?? false}
                                onClick={() => handleTopicClick(-1)}
                                button
                            >
                                <TopicIndicator isActive={session.isLobbyPhase ?? false} />
                                <Home style={{ marginRight: '8px' }} htmlColor={lightTheme.primary.midnight} />
                                <StyledListItemText primary="Lobby" isSelected={session.isLobbyPhase ?? false} />
                            </StyledListItem>
                            {session.topics.map((topic: VideoAgentSessionTopic, index: number) => (
                                <Draggable key={topic.id} draggableId={topic.id} index={index}>
                                    {(provided, snapshot) => (
                                        <StyledListItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            isDragging={snapshot.isDragging}
                                            isSelected={index === session.currentTopicIndex}
                                            isHovered={hoveredId === topic.id}
                                            button
                                            onClick={() => handleTopicClick(index)}
                                            onMouseEnter={() => setHoveredId(topic.id)}
                                            onMouseLeave={() => setHoveredId(null)}
                                        >
                                            <TopicIndicator isActive={index === session.currentTopicIndex} />
                                            <DragHandleStyled
                                                {...provided.dragHandleProps}
                                                isHovered={hoveredId === topic.id}
                                            >
                                                <DragIndicatorIcon />
                                            </DragHandleStyled>
                                            <StyledListItemText
                                                primary={topic.title}
                                                isSelected={index === session.currentTopicIndex}
                                            />
                                            <StyledListItemSecondaryAction
                                                onMouseEnter={() => setHoveredId(topic.id)}
                                                onMouseLeave={() => setHoveredId(null)}
                                                isHovered={hoveredId === topic.id}
                                            >
                                                <IconButton
                                                    edge="end"
                                                    aria-label="edit"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleEditTopic(
                                                            topic.id,
                                                            prompt('Enter new topic name', topic.title) || topic.title
                                                        );
                                                    }}
                                                >
                                                    <EditIcon htmlColor={lightTheme.primary.midnight} />
                                                </IconButton>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteTopic(topic.id);
                                                    }}
                                                >
                                                    <DeleteIcon htmlColor={lightTheme.primary.midnight} />
                                                </IconButton>
                                            </StyledListItemSecondaryAction>
                                        </StyledListItem>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </TopicList>
                    )}
                </Droppable>
            </DragDropContext>
        </TopicListContainer>
    );
}
