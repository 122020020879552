import { Stage } from '@spinach-shared/types';
import { getStage } from '@spinach-shared/utils';

import { isWebPlatform, isZoomPlatform } from './platform';

function getConfigForAllStages(value: string) {
    return {
        [Stage.Local]: value,
        [Stage.Test]: value,
        [Stage.Staging]: value,
        [Stage.Production]: value,
    };
}

const ENVIRONMENT_CONFIG = {
    REACT_APP_APP_VERSION: getConfigForAllStages('3.61.0'),
    PORT: getConfigForAllStages('3001'),
    REACT_APP_PORT: {
        [Stage.Local]: '3001',
        [Stage.Test]: undefined,
        [Stage.Staging]: undefined,
        [Stage.Production]: undefined,
    },
    REACT_APP_WEBSOCKET_URL: {
        [Stage.Local]: 'http://localhost:4000',
        [Stage.Test]: isWebPlatform() ? 'https://test.spinach.ai' : 'https://test.spinach.io',
        [Stage.Staging]: isWebPlatform() ? 'https://staging.spinach.ai' : 'https://staging.spinach.io',
        [Stage.Production]: isWebPlatform() ? 'https://socket.prod.spinach.ai' : 'https://socket.prod.spinach.io',
    },
    // The '/en' here is very important. If we remove it users will be automatically redirected to help.spinach.ai
    // This means that Zoom users will be redirected to help.spinach.ai and Zoom may block the redirect due to their
    // strict security policies.
    INTERCOM_SUPPORT_URL: getConfigForAllStages(
        isZoomPlatform() ? 'https://intercom.help/spinach_io/' : 'https://help.spinach.ai'
    ),
    INTERCOM_SUPPORT_URL_STANDUP_APP: getConfigForAllStages(
        isZoomPlatform()
            ? 'https://intercom.help/spinach_io/articles/6323450-quickstart-guide-for-the-standup-app'
            : 'https://help.spinach.ai/en/articles/6323450-quickstart-guide-for-the-standup-app'
    ),
    /**
     * @NOTE not using getConfigForAllStages here just in case
     * we need to toggle off for production but keep it on for test/staging
     */
    REACT_APP_MIGRATE_TO_NEW_DOMAIN: {
        [Stage.Local]: 'true',
        [Stage.Test]: 'true',
        [Stage.Staging]: 'true',
        [Stage.Production]: 'true',
    },
    REACT_APP_AUTH_URL: {
        [Stage.Local]: 'http://localhost:3000',
        [Stage.Test]: isWebPlatform() ? 'https://auth.test.spinach.ai' : 'https://auth.test.spinach.io',
        [Stage.Staging]: isWebPlatform()
            ? 'https://zoom.auth.staging.spinach.ai'
            : 'https://zoom.auth.staging.spinach.io',
        [Stage.Production]: isWebPlatform() ? 'https://zoom.auth.prod.spinach.ai' : 'https://zoom.auth.prod.spinach.io',
    },
    REACT_APP_SLACK_SERVER_URL: {
        [Stage.Local]: 'http://localhost:4001',
        [Stage.Test]: 'https://slack.test.spinach.tech',
        [Stage.Staging]: 'https://slack.test.spinach.tech',
        [Stage.Production]: 'https://slack.prod.spinach.io',
    },
    REACT_APP_GOOGLE_CLIENT_ID: {
        [Stage.Local]: '996701566391-b1femim0q46l3sg2jenel6vjivdrdt8b.apps.googleusercontent.com',
        [Stage.Test]: '996701566391-b1femim0q46l3sg2jenel6vjivdrdt8b.apps.googleusercontent.com',
        [Stage.Staging]: '996701566391-b1femim0q46l3sg2jenel6vjivdrdt8b.apps.googleusercontent.com',
        [Stage.Production]: '202138718499-q8pt5phngv0bc8d9slk206lai2ucmtck.apps.googleusercontent.com',
    },
    REACT_APP_COGNITO_BASE_PATH: {
        [Stage.Local]: 'https://spinach-test.auth.us-east-2.amazoncognito.com',
        [Stage.Test]: 'https://spinach-test.auth.us-east-2.amazoncognito.com',
        [Stage.Staging]: 'https://spinach-test.auth.us-east-2.amazoncognito.com',
        [Stage.Production]: 'https://spinach-prod.auth.us-east-2.amazoncognito.com',
    },
    REACT_APP_COGNITO_CLIENT_ID: {
        [Stage.Local]: '3nvpv850maltbtd0h431p0a3t',
        [Stage.Test]: '3nvpv850maltbtd0h431p0a3t',
        [Stage.Staging]: '3nvpv850maltbtd0h431p0a3t',
        [Stage.Production]: '4vqccam7eul74lo01cu083l07t',
    },
    REACT_APP_MICROSOFT_APP_ID: {
        [Stage.Local]: '1034f2a2-a8c7-42c7-80ac-b5e7d106cc7a',
        [Stage.Test]: '1034f2a2-a8c7-42c7-80ac-b5e7d106cc7a',
        [Stage.Staging]: '1034f2a2-a8c7-42c7-80ac-b5e7d106cc7a',
        [Stage.Production]: '1d98ced9-052b-4418-9d31-8202fb8fb528',
    },
    REACT_APP_STRIPE_PUBLIC_API_KEY: {
        [Stage.Local]:
            'pk_test_51Lar8wLIIcNbFYvrTBVEXmOpkms3rHULAecY6uDVkZCRkOZSLyk3tB6qYiCWbXTzyVwCweoRqIDMLZQuzNAOWfDK00Y0Li8tVX',
        [Stage.Test]:
            'pk_test_51Lar8wLIIcNbFYvrTBVEXmOpkms3rHULAecY6uDVkZCRkOZSLyk3tB6qYiCWbXTzyVwCweoRqIDMLZQuzNAOWfDK00Y0Li8tVX',
        [Stage.Staging]:
            'pk_test_51Lar8wLIIcNbFYvrTBVEXmOpkms3rHULAecY6uDVkZCRkOZSLyk3tB6qYiCWbXTzyVwCweoRqIDMLZQuzNAOWfDK00Y0Li8tVX',
        [Stage.Production]:
            'pk_live_51Lar8wLIIcNbFYvrfOe31bvHaQYniAj6uoBNEwGX7UyIHi04FfOTra9WhcnEOjM2YpVEsWT1oGcouAVqJKTKR1SQ00MHZrJr2m',
    },
    REACT_APP_CLARITY_PROJECT_ID: {
        [Stage.Local]: 'l5fxzpqqvx',
        [Stage.Test]: 'l5fxzpqqvx',
        [Stage.Staging]: 'l5fxzpqqvx',
        [Stage.Production]: 'l5ba7y3il2',
    },
    REACT_APP_VAPI_API_TOKEN: getConfigForAllStages('2ba59f92-9c8b-4e24-8938-e77c89ff64ce'),
    REACT_APP_VAPI_ASSISTANT_ID: {
        [Stage.Local]: '86e878bd-3e08-4493-8b04-333f26a304dc',
        [Stage.Test]: '86e878bd-3e08-4493-8b04-333f26a304dc',
        [Stage.Staging]: '86e878bd-3e08-4493-8b04-333f26a304dc',
        [Stage.Production]: 'e6f3833d-8e28-4032-8018-bf0297827da6',
    },
    REACT_APP_CELLO_PRODUCT_ID: {
        [Stage.Local]: 'stage-app.spinach.io',
        [Stage.Test]: 'stage-app.spinach.io',
        [Stage.Staging]: 'stage-app.spinach.io',
        [Stage.Production]: 'app.spinach.io',
    },
};

export type ClientEnvVarKey = keyof typeof ENVIRONMENT_CONFIG;

export function getClientConfigValue(key: ClientEnvVarKey): string {
    const stage = getStage();

    const value = ENVIRONMENT_CONFIG[key];
    const finalValue = typeof value !== 'string' ? value[stage] : value;

    if (!finalValue) {
        throw new Error(`No value found for client config key ${key} in stage ${stage}`);
    }

    return finalValue;
}
