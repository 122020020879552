import * as Sentry from '@sentry/react';
import React from 'react';
import { useInterval } from 'react-use';

import { VideoAgentTranscriptPayload } from '@spinach-shared/schemas';
import { PostVideoAgentChatMessageRequest } from '@spinach-shared/types';
import { isLocalStage, isProductionStage } from '@spinach-shared/utils';

import {
    bootTime,
    getAgentSearchParams,
    postTranscript,
    postVideoAgentChatMessage,
    useGlobalNullableVideoAgent,
} from '../../..';
import { parseMessage } from '../../components/common/agent-video/speechProcessing';
import { SendFunction } from '../../types/agent';

export function useLiveRecallTranscription(send: SendFunction) {
    const { session, setSession } = useGlobalNullableVideoAgent();
    const transcriptToSync = React.useRef<VideoAgentTranscriptPayload[]>([]);
    const { botId } = getAgentSearchParams();

    useInterval(async () => {
        if (!isLocalStage() && session?.isAssistantEnabled && transcriptToSync.current.length > 0) {
            await postTranscript(transcriptToSync.current);
        }
        transcriptToSync.current = [];
    }, 7 * 1000);
    React.useEffect(() => {
        const ws = new WebSocket(
            isLocalStage()
                ? 'ws://localhost:8080/api/v1/transcript'
                : 'wss://meeting-data.bot.recall.ai/api/v1/transcript'
        );
        (window as any).recallWebSocketRef = ws;

        ws.onmessage = (event) => {
            let didStore = false;
            try {
                const {
                    speaker,
                    words,
                    rawWords,
                    original_transcript_id,
                    isFinal,
                    firstWordTimestamp,
                    estimatedAverageTranscriptDelay,
                } = parseMessage(event.data);
                if (isFinal) {
                    transcriptToSync.current.push({
                        botId,
                        rawPayload: event.data,
                        speaker,
                        words,
                        rawWords,
                        isFinal,
                        original_transcript_id,
                        firstWordTimestamp,
                        receivedAt: new Date().toISOString(),
                        estimatedAverageTranscriptDelay,
                    });
                }
                didStore = true;

                if (!isProductionStage()) {
                    const words = rawWords.map((o) => ({
                        text: o.text,
                        startTimestamp: o.startTimestamp - bootTime,
                        endTimestamp: o.endTimestamp - bootTime,
                    }));

                    const msg = `isFinal: ${isFinal}, words:\n ${words
                        .map((o) => `${o.startTimestamp.toFixed(1)} - ${o.text}`)
                        .join('\n')}`;
                    if (window.sessionStorage.logInChat && session) {
                        const payload: PostVideoAgentChatMessageRequest = {
                            botId: session.botId,
                            message: msg,
                        };
                        postVideoAgentChatMessage(payload);
                    }
                }

                if (words.toLowerCase().replace(/[^a-z\s]/g, '') === 'reload this page') {
                    window.location.reload();
                }
                if (words.toLowerCase().replace(/[^a-z\s]/g, '') === 'print the text here') {
                    window.sessionStorage.logInChat = true;
                }

                if (words.toLowerCase().replace(/[^a-z\s]/g, '') === 'why did it trigger') {
                    send({ type: 'WHY_DID_IT_TRIGGER' });
                }
                if (words.toLowerCase().replace(/[^a-z\s]/g, '') === 'why did it not trigger') {
                    send({ type: 'WHY_DID_IT_NOT_TRIGGER' });
                }

                if (isFinal && session?.isOnFeedbackTopic && session.settings.isFeedbackCollectionEnabled) {
                    setSession(session.withAddedUserFeedback(`${speaker}: ${words}`));
                }

                send({
                    type: 'NEW_TEXT',
                    data: {
                        id: original_transcript_id,
                        text: words,
                        final: isFinal,
                        speaker,
                        rawWords,
                        timestamp: new Date(firstWordTimestamp).toISOString(),
                    },
                });
            } catch (error) {
                Sentry.captureException(error);
                if (!didStore) {
                    transcriptToSync.current.push({
                        botId,
                        rawPayload: event.data,
                        receivedAt: new Date().toISOString(),
                    });
                }
            }
        };

        ws.onopen = () => {};

        ws.onclose = () => {};
        return () => {
            ws.close();
        };
    }, [botId, send, session, setSession]);
}
