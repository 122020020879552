import { useLocation } from 'react-router-dom';

import { ClientEventType } from '@spinach-shared/types';

import { ClientPath, useExperienceTracking, useGlobalAuthedUser, useGlobalRouting } from '../../../..';
import { ReactComponent as SpinachAiApp } from '../../../assets/Spinach_AI.svg';
import { ReactComponent as SpinachStandupApp } from '../../../assets/Spinach_Standup.svg';
import { BootstrapTooltip } from '../../common';

export function DashboardSelectorIcons(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { routeToDashboard, routeToAIDashboard } = useGlobalRouting();
    const { pathname } = useLocation();

    const baseIconStyle = {
        width: '50px',
        height: '50px',
        cursor: 'pointer',
        transition: 'filter 0.2s',
        margin: '10px auto',
        display: 'block',
    };

    return (
        <>
            <BootstrapTooltip title={`${user.meetingWord}s`} placement={'right'}>
                <SpinachStandupApp
                    style={{
                        ...baseIconStyle,
                        width: pathname === ClientPath.Root ? '50px' : '48px',
                        height: pathname === ClientPath.Root ? '50px' : '48px',
                        filter: pathname === ClientPath.Root ? 'none' : 'grayscale(100%) invert(1)',
                        border: pathname === ClientPath.Root ? 'none' : '2px solid white',
                        borderRadius: pathname === ClientPath.Root ? '0px' : '10px',
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.opacity = '0.7';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.opacity = '1';
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Dashboard Sidebar Standup App' });
                        routeToDashboard();
                    }}
                />
            </BootstrapTooltip>
            <BootstrapTooltip title="Spinach AI" placement={'right'}>
                <SpinachAiApp
                    style={{
                        ...baseIconStyle,
                        width: pathname === ClientPath.AIHome ? '50px' : '48px',
                        height: pathname === ClientPath.AIHome ? '50px' : '48px',
                        filter: pathname === ClientPath.AIHome ? 'none' : 'grayscale(100%) invert(1)',
                        border: pathname === ClientPath.AIHome ? 'none' : '2px solid white',
                        borderRadius: pathname === ClientPath.AIHome ? '0px' : '10px',
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.opacity = '0.7';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.opacity = '1';
                    }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, { ClickedOn: 'Dashboard Sidebar AI App' });
                        routeToAIDashboard();
                    }}
                />
            </BootstrapTooltip>
        </>
    );
}
