import { Backdrop, CircularProgress, Modal, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import {
    BaseIDRequest,
    ClientEventType,
    ClientSocketEvent,
    InviteViaSlackActions,
    MeetingSettingsSubview,
} from '@spinach-shared/types';

import { patchUser, postExperienceEvent } from '../../apis';
import { ReactComponent as LeftDesign } from '../../assets/left-side-bg.svg';
import { ReactComponent as RightDesign } from '../../assets/right-side-bg.svg';
import { ReactComponent as SlackLogo } from '../../assets/slack-logo.svg';
import { GlobalModal, GlobalModalFrom, atomLiveSeries } from '../../atoms';
import {
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalModalState,
    useGlobalNullableLiveSeries,
    useGlobalNullableStoredSeries,
    useGlobalRouting,
    useIntegrationDetection,
} from '../../hooks';
import { useGlobalMeetingSettings } from '../../hooks/useGlobalMeetingSettings';
import { useGlobalNullableMeetingSocket } from '../../hooks/useGlobalSocket';
import { useGlobalSlack } from '../../hooks/useSlack';
import { BodyBig, BodyBigOnboard, BodyRegular, ButtonSize, HeaderThree, lightTheme } from '../../styles';
import { URLUtil, createWebsocketPayload, usePracticeRoundCompletion, withContentMasking } from '../../utils';
import { useSpinachInputStyles } from '../input';
import { ShareSeriesModal } from '../series';
import { CreateSeriesModalContainer } from '../series/CreateStandupModal';
import { RemoveSeriesModalContainer } from '../series/RemoveSeriesModal';
import { RenameSeriesModalContainer } from '../series/RenameSeriesModal';
import { ModalContent, ResponsiveModalContent } from '../series/common';
import { PrimaryButton } from '../stand-up';
import { OutlinedButton } from '../stand-up/OutlinedButton';
import SecondaryButton from '../stand-up/SecondaryButton';
import { BootstrapTooltip } from './BootstrapTooltip';
import { CloseButton } from './CloseButton';
import { ScheduleModalContainer } from './ScheduleModal';
import { SpinachModalContent } from './SpinachModalContent';
import { Column, Hairline, Row, Spacing } from './framing';
import {
    AccountManagementModal,
    ArchivedUserGoalsModal,
    ConfirmDeactivateUserAsAdmin,
    ConfirmDeleteMeetingAsAdminModal,
    ConfirmViewMeetingAsAdmin,
    ConnectTicketSource,
    GlobalManageDraftSummaryRecipientsModal,
    GlobalSubscribeSeriesOutputModal,
    IntegrationsSettingsModal,
    MeditationPromptModalContainer,
    ShareAiHistoryModal,
    WebAuthRouterModal,
} from './modals';
import { ActivateTrialModal } from './modals/ActivateTrialModal';
import { AddPrivateChannelInstructionsModal } from './modals/AddPrivateChannelInstructionsModal';
import { AddTeamMemberModal } from './modals/AddTeamMemberModal';
import { ClaimHostModal } from './modals/ClaimHostModal';
import { FinishedActivatingTrialModal } from './modals/FinishedActivatingTrialModal';
import { ImageUploadModal } from './modals/ImageUploadModal';
import { KnowledgeBaseSetupCompleteModal } from './modals/KnowledgeBaseSetupComplete';
import { GlobalManageSeriesAdditionalEditorsModal } from './modals/ManageSeriesAdditionalEditorsModal';
import { NeedCalendarAccessToActivateTrialModal } from './modals/NeedCalendarAccessToActivateTrialModal';
import { SSOProvisioningInstructionsModal } from './modals/SSOProvisioningInstructions';
import { SelectTicketProject } from './modals/SelectTicketProject';
import { SeriesSettingsModalContainer } from './modals/SeriesSettingsModal';
import { ConfigureSlackHuddlesModal } from './modals/SlackHuddleConnectionModal';
import { GlobalSpinachReferralModal } from './modals/SpinachReferralModal';
import { UpgradeToRecallCalendarV2Modal } from './modals/UpgradeToRecallCalendarV2Modal';
import { ZapierApiKeyModal } from './modals/ZapierApiKeyModal';

export function GlobalShareSeriesModal(): JSX.Element {
    const [series] = useGlobalNullableStoredSeries();
    const [openModal, setOpenModal] = useGlobalModal();
    const [user] = useGlobalAuthedUser();

    if (!series) {
        return <></>;
    }

    if (!user) {
        return <></>;
    }

    return (
        <ShareSeriesModal
            isOpen={openModal === GlobalModal.ShareSeries}
            onClose={() => {
                setOpenModal(null);
            }}
            series={series}
            userId={user.spinachUserId}
        />
    );
}

export function GlobalInviteViaSlackModal(): JSX.Element {
    const { from } = useGlobalModalState();
    const [openModal, setOpenModal] = useGlobalModal();

    const { setSubview } = useGlobalMeetingSettings();
    const [liveSeries] = useGlobalNullableLiveSeries();
    const [user] = useGlobalAuthedUser();
    const [socket] = useGlobalNullableMeetingSocket();
    const { slackState } = useGlobalSlack();

    const track = useExperienceTracking();
    const [isLoading, setIsLoading] = useState(false);

    const startDetection = useIntegrationDetection(() => {
        setIsLoading(false);
        setOpenModal(GlobalModal.IntegrationsSettings);
        setSubview(MeetingSettingsSubview.SlackSettings);
    });

    const shouldShowModal =
        !!liveSeries && !!slackState.installUrl && !!socket && openModal === GlobalModal.InviteViaSlack;

    useEffect(() => {
        if (shouldShowModal) {
            track(ClientEventType.InviteViaSlackModalViewed, {
                From: from,
            });
        }
    }, [shouldShowModal]);

    if (!shouldShowModal) {
        return <></>;
    }

    return (
        <Modal
            open={openModal === GlobalModal.InviteViaSlack}
            onClose={() => {
                setOpenModal(null);
            }}
        >
            <SpinachModalContent
                onClose={() => {
                    setOpenModal(null);
                }}
                style={{ minHeight: 'unset' }}
            >
                <Spacing factor={1 / 2} />
                <Column>
                    <HeaderThree>Invite your teammates</HeaderThree>
                    <Spacing factor={1 / 2} />
                    <BodyRegular style={{ textAlign: 'center', fontSize: '16px' }}>
                        Connect Spinach to your workspace to start inviting teammates to your {user.meetingWord}
                    </BodyRegular>
                    <Spacing factor={1 / 2} />
                    <Row style={{ justifyContent: 'center' }}>
                        <PrimaryButton
                            preIcon={<SlackLogo style={{ width: '20px', height: '20px', paddingRight: '3px' }} />}
                            isLoading={isLoading}
                            style={{ backgroundColor: '#F4F4F4', border: '1px solid #B8B8B8' }}
                            labelStyles={{
                                alignItems: 'center',
                                display: 'flex',
                                color: lightTheme.primary.midnight,
                            }}
                            title={'Connect to Slack'}
                            onClick={() => {
                                track(ClientEventType.InviteViaSlackClick, {
                                    Action: InviteViaSlackActions.Integrate,
                                });
                                startDetection();
                                setIsLoading(true);
                                URLUtil.openURL(slackState.installUrl);
                            }}
                        />
                        <Spacing horizontal={true} factor={1 / 2} />
                        <OutlinedButton
                            title="Other Options"
                            onClick={() => {
                                track(ClientEventType.InviteViaSlackClick, {
                                    Action: InviteViaSlackActions.OtherOptions,
                                });
                                setOpenModal(GlobalModal.ShareSeries);
                            }}
                        />
                    </Row>
                    {from === GlobalModalFrom.StartStandup ? (
                        <>
                            <Spacing factor={1 / 2} />
                            <SecondaryButton
                                onClick={() => {
                                    track(ClientEventType.InviteViaSlackClick, {
                                        Action: InviteViaSlackActions.InviteLater,
                                    });
                                    const payload = createWebsocketPayload<BaseIDRequest>({
                                        spinachUserId: user.spinachUserId,
                                        seriesSlug: liveSeries.slug,
                                        meetingId: liveSeries.currentMeeting.id,
                                    });

                                    setOpenModal(null);
                                    if (liveSeries.currentMeeting.isMeditationActivated) {
                                        socket.emit(ClientSocketEvent.MeditationStarting, payload);

                                        return;
                                    }

                                    socket.emit(ClientSocketEvent.AgendaStarting, payload);
                                }}
                                title="Invite Later"
                            />
                        </>
                    ) : (
                        <></>
                    )}
                    <Spacing factor={1 / 2} />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}

export function GlobalUserAccountModal(): JSX.Element {
    const [openModal, setOpenModal] = useGlobalModal();
    const [user, setUser] = useGlobalAuthedUser();
    const [firstName, setFirstName] = useState(user?.firstName ?? '');
    const [lastName, setLastName] = useState(user?.lastName ?? '');
    const track = useExperienceTracking();

    const [isLoading, setIsLoading] = useState(false);

    const classes = useSpinachInputStyles({ value: firstName });
    const [firstNameError, setFirstNameError] = useState<boolean>(false);
    const [lastNameError, setLastNameError] = useState<boolean>(false);

    useEffect(() => {
        setFirstNameError(!firstName || !firstName.trim().length);
    }, [firstName]);

    useEffect(() => {
        setLastNameError(!lastName || !lastName.trim().length);
    }, [lastName]);

    useEffect(() => {
        setFirstName(user?.firstName ?? '');
    }, [user?.firstName]);

    useEffect(() => {
        setLastName(user?.lastName ?? '');
    }, [user?.lastName]);

    if (!user) {
        return <></>;
    }

    return (
        <Modal
            open={openModal === GlobalModal.UserAccount}
            onClose={() => {
                setOpenModal(null);
            }}
        >
            <ResponsiveModalContent style={{ overflowY: 'unset' }}>
                <CloseButton
                    style={{
                        left: '3px',
                        top: '9px',
                    }}
                    onClick={() => {
                        setOpenModal(null);
                    }}
                />
                <Spacing factor={1 / 2} />
                <Column
                    style={{
                        backgroundColor: 'white',
                        border: `1px solid rgb(218,220,224)`,
                        borderRadius: '8px',
                        padding: '10px',
                    }}
                >
                    <Column>
                        <BodyBigOnboard>Your Profile</BodyBigOnboard>
                    </Column>
                    <Hairline />
                    <Spacing factor={1 / 3} />
                    <Column style={{ alignItems: 'start' }}>
                        <BodyBigOnboard>Your name</BodyBigOnboard>
                        <Column>
                            <Row>
                                <TextField
                                    value={firstName}
                                    helperText={firstNameError ? "Sorry, your first name can't be empty" : ''}
                                    error={firstNameError}
                                    InputProps={{ classes: { root: classes.base } }}
                                    {...withContentMasking(classes.root)}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    style={{ marginRight: '10px' }}
                                    inputProps={{ maxLength: 50 }}
                                    placeholder="First Name"
                                />

                                <TextField
                                    value={lastName}
                                    helperText={lastNameError ? "Sorry, your last name can't be empty" : ''}
                                    error={lastNameError}
                                    InputProps={{ classes: { root: classes.base } }}
                                    {...withContentMasking(classes.root)}
                                    onChange={(e) => setLastName(e.target.value)}
                                    inputProps={{ maxLength: 50 }}
                                    placeholder="Last Name"
                                />
                            </Row>
                        </Column>
                    </Column>
                </Column>

                <Spacing factor={2 / 3} />

                <BootstrapTooltip
                    title={
                        firstNameError || lastNameError
                            ? 'How will people know what to call you if you enter an empty name?'
                            : ''
                    }
                    style={{ position: 'absolute', bottom: 'calc(0.05 * 70vmin)' }}
                    placement={'top'}
                    arrow
                >
                    <div>
                        <PrimaryButton
                            size={ButtonSize.Small}
                            isLoading={isLoading}
                            disabled={
                                (lastName === user.lastName && firstName === user.firstName) ||
                                firstNameError ||
                                lastNameError
                            }
                            title="Save"
                            onClick={async () => {
                                track(ClientEventType.UserClickedConfirmedAccountChanges, {
                                    ChangedLastName: lastName !== user.lastName,
                                    ChangedFirstName: firstName !== user.firstName,
                                });
                                setIsLoading(true);

                                const { user: updatedUser } = await patchUser({
                                    metadata: { lastName: lastName.trim(), firstName: firstName.trim() },
                                });
                                if (updatedUser) {
                                    setUser(updatedUser);
                                }
                                setOpenModal(null);
                                setIsLoading(false);
                            }}
                        />
                    </div>
                </BootstrapTooltip>
                <RightDesign
                    style={{
                        position: 'absolute',
                        bottom: '0px',
                        right: '0px',
                        height: '40%',
                        width: '10%',
                        zIndex: -1,
                        display: 'flex',
                        alignContent: 'center',
                    }}
                />
                <LeftDesign
                    style={{
                        position: 'absolute',
                        bottom: '0px',
                        left: '0px',
                        height: '40%',
                        width: '10%',
                        zIndex: -1,
                        display: 'flex',
                        alignContent: 'center',
                    }}
                />
                <LeftDesign
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        height: '40%',
                        width: '10%',
                        zIndex: -1,
                        display: 'flex',
                        alignContent: 'center',
                        transform: 'rotateY(180deg)',
                    }}
                />
                <RightDesign
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        height: '40%',
                        width: '10%',
                        zIndex: -1,
                        display: 'flex',
                        alignContent: 'center',
                        transform: 'rotateY(180deg)',
                    }}
                />
            </ResponsiveModalContent>
        </Modal>
    );
}

export function ConfirmLeaveDemoModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { routeToOnboarding } = useGlobalRouting();
    const [openModal, setOpenModal] = useGlobalModal();
    const [liveSeries] = useRecoilState(atomLiveSeries);
    const [isLoading, setIsLoading] = useState(false);
    const { leavePracticeRound } = usePracticeRoundCompletion();

    if (!liveSeries) {
        return <></>;
    }

    return (
        <Modal
            open={openModal === GlobalModal.ConfirmLeaveDemo}
            onClose={() => {
                setOpenModal(null);
            }}
        >
            <ModalContent>
                <CloseButton
                    style={{
                        left: '3px',
                        top: '9px',
                    }}
                    onClick={() => {
                        setOpenModal(null);
                    }}
                />
                <Column>
                    <Spacing factor={1 / 2} />

                    <BodyBig style={{ textAlign: 'center' }}>Are you sure you want to leave Practice Round?</BodyBig>

                    <Spacing factor={3 / 2} />

                    <PrimaryButton
                        isLoading={isLoading}
                        onClick={async () => {
                            setIsLoading(true);
                            postExperienceEvent({
                                eventType: ClientEventType.ConfirmLeaveDemoModeClick,
                                payload: {
                                    ...user.toUserIdentityPayload(),
                                },
                            });

                            if (user.isDemoing) {
                                routeToOnboarding({ replace: true });
                            } else {
                                leavePracticeRound();
                            }

                            setIsLoading(false);
                        }}
                        title="Leave Practice Round"
                    />

                    <Spacing factor={2 / 3} />

                    <SecondaryButton
                        title="Continue Practice Round"
                        onClick={() => {
                            postExperienceEvent({
                                eventType: ClientEventType.ContinuePracticeRoundClick,
                                payload: {
                                    ...user.toUserIdentityPayload(),
                                },
                            });

                            setOpenModal(null);
                        }}
                    />
                </Column>
            </ModalContent>
        </Modal>
    );
}

function LoadingBackdrop(): JSX.Element {
    const [globalModal] = useGlobalModal();

    if (globalModal !== GlobalModal.LoadingBackdrop) {
        return <></>;
    }

    return (
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={true} onClick={() => undefined}>
            <Column centered>
                <BodyBigOnboard style={{ paddingRight: '10px', color: 'inherit' }}>
                    Updating automations...
                </BodyBigOnboard>
                <Spacing />
                <CircularProgress color="inherit" />
            </Column>
        </Backdrop>
    );
}

export function GlobalModalContainer(): JSX.Element {
    return (
        <div>
            <GlobalShareSeriesModal />
            <ConfirmLeaveDemoModal />
            <WebAuthRouterModal />
            <GlobalUserAccountModal />
            <SeriesSettingsModalContainer />
            <ArchivedUserGoalsModal />
            <GlobalInviteViaSlackModal />
            <GlobalSubscribeSeriesOutputModal />
            <MeditationPromptModalContainer />
            <GlobalSpinachReferralModal />
            <IntegrationsSettingsModal />
            <ScheduleModalContainer />
            <ConnectTicketSource />
            <SelectTicketProject />
            <KnowledgeBaseSetupCompleteModal />
            <RemoveSeriesModalContainer />
            <CreateSeriesModalContainer />
            <RenameSeriesModalContainer />
            <ImageUploadModal />
            <AddPrivateChannelInstructionsModal />
            <SSOProvisioningInstructionsModal />
            <ConfirmDeleteMeetingAsAdminModal />
            <ConfirmViewMeetingAsAdmin />
            <ConfirmDeactivateUserAsAdmin />
            <UpgradeToRecallCalendarV2Modal />
            <LoadingBackdrop />
            <ShareAiHistoryModal />
            <AccountManagementModal />
            <ConfigureSlackHuddlesModal />
            <ClaimHostModal />
            <ZapierApiKeyModal />
            <GlobalManageDraftSummaryRecipientsModal />
            <AddTeamMemberModal />
            <GlobalManageSeriesAdditionalEditorsModal />
            <FinishedActivatingTrialModal />
            <ActivateTrialModal />
            <NeedCalendarAccessToActivateTrialModal />
        </div>
    );
}
