import styled from 'styled-components';

export const ControlButton = styled.button<{ isActive?: boolean; variant?: 'primary' | 'secondary' | 'tertiary' }>`
    background: white;
    color: ${(props) => {
        if (props.variant === 'primary') return props.isActive ? props.theme.primary.midnight : '#4CAF50';
        return props.theme.primary.midnight;
    }};
    border: 1px solid
        ${(props) => {
            if (props.variant === 'primary') return props.isActive ? props.theme.primary.midnight : '#4CAF50';
            return props.theme.primary.midnight;
        }};
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: bold;
    transition: all 0.3s ease;
    font-size: 14px;
    min-width: 180px;
    height: 36px;
    width: 100%;

    @media (max-width: 600px) {
        min-width: 120px;
        padding: 8px 6px;
        font-size: 12px;
        gap: 4px;
    }

    @media (max-width: 480px) {
        min-width: 100px;
        padding: 8px 4px;
        font-size: 11px;
        gap: 2px;
    }

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    span {
        font-size: 18px;
        line-height: 1;
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
            font-size: 16px;
        }

        @media (max-width: 480px) {
            font-size: 14px;
        }
    }

    svg {
        @media (max-width: 600px) {
            font-size: 18px !important;
        }

        @media (max-width: 480px) {
            font-size: 16px !important;
        }
    }
`;
