import { CalendarToday, InfoOutlined, Lock } from '@material-ui/icons';
import { useState } from 'react';
import styled from 'styled-components';

import { ClientEventType } from '@spinach-shared/types';

import { useExperienceTracking, useLandingAnalytic, useWindowSize } from '../../../../..';
import CalendarWhySrc from '../../../../assets/calendar-why-v2.png';
import { ReactComponent as SpinachLogo } from '../../../../assets/logo-spinachio.svg';
import SecurityBadges from '../../../../assets/security-badges.png';
import { BodyBigOnboard, BodyRegularOnboard, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { Column, Row, Spacing } from '../../../common';
import { ScrollView } from '../../facilitation/common';
import { StepProps } from '../common';
import { StepTracker } from './StepTracker';
import { addToAllOnboardingSteps } from './constants';
import { Container, ContentContainer, CustomTooltip, Divider, TooltipContainer } from './shared';

const ContinueButton = styled.div`
    background-color: ${(props) => props.theme.primary.orangeDark};
    display: block;
    color: white;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-family: Gilroy, sans-serif;
    padding: 10px;
    height: 40px;
    width: 100%;
    font-size: 24px;
    text-align: center;
    line-height: 36px;

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }

    &:active {
        cursor: pointer;
        background-color: #eee;
        color: rgba(0, 0, 0, 0.54);
        opacity: 1;
    }
`;

export type AddToAllConfirmationStepProps = StepProps & {
    onProgressToAddNextStep: () => void;
};

export function AddToAllConfirmationStep({ onProgressToAddNextStep }: AddToAllConfirmationStepProps): JSX.Element {
    useLandingAnalytic(ClientEventType.UserViewedAddToAllConfirmationStep);

    const track = useExperienceTracking();
    const { isDesktopView } = useWindowSize();
    const [securityTooltipOpen, setSecurityTooltipOpen] = useState(false);

    return (
        <Container>
            <Row>
                <SpinachLogo
                    style={{
                        height: isDesktopView ? '55px' : '25px',
                        marginBottom: isDesktopView ? 'initial' : '20px',
                        marginLeft: isDesktopView ? 'initial' : '-20px',
                    }}
                />
            </Row>
            <ContentContainer>
                <Row centered>
                    <StepTracker steps={addToAllOnboardingSteps} currentStep={1} />
                </Row>
                <Spacing factor={isDesktopView ? 2.5 : 1} />
                <HeaderThreeOnboard>Setup Spinach</HeaderThreeOnboard>
                <Spacing factor={1} />
                <Row style={{ justifyContent: 'center', alignItems: 'flex-start', margin: '0 auto' }}>
                    <Column
                        style={{
                            flex: '0',
                            marginRight: isDesktopView ? '40px' : '15px',
                            marginLeft: isDesktopView ? '-40px' : '0px',
                        }}
                    >
                        <BodyBigOnboard style={{ fontSize: isDesktopView ? '32px' : '22px' }}>
                            <b>1.</b>
                        </BodyBigOnboard>
                    </Column>
                    <Column style={{ maxWidth: '400px', minWidth: '240px' }}>
                        <Row vCenter>
                            <BodyRegularOnboard style={{ fontSize: isDesktopView ? '18px' : '16px' }}>
                                Sync Calendar
                            </BodyRegularOnboard>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row>
                            <ContinueButton
                                onClick={() => {
                                    track(ClientEventType.UserClickedContinueAddToAllConfirmationStep);
                                    onProgressToAddNextStep();
                                }}
                            >
                                Continue
                            </ContinueButton>
                        </Row>

                        <Spacing factor={1 / 2} />
                        <Row>
                            <Column style={{ flex: 0, marginRight: '12px' }}>
                                <CalendarToday style={{ color: 'black', fontSize: '30px' }} htmlColor={'black'} />
                            </Column>
                            <Column>
                                <Row>
                                    <BodyRegularOnboard style={{ fontSize: '16px' }}>
                                        Spinach will be added to your meetings (you can change/remove in the dashboard
                                        any time)
                                    </BodyRegularOnboard>
                                </Row>
                            </Column>
                        </Row>
                        <Spacing factor={1 / 2} />
                        <Row vCenter>
                            <Column style={{ flex: 0, marginRight: '12px' }}>
                                <Lock style={{ color: 'black', fontSize: '30px' }} htmlColor={'black'} />
                            </Column>
                            <Column>
                                <Row>
                                    <BodyRegularOnboard style={{ fontSize: '16px' }}>
                                        All your data is encrypted and <b>never</b> used
                                        <br />
                                        <span style={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}>
                                            for AI model training
                                            <CustomTooltip
                                                enterTouchDelay={0}
                                                leaveTouchDelay={15000}
                                                onOpen={() => {
                                                    if (!securityTooltipOpen) {
                                                        setSecurityTooltipOpen(true);
                                                        track(ClientEventType.UserOpenedCalendarConnectSecurityTooltip);
                                                    }
                                                }}
                                                onClose={() => {
                                                    setSecurityTooltipOpen(false);
                                                }}
                                                style={{ marginLeft: '5px' }}
                                                interactive={true}
                                                title={
                                                    <TooltipContainer
                                                        style={{
                                                            width: isDesktopView ? '350px' : '300px',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <Row>
                                                            <Column style={{ flex: 0, marginRight: '17px' }}>
                                                                <Lock
                                                                    style={{ color: 'black', fontSize: '30px' }}
                                                                    htmlColor={'black'}
                                                                />
                                                            </Column>
                                                            <Column>
                                                                <BodyRegularOnboard
                                                                    style={{
                                                                        fontSize: '16px',
                                                                        textAlign: 'left',
                                                                    }}
                                                                >
                                                                    All systems are audited by EY on annual basis to
                                                                    confirm compliance and security practices with SOC 2
                                                                    standards.
                                                                </BodyRegularOnboard>
                                                                <Spacing factor={1 / 2} />
                                                            </Column>
                                                        </Row>
                                                        <Row centered>
                                                            <img src={SecurityBadges} style={{ width: '250px' }} />
                                                        </Row>
                                                    </TooltipContainer>
                                                }
                                                placement="top"
                                            >
                                                <InfoOutlined
                                                    htmlColor={lightTheme.primary.greenLight}
                                                    fontSize="small"
                                                    style={{ fontSize: '24px' }}
                                                />
                                            </CustomTooltip>
                                        </span>
                                    </BodyRegularOnboard>
                                </Row>
                            </Column>
                        </Row>
                    </Column>
                </Row>

                <Spacing factor={1} />
                <Divider />
                <Spacing factor={1} />
                <ScrollView sidePadding={0} stretch={true} style={{ height: 'calc(100vh - 705px)' }}>
                    <Row centered={true}>
                        <img
                            src={CalendarWhySrc}
                            style={{ maxWidth: '500px', minWidth: '100px' }}
                            alt="Following steps give you control of meetings that spinach can join"
                        />
                    </Row>
                </ScrollView>
            </ContentContainer>
        </Container>
    );
}
