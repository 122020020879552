import styled from 'styled-components';

import { ReactComponent as GreenCheckIcon } from '../../../../assets/green-circle-check.svg';
import { ShowcaseGuideCtaTarget, ShowcaseGuideSlideId, ShowcasePacket, ShowcaseSlide } from './types';

const FeatureList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const FeatureItem = styled.div`
    display: flex;
    align-items: start;
    gap: 8px;
    color: #666;
    font-size: 14px;
`;

export const showcaseGuideSlidesById: Record<ShowcaseGuideSlideId, ShowcaseSlide> = {
    [ShowcaseGuideSlideId.AddSpinachToYourMeeting]: {
        id: ShowcaseGuideSlideId.AddSpinachToYourMeeting,
        title: 'Add Spinach to your meeting',
        description: 'Spinach attends your meetings, takes notes, and delivers them to you afterwards',
        ctaLabel: 'See your meetings',
        ctaTarget: ShowcaseGuideCtaTarget.Meetings,
    },
    [ShowcaseGuideSlideId.LetUsInFromTheWaitingRoom]: {
        id: ShowcaseGuideSlideId.LetUsInFromTheWaitingRoom,
        title: 'Let us in from the waiting room',
        description: 'We might be trying to join - please check the waiting room to let us in!',
        ctaLabel: 'Got it',
    },
    [ShowcaseGuideSlideId.ReviewYourMeeting]: {
        id: ShowcaseGuideSlideId.ReviewYourMeeting,
        title: 'Customize your experience',
        description: "Edit your meeting summary, control summary access, change your meeting's language and more!",
        ctaLabel: 'View your settings',
        ctaTarget: ShowcaseGuideCtaTarget.Settings,
    },
    [ShowcaseGuideSlideId.RestartTrial]: {
        id: ShowcaseGuideSlideId.RestartTrial,
        title: 'RESTART_TRIAL_LENGTH days no credit card needed',
        description: (
            <FeatureList>
                <FeatureItem>
                    <GreenCheckIcon style={{ flexShrink: 0, marginTop: '5px' }} /> Advanced AI summary
                </FeatureItem>
                <FeatureItem>
                    <GreenCheckIcon style={{ flexShrink: 0, marginTop: '5px' }} /> Ask Spinach - AI assistant
                </FeatureItem>
                <FeatureItem>
                    <GreenCheckIcon style={{ flexShrink: 0, marginTop: '5px' }} /> Custom Branding
                </FeatureItem>
                <FeatureItem>
                    <GreenCheckIcon style={{ flexShrink: 0, marginTop: '5px' }} /> Shared Team or Company account
                </FeatureItem>
            </FeatureList>
        ),
        ctaLabel: 'Start Trial',
        ctaTarget: ShowcaseGuideCtaTarget.RestartTrial,
        showPrimaryCta: true,
    },
};

export const starterGuidePacket: ShowcasePacket = {
    title: 'Starter Guide',
    slides: [
        showcaseGuideSlidesById[ShowcaseGuideSlideId.AddSpinachToYourMeeting],
        showcaseGuideSlidesById[ShowcaseGuideSlideId.LetUsInFromTheWaitingRoom],
        showcaseGuideSlidesById[ShowcaseGuideSlideId.ReviewYourMeeting],
    ],
    isMinimizeable: true,
};

export const restartTrialGuidePacket: ShowcasePacket = {
    title: 'Try Spinach Pro',
    slides: [showcaseGuideSlidesById[ShowcaseGuideSlideId.RestartTrial]],
    isMinimizeable: false,
};
