import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SetterOrUpdater, useRecoilState } from 'recoil';

import { DashboardSection } from '@spinach-shared/constants';
import { MeetingSelection, WebUrlQuery } from '@spinach-shared/types';

import { GlobalAiDashboardState, atomAiDashboardState } from '../atoms';

export type SetGlobalAiDashboardState = SetterOrUpdater<GlobalAiDashboardState>;

export type GlobalAiDashboardProps = {
    state: GlobalAiDashboardState;
    toastText: GlobalAiDashboardState['toastText'];
    activeSection: DashboardSection;
    isOnDemoNotes: GlobalAiDashboardState['isOnDemoNotes'];
    isSearchVisible: GlobalAiDashboardState['isSearchVisible'];
    meetingOptions: MeetingSelection[];
    setToastTextAndClearPendingSpinachToggleICalUid: (toastText: string) => void;
    openMeetingsSection: () => void;
    openIntegrationsSection: () => void;
    openTranscriptsSection: () => void;
    setMeetingOptions: (meetingOptions: MeetingSelection[]) => void;
    openSummariesSection: () => void;
    setActiveSection: (activeSection: DashboardSection) => void;
    setToastText: (toastText: GlobalAiDashboardState['toastText']) => void;
    setIsOnDemoNotes: (isOnDemoNotes: GlobalAiDashboardState['isOnDemoNotes']) => void;
    setIsSearchVisible: (isSearchVisible: GlobalAiDashboardState['isSearchVisible']) => void;
    setState: SetGlobalAiDashboardState;
};

export function useGlobalAiDashboard(): GlobalAiDashboardProps {
    const [state, setState] = useRecoilState(atomAiDashboardState);
    const [searchParams, setSearchParams] = useSearchParams();

    function openMeetingsSection() {
        setActiveSection(DashboardSection.Meetings);
    }

    function openIntegrationsSection() {
        setActiveSection(DashboardSection.Integrations);
    }

    function openTranscriptsSection() {
        setActiveSection(DashboardSection.Transcripts);
    }

    function openSummariesSection() {
        setActiveSection(DashboardSection.Summaries);
    }

    function setActiveSection(activeSection: DashboardSection) {
        setState({ ...state, activeSection });
        const search = new URLSearchParams({
            ...Object.fromEntries(searchParams),
            ...{ [WebUrlQuery.Section]: activeSection },
        }).toString();
        setSearchParams(search, { replace: true });
    }

    function setToastTextAndClearPendingSpinachToggleICalUid(toastText: string) {
        const search = new URLSearchParams({
            ...Object.entries(Object.fromEntries(searchParams))
                .filter(([key]) => key !== WebUrlQuery.Action && key !== WebUrlQuery.ICalUid)
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
        }).toString();
        setState((state) => ({ ...state, toastText, pendingSpinachToggleICalUid: undefined }));
        setSearchParams(search, { replace: true });
    }

    const setToastText = useCallback(
        function setToastText(toastText: GlobalAiDashboardState['toastText']) {
            setState((state) => ({ ...state, toastText }));
        },
        [setState]
    );

    const setIsOnDemoNotes = useCallback(
        function setIsOnDemoNotes(isOnDemoNotes: GlobalAiDashboardState['isOnDemoNotes']) {
            setState((state) => ({ ...state, isOnDemoNotes }));
        },
        [setState]
    );

    const setMeetingOptions = useCallback(
        function setMeetingOptions(meetingOptions: MeetingSelection[]) {
            setState((state) => ({ ...state, meetingOptions }));
        },
        [setState]
    );

    const setIsSearchVisible = useCallback(
        function setIsSearchVisible(isSearchVisible: GlobalAiDashboardState['isSearchVisible']) {
            setState((state) => ({ ...state, isSearchVisible }));
        },
        [setState]
    );

    return {
        state,
        toastText: state.toastText,
        activeSection: state.activeSection,
        isOnDemoNotes: state.isOnDemoNotes,
        isSearchVisible: state.isSearchVisible,
        meetingOptions: state.meetingOptions,
        setToastTextAndClearPendingSpinachToggleICalUid,
        openMeetingsSection,
        setActiveSection,
        openIntegrationsSection,
        openTranscriptsSection,
        openSummariesSection,
        setToastText,
        setIsOnDemoNotes,
        setIsSearchVisible,
        setMeetingOptions,
        setState,
    };
}
