import { BundleType, PlanScope, PlanType, ProPlanType, SpinachAPIPath, StripePlans } from '@spinach-shared/types';

import { postSpinachAPI } from './postSpinachAPI';

export async function postCreateCheckoutSession(planName: string) {
    return await postSpinachAPI<{ clientSecret: string }>(SpinachAPIPath.CreateCheckoutSession, {
        planName,
    });
}

export async function postCreateCheckoutSessionV2(payload: {
    planType: PlanType;
    planScope: PlanScope;
    proPlanType?: ProPlanType;
    bundle?: string;
}) {
    return await postSpinachAPI<{ clientSecret: string }>(SpinachAPIPath.CreateCheckoutSessionV2, payload);
}
