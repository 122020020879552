import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

import { VideoAgentControlAction, VideoAgentControlCommand } from '@spinach-shared/types';

import { useGlobalVideoAgent } from '../../hooks/agent/useGlobalVideoAgent';
import { Row } from '../common';
import { ControlButton } from './common';

const BottomNavigationContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background: white;
    border-top: 1px solid rgba(11, 103, 74, 0.1);
    box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    z-index: 1;

    ${Row} {
        width: 100%;
        max-width: 600px;
        padding: 0 20px;
        gap: 8px;
    }
`;

const NavButton = styled(ControlButton)`
    min-width: 180px;
    flex: 1;

    @media (max-width: 600px) {
        min-width: 120px;
    }

    @media (max-width: 480px) {
        min-width: 100px;
    }
`;

const StartMeetingButton = styled(ControlButton)`
    background-color: #4caf50;
    color: white;
    border-color: #4caf50;
    min-width: 180px;
    width: 100%;
    max-width: 600px;
    margin: 0 20px;

    &:hover {
        background-color: #45a049;
    }

    @media (max-width: 600px) {
        min-width: 120px;
    }

    @media (max-width: 480px) {
        min-width: 100px;
    }
`;

export function BottomNavigation({ onCommand }: { onCommand: (command: VideoAgentControlCommand) => void }) {
    const { session } = useGlobalVideoAgent();

    const handleStartMeeting = () => {
        onCommand({ action: VideoAgentControlAction.Next });
    };

    return (
        <BottomNavigationContainer>
            {session.isLobbyPhase ? (
                <StartMeetingButton onClick={handleStartMeeting}>
                    {session.wasAgendaStarted ? 'Resume Agenda' : 'Start Agenda'}
                </StartMeetingButton>
            ) : session.isConclusionPhase ? (
                <Row centered={true}>
                    <NavButton onClick={() => onCommand({ action: VideoAgentControlAction.Previous })}>
                        <ChevronLeft />
                        Last Topic
                    </NavButton>
                </Row>
            ) : (
                <Row centered={true}>
                    <NavButton onClick={() => onCommand({ action: VideoAgentControlAction.Previous })}>
                        <ChevronLeft />
                        {session.isOnFirstTopic ? 'Back to Lobby' : 'Last Topic'}
                    </NavButton>
                    <NavButton onClick={() => onCommand({ action: VideoAgentControlAction.Next })}>
                        {session.isOnLastTopic ? 'Finish Agenda' : 'Next Topic'}
                        <ChevronRight />
                    </NavButton>
                </Row>
            )}
        </BottomNavigationContainer>
    );
}
