import { IconButton } from '@material-ui/core';
import { Add, Check, Close } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { VideoAgentSession } from '@spinach-shared/models';
import { ClientEventType } from '@spinach-shared/types';

import { useActivityTracking } from '../../hooks';
import { lightTheme } from '../../styles/theme';
import { AgentCommandType } from '../../types/agent';

const AddTopicContainer = styled.div`
    display: flex;
    height: 44px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 5px;
`;

const TopicInputContainer = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const TopicInput = styled.input`
    background: rgba(11, 103, 74, 0.1) !important;
    backdrop-filter: blur(8px);
    color: ${(props) => props.theme.primary.midnight} !important;
    height: 28px;
    flex: 1;
    width: 100%;
    padding: 10px;
    border: 1px solid white;
    border-radius: 4px;

    &::placeholder {
        color: rgba(29, 29, 39, 0.7);
    }

    &:focus {
        border-color: ${(props) => props.theme.primary.green};
    }
`;

const ActionButton = styled(IconButton)`
    &.MuiIconButton-root {
        width: 48px;
        height: 48px;
        padding: 4px;
        color: ${(props) => props.theme.primary.midnight};
        background: rgba(11, 103, 74, 0.1);
        border-radius: 4px;
        border: none;

        &:hover {
            background: rgba(11, 103, 74, 0.2);
        }

        svg {
            font-size: 20px;
        }
    }
`;

const AddTopicButton = styled.button`
    width: 100%;
    padding: 8px 12px;
    height: 50px;
    background: rgba(11, 103, 74, 0.05);
    border: 1px solid rgba(11, 103, 74, 0.4);
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    transition: all 0.2s ease-in-out;
    color: ${(props) => props.theme.primary.midnight};
    display: flex;
    align-items: center;
    font-size: 16px;

    &:hover {
        background: rgba(11, 103, 74, 0.1);
        border-color: #0b674a;
        transform: translateX(4px);
        width: calc(100% - 4px);
    }
`;

interface AddTopicProps {
    onAddTopic: (topicName: string) => void;
    session?: VideoAgentSession | null;
}

export function AddTopic({ onAddTopic, session }: AddTopicProps) {
    const [newTopicName, setNewTopicName] = useState<string>('');
    const [isAddingTopic, setIsAddingTopic] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const trackActivity = useActivityTracking();

    const handleAddTopic = () => {
        if (newTopicName.trim()) {
            trackActivity(ClientEventType.VideoAgentActivity, 'Add Topic', {
                TriggeredBy: AgentCommandType.RemoteControl,
                ...session?.analyticsPayload,
            });
            onAddTopic(newTopicName.trim());
            setNewTopicName('');
            setIsAddingTopic(false);
        }
    };

    const handleCancelAddTopic = () => {
        setIsAddingTopic(false);
        setNewTopicName('');
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleAddTopic();
        }
    };

    React.useEffect(() => {
        if (isAddingTopic && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isAddingTopic]);

    return (
        <AddTopicContainer>
            {isAddingTopic ? (
                <TopicInputContainer>
                    <TopicInput
                        ref={inputRef}
                        type="text"
                        value={newTopicName}
                        onChange={(e) => setNewTopicName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        onKeyDown={(event) => {
                            if (event.key === 'Escape') {
                                handleCancelAddTopic();
                            }
                        }}
                        placeholder="Enter topic name"
                    />
                    <ActionButton onClick={handleAddTopic} disabled={newTopicName.trim() === ''}>
                        <Check htmlColor={lightTheme.primary.green} />
                    </ActionButton>
                    <ActionButton onClick={handleCancelAddTopic}>
                        <Close htmlColor={lightTheme.status.negative} />
                    </ActionButton>
                </TopicInputContainer>
            ) : (
                <AddTopicButton onClick={() => setIsAddingTopic(true)}>
                    <Add style={{ color: lightTheme.primary.midnight, marginRight: '8px' }} />
                    Add a Topic
                </AddTopicButton>
            )}
        </AddTopicContainer>
    );
}
