import { VideoAgentControlAction, VideoAgentControlCommand } from '@spinach-shared/types';

import { useGlobalVideoAgent } from '../../..';
import { AddTopic } from './AddTopic';
import { AgendaList } from './AgendaList';

interface AgendaContentProps {
    onCommand: (command: VideoAgentControlCommand) => void;
}

export function AgendaContent({ onCommand }: AgendaContentProps) {
    const { session } = useGlobalVideoAgent();

    const handleAddTopic = (topicName: string) => {
        onCommand({ action: VideoAgentControlAction.AddTopic, topicName });
    };

    return (
        <>
            <AgendaList onCommand={onCommand} />
            <AddTopic onAddTopic={handleAddTopic} session={session} />
        </>
    );
}
