import { Box } from '@material-ui/core';
import { CSSProperties, useEffect, useRef, useState } from 'react';

import { VideoAgentSessionTopic } from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import { Column, Row, useGlobalModal, useGlobalVideoAgent } from '../../../..';
import { ReactComponent as SpinachLogo } from '../../../assets/just-s-logo.svg';
import { GlobalModal } from '../../../atoms';
import { safeWidth } from '../../../constants';
import { AgentAgendaNextTopic } from './AgentAgendaNextTopic';
import { DiagonalBox } from './DiagonalBox';
import { NoAgendaContent } from './NoAgendaContent';

// Courtesy of GPT
function paginateTopics(topics: VideoAgentSessionTopic[], pageSize: number): VideoAgentSessionTopic[][] {
    const pages: VideoAgentSessionTopic[][] = [];
    for (let i = 0; i < topics.length; i += pageSize) {
        pages.push(topics.slice(i, i + pageSize));
    }
    return pages;
}

export function AgentLobbyContent(): JSX.Element {
    const { session } = useGlobalVideoAgent();
    const [globalModal, setGlobalModal] = useGlobalModal();

    return (
        <Box
            height="100vh"
            width="100vw"
            display="flex"
            justifyContent="center"
            style={{ backgroundColor: 'transparent' }}
            onClick={() => {
                if (isLocalStage()) {
                    if (globalModal === GlobalModal.VideoAgentTestControls) {
                        setGlobalModal(null);
                    } else {
                        setGlobalModal(GlobalModal.VideoAgentTestControls);
                    }
                }
            }}
        >
            <Box
                position="absolute"
                width={safeWidth}
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection={'column'}
                zIndex={30}
                key={session.topics.length}
            >
                <Box
                    style={{
                        textAlign: 'center',
                        fontSize: '60px',
                        marginBottom: '50px',
                        transform: 'scale(1.1)',
                        color: 'white',
                        marginTop: '30px',
                        position: 'relative', // Added to position the boxes relatively
                        lineHeight: 1.4,
                    }}
                >
                    {session.isPaused ? (
                        <DiagonalBox color="#FCD008" text="Recording Paused" />
                    ) : (
                        <Row style={{ height: '84px' }}></Row>
                    )}
                </Box>

                {session.topics.length ? <LobbyAgendaContent /> : <NoAgendaContent />}
            </Box>
        </Box>
    );
}

function StartInstructions(): JSX.Element {
    const { session } = useGlobalVideoAgent();

    if (!session.isRoundtableEnabled && !session.topics.length) {
        return (
            <Column centered={true} vCentered={true} style={{ height: '100%' }}>
                <SpinachLogo
                    style={{
                        height: '70px',
                        marginBottom: '20px',
                    }}
                />
                <Box
                    style={{
                        textAlign: 'center',
                        fontSize: '55px',
                        transform: 'scale(1.1)',
                        color: 'white',
                        position: 'relative', // Added to position the boxes relatively
                    }}
                >
                    To add agenda topics {session.settings.isVoicePrimaryCommandKind ? 'say' : 'type'}
                    <br />
                    {session.settings.isVoicePrimaryCommandKind ? (
                        <DiagonalBox color="#00DD00" text="Spinach Add <topic>" />
                    ) : (
                        <DiagonalBox color="#00DD00" text="Add <topic>" />
                    )}
                    <br />
                    {!session.settings.isVoicePrimaryCommandKind ? 'into chat' : ''}
                </Box>
            </Column>
        );
    }

    if (session.settings.isVoicePrimaryCommandKind) {
        return (
            <>
                Say <DiagonalBox color="#00DD00" text="Spinach Start" /> to Begin
            </>
        );
    } else {
        return (
            <>
                Type <DiagonalBox color="#00DD00" text="Start" /> in Chat to Begin
            </>
        );
    }
}

function LobbyAgendaContent(): JSX.Element {
    const {
        session,
        state: { config },
    } = useGlobalVideoAgent();
    const [pageIndex, setPageIndex] = useState(0);
    const paginatedTopics = paginateTopics(session.topics, config.maxTopicsListedPerPage);

    // TODO ensure timer doesnt get reset upon each topic add
    const interval = useRef<NodeJS.Timeout>();
    const [topicsOpacity, setTopicsOpacity] = useState(1);
    useEffect(() => {
        const shouldPageThrough = paginatedTopics.length > 1;

        if (!shouldPageThrough) {
            setPageIndex(0);
            return;
        }

        interval.current = setInterval(() => {
            setTopicsOpacity(0);
            setTimeout(() => {
                setTopicsOpacity(1);
                setPageIndex((pageIndex + 1) % paginatedTopics.length);
            }, 500);
        }, 5000);

        return () => {
            if (interval.current) {
                clearInterval(interval?.current);
            }
        };
    }, [pageIndex, paginatedTopics.length]);
    const agendaListStyles: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        fontSize: '45px',
        color: 'white',
        marginTop: '10px',
        lineHeight: '1.3',
        opacity: topicsOpacity,
        transition: 'opacity 0.5s',
    };
    return (
        <>
            <Box
                style={{
                    width: '100%',
                    fontSize: '25px',
                    marginTop: '10px',
                    color: '#00ff00',
                }}
            >
                <b>AGENDA</b>
            </Box>
            {session.topics.length ? (
                <ol style={agendaListStyles} start={pageIndex * config.maxTopicsListedPerPage + 1}>
                    {paginatedTopics[pageIndex]?.map((topic) => (
                        <li key={topic.id}>
                            <div
                                style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {topic.title}
                            </div>
                        </li>
                    ))}
                </ol>
            ) : (
                <div style={agendaListStyles}>Add a topic to the agenda</div>
            )}
            <Box flexGrow={1} />

            <AgentAgendaNextTopic />
        </>
    );
}
