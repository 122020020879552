export enum ClientPath {
    Root = '/',
    Onboarding = '/welcome',
    Demo = '/demo',
    Meeting = '/meeting',
    SeriesIdParam = ':seriesId',
    MeetingPath = '/meeting/:seriesId',
    SchedulePath = '/meeting/:seriesId/schedule',
    CustomizeSeriesPath = '/meeting/:seriesId/customize',
    SummariesPath = '/meeting/:seriesId/summaries',
    CreateSeriesFlow = '/create-meeting',
    Verify = '/verify',
    VerifyGoogleCode = '/verify-google-code',
    VerifyMicrosoftCode = '/verify-microsoft-code',
    SignUp = '/signup',
    TrySpinach = '/try-spinach',
    Sample = '/sample',
    Unknown = '*',
    Experiment = '/experiment',
    ActivateTrial = '/activate-trial',

    // AI paths
    OnboardingAI = '/ai/welcome',
    AIHome = '/ai/home',
    AICalendarInvite = '/ai/calendar/invite',
    AIEditSummary = '/ai/edit-summary',
    AIFacilitation = '/ai/facilitation/:seriesId',

    VideoAgent = '/video-agent',
    Control = '/control',
    ControlWithBot = '/control/:botId',

    Redirect = '/redirect',
}
