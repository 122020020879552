import { Box, Modal } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import {
    ClientEventType,
    KnowledgeBaseIntegration,
    SpinachIntegration,
    SpinachIntegrations,
} from '@spinach-shared/types';

import { ReactComponent as ConfluenceFolderLayout } from '../../../assets/integrations/confluence-folder-layout.svg';
import { ReactComponent as GoogleDriveFolderLayout } from '../../../assets/integrations/google-drive-folder-layout.svg';
import { ReactComponent as NotionFolderLayout } from '../../../assets/integrations/notion-folder-layout.svg';
import { GlobalModal, GlobalModalMetadataType } from '../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal, useGlobalModalState } from '../../../hooks';
import { BodyHuge, BodyRegularOnboard, responsiveness } from '../../../styles';
import { URLUtil } from '../../../utils';
import { PrimaryButton } from '../../stand-up';
import { SpinachModalContent } from '../SpinachModalContent';
import { Anchor, Column, Row, Spacing } from '../framing';
import { DriveVideoUploadSettings } from './DriveVideoUploadSettings';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';

const ResponsiveModalTitle = styled(BodyHuge)`
    @media ${responsiveness.thinnerThanSM} {
        font-size: 20px;
    }

    @media ${responsiveness.thinnerThanXS} {
        font-size: 18px;
    }
`;

const knowledgeBaseIntegrationImageComponentMap: Record<KnowledgeBaseIntegration, JSX.Element> = {
    [SpinachIntegration.GoogleDrive]: <GoogleDriveFolderLayout />,
    [SpinachIntegration.Confluence]: <ConfluenceFolderLayout />,
    [SpinachIntegration.Notion]: <NotionFolderLayout />,
};

const knowledgeBaseIntegrationSuccessCopyMap: Record<KnowledgeBaseIntegration, string> = {
    [SpinachIntegration.GoogleDrive]:
        'We’ve added the following folders to your Drive. Once your meeting(s) end, Spinach will generate and send meeting notes here.',
    [SpinachIntegration.Confluence]:
        'We’ve added the following pages to Confluence. Once your meeting(s) end, Spinach will generate and send meeting notes here.',
    /** @TODO update this in a followup PR */
    [SpinachIntegration.Notion]:
        'We’ve added the following pages to Notion. Once your meeting(s) end, Spinach will generate and send meeting notes here.',
};

function KnowledgeBaseSetupFailedModalContent({
    knowledgeBaseIntegration,
}: {
    knowledgeBaseIntegration: KnowledgeBaseIntegration;
}) {
    const track = useExperienceTracking();
    const integrationName = SpinachIntegrations[knowledgeBaseIntegration].displayName;

    const linkToOpen =
        knowledgeBaseIntegration === SpinachIntegration.GoogleDrive
            ? `${getClientConfigValue('INTERCOM_SUPPORT_URL')}/articles/8150045-google-drive-docs-integration`
            : undefined;

    return (
        <>
            <Spacing factor={1 / 2} />
            <Column style={{ alignItems: 'start' }}>
                <BodyRegularOnboard>We're unable to connect.</BodyRegularOnboard>
                <Spacing factor={1 / 3} />
                <BodyRegularOnboard>
                    Check that your Spinach login is the same as your {integrationName} account and try again. If your
                    emails are the same, contact support{' '}
                    <Anchor
                        id={linkToOpen ? undefined : 'spinach_intercom'}
                        // dynamic id if we have no article
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Support for Failed ${integrationName}`,
                            });
                            if (linkToOpen) {
                                URLUtil.openURL(linkToOpen);
                            }
                        }}
                    >
                        here
                    </Anchor>
                    .
                </BodyRegularOnboard>
            </Column>
        </>
    );
}

function KnowledgeBaseSetupSuccessModalContent({
    knowledgeBaseIntegration,
}: {
    knowledgeBaseIntegration: KnowledgeBaseIntegration;
}) {
    return (
        <>
            <BodyRegularOnboard>{knowledgeBaseIntegrationSuccessCopyMap[knowledgeBaseIntegration]}</BodyRegularOnboard>
            <Spacing factor={1 / 3} />
            {knowledgeBaseIntegrationImageComponentMap[knowledgeBaseIntegration]}
            <Spacing factor={1 / 3} />
            <BodyRegularOnboard>
                Note: You can move the page and Spinach will continue to write to this page location
            </BodyRegularOnboard>

            {knowledgeBaseIntegration === SpinachIntegration.GoogleDrive ? (
                <>
                    <Box
                        style={{
                            marginTop: '20px',
                            marginBottom: '20px',
                            background: 'black',
                            height: '1px',
                            opacity: 0.1,
                            alignSelf: 'stretch',
                        }}
                    />
                    <DriveVideoUploadSettings />
                </>
            ) : null}
        </>
    );
}

function KnowledgeBaseSetupCompleteModalContent({
    onClose,
    setupFailed,
    knowledgeBaseIntegration,
}: {
    onClose: () => void;
    setupFailed: boolean;
    knowledgeBaseIntegration: KnowledgeBaseIntegration;
}) {
    return (
        <SpinachModalContent onClose={onClose} style={{ overflow: 'hidden' }}>
            <Column centered style={{ flexGrow: 1 }}>
                <Row centered>
                    <ResponsiveModalTitle>{setupFailed ? 'Connection Error' : `You're all set!`}</ResponsiveModalTitle>
                </Row>
                <Spacing factor={1 / 3} />
                {setupFailed ? (
                    <KnowledgeBaseSetupFailedModalContent knowledgeBaseIntegration={knowledgeBaseIntegration} />
                ) : (
                    <KnowledgeBaseSetupSuccessModalContent knowledgeBaseIntegration={knowledgeBaseIntegration} />
                )}
            </Column>
            <Row centered>
                <PrimaryButton title="Done" onClick={onClose} />
            </Row>
        </SpinachModalContent>
    );
}

export function KnowledgeBaseSetupCompleteModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const globalModalState = useGlobalModalState();

    const globalModalMetadata = globalModalState.metadata;
    const track = useExperienceTracking();

    if (globalModalMetadata?.metadataType !== GlobalModalMetadataType.KnowledgeBaseSetup) {
        return <></>;
    }
    const { setupFailed, knowledgeBaseIntegration } = globalModalMetadata;

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Google Drive Completed Source Modal',
            FolderId: user.integrationSettings?.googleSettings?.googleDriveSummaryFolderId,
        });
        setGlobalModal(null);
    };

    if (!user) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.KnowledgeBaseSetupComplete} onClose={closeModal}>
            <KnowledgeBaseSetupCompleteModalContent
                onClose={closeModal}
                setupFailed={setupFailed}
                knowledgeBaseIntegration={knowledgeBaseIntegration}
            />
        </Modal>
    );
}
