import { Box, Modal, TextField } from '@material-ui/core';
import { CallMade } from '@material-ui/icons';
import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { ClientEventType, FeatureToggle, SpinachIntegration, TicketSource } from '@spinach-shared/types';
import { capitalizeString, isTicketSource } from '@spinach-shared/utils';

import { PrimaryButton, UpgradeLockIcon } from '../../../../..';
import { ReactComponent as AsanaLogo } from '../../../../assets/Asana-logo.svg';
import { ReactComponent as ClickUpLogo } from '../../../../assets/clickup-icon.svg';
import { ReactComponent as JiraLogo } from '../../../../assets/jira-logo.svg';
import { ReactComponent as LinearLogo } from '../../../../assets/linear-logo.svg';
import { ReactComponent as MondayDotComLogo } from '../../../../assets/monday-dot-com.svg';
import { ReactComponent as TrelloLogo } from '../../../../assets/trello-logo.svg';
import { ElementId } from '../../../../constants';
import { useExperienceTracking, useGlobalAuthedUser, useIntegrationDetection, useWindowSize } from '../../../../hooks';
import { BodyRegularOnboard, ButtonSize, HeaderThree, lightTheme, responsiveness } from '../../../../styles';
import { openTicketSourceAuth } from '../../../../utils';
import { BootstrapTooltip, CloseButton, Column, Row, Spacing, SpinachModalContent } from '../../../common';
import { JiraIntegrationSettings } from '../../../common/settings/JiraIntegrationSettings';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { IntegrationSettingsModalKind, IntegrationSettingsModalMetadata, UseHoverRowProps } from '../types';
import { ConnectedTicketSourceRowContents } from './ConnectedTicketSourceRowContents';
import { ComingSoonBanner } from './KnowledgeBaseIntegrationSection';
import { MondayApiKeyModel } from './MondayApiKeyModel';
import { Label } from './common';

const glow = keyframes`
    50% { 
        box-shadow: 0px 0px 20px 5px ${lightTheme.primary.orangeLight};
    }
`;
const FocusedHeader = styled(HeaderThree)`
    &:focus {
        animation: ${glow} 1s;
        animation-iteration-count: 2;
    }
`;

const StaticSizeLabel = styled(Label)`
    min-width: 75px;
`;

export const Body = styled(BodyRegularOnboard)`
    @media ${responsiveness.sm} {
        font-size: 14px;
    }
`;

export function AsanaIntegrationSection({
    hoverRowProps,
    setModalKind,
    showProjectSelectionModal = true,
}: {
    hoverRowProps: UseHoverRowProps;
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    showProjectSelectionModal?: boolean;
}): JSX.Element {
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const ticketSourceHoveredRow = isTicketSource(hoveredRow) ? hoveredRow : null;
    const { isDesktopView } = useWindowSize();

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Asana)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="Asana" />
            ) : isDesktopView ? (
                <AsanaLogo style={{ minWidth: '24px', maxWidth: '24px', height: '24px', marginRight: '15px' }} />
            ) : (
                <></>
            )}
            <StaticSizeLabel style={{ opacity: user.isOnNewStarter ? 0.5 : 1 }}>Asana</StaticSizeLabel>
            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForAsana ? (
                <ConnectedTicketSourceRowContents
                    label={'Connected'}
                    hoveredRow={ticketSourceHoveredRow}
                    ticketSource={SpinachIntegration.Asana}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsAsana);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Settings Asana Modal`,
                        });
                    }}
                />
            ) : (
                <ConnectRowContents
                    onConnected={() => setModalKind(IntegrationSettingsModalKind.SettingsAsana)}
                    showProjectSelectionModal={showProjectSelectionModal}
                    ticketSource={SpinachIntegration.Asana}
                />
            )}
            <span style={{ flexGrow: 1 }} />
        </Row>
    );
}

export function JiraIntegrationSettingsModal({
    isJiraSettingsOpen,
    setIsJiraSettingsOpen,
}: {
    isJiraSettingsOpen: boolean;
    setIsJiraSettingsOpen: (val: boolean) => void;
}) {
    function onClose() {
        setIsJiraSettingsOpen(false);
    }

    if (!isJiraSettingsOpen) {
        return <></>;
    }

    return (
        <Modal open={isJiraSettingsOpen} onClose={onClose}>
            <SpinachModalContent
                onClose={onClose}
                style={{
                    maxWidth: '80%',
                    padding: '20px',
                    minHeight: 'unset',
                    overflowY: 'hidden',
                }}
            >
                <JiraIntegrationSettings />
            </SpinachModalContent>
        </Modal>
    );
}

export function JiraIntegrationSection({
    hoverRowProps,
    setModalKind,
    setModalMetadata,
    showProjectSelectionModal = true,
}: {
    hoverRowProps: UseHoverRowProps;
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    setModalMetadata?: (metadata: IntegrationSettingsModalMetadata) => void;
    showProjectSelectionModal?: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { isDesktopView } = useWindowSize();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const ticketSourceHoveredRow = isTicketSource(hoveredRow) ? hoveredRow : null;

    const isUsingOutdatedJiraIntegration =
        user.isAuthedForJira && !user.integrationSettings?.jiraSettings?.hasCreateScopes;

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Jira)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="Jira" />
            ) : isDesktopView ? (
                <JiraLogo style={{ minWidth: '24px', maxWidth: '24px', height: '24px', marginRight: '15px' }} />
            ) : (
                <></>
            )}
            <StaticSizeLabel style={{ opacity: user.isOnNewStarter ? 0.5 : 1 }}>Jira</StaticSizeLabel>
            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForJira ? (
                <ConnectedTicketSourceRowContents
                    attentionRequired={isUsingOutdatedJiraIntegration}
                    label={
                        <BootstrapTooltip
                            title={`${user.integrationSettings?.jiraSettings?.jiraAccountUrl?.replace('https://', '')}`}
                        >
                            <Body>Connected</Body>
                        </BootstrapTooltip>
                    }
                    hoveredRow={ticketSourceHoveredRow}
                    ticketSource={SpinachIntegration.Jira}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsJira);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Remove Jira Modal`,
                        });
                    }}
                />
            ) : (
                <ConnectRowContents
                    onConnected={() => {
                        if (setModalMetadata) {
                            setModalMetadata({
                                [IntegrationSettingsModalKind.SettingsJira]: {
                                    isFirstTimeAuth: true,
                                },
                            });
                        }

                        setModalKind(IntegrationSettingsModalKind.SettingsJira);
                    }}
                    showProjectSelectionModal={showProjectSelectionModal}
                    ticketSource={SpinachIntegration.Jira}
                />
            )}

            <span style={{ flexGrow: 1 }} />
        </Row>
    );
}

export function LinearIntegrationSection({
    hoverRowProps,
    setModalKind,
    showProjectSelectionModal = true,
    isComingSoon = false,
}: {
    hoverRowProps: UseHoverRowProps;
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    showProjectSelectionModal?: boolean;
    isComingSoon?: boolean;
}) {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { isDesktopView } = useWindowSize();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const ticketSourceHoveredRow = isTicketSource(hoveredRow) ? hoveredRow : null;

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Linear)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="Linear" />
            ) : isDesktopView ? (
                <LinearLogo style={{ minWidth: '24px', maxWidth: '24px', height: '24px', marginRight: '15px' }} />
            ) : (
                <></>
            )}
            <StaticSizeLabel style={{ opacity: user.isOnNewStarter ? 0.5 : 1 }}>Linear</StaticSizeLabel>
            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForLinear && !isComingSoon ? (
                <ConnectedTicketSourceRowContents
                    label={'Connected'}
                    hoveredRow={ticketSourceHoveredRow}
                    ticketSource={SpinachIntegration.Linear}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsLinear);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Remove Linear Modal`,
                        });
                    }}
                />
            ) : (
                <ConnectRowContents
                    onConnected={() => setModalKind(IntegrationSettingsModalKind.SettingsLinear)}
                    isComingSoon={isComingSoon}
                    showProjectSelectionModal={showProjectSelectionModal}
                    ticketSource={SpinachIntegration.Linear}
                />
            )}
            <span style={{ flexGrow: 1 }} />
        </Row>
    );
}

export function ClickUpIntegrationSection({
    setModalKind,
    hoverRowProps,
    isComingSoon = false,
    showProjectSelectionModal = true,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
    isComingSoon?: boolean;
    showProjectSelectionModal?: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { isDesktopView } = useWindowSize();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const ticketSourceHoveredRow = isTicketSource(hoveredRow) ? hoveredRow : null;

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Clickup)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="ClickUp" />
            ) : isDesktopView ? (
                <ClickUpLogo
                    style={{
                        minWidth: '38px',
                        maxWidth: '38px',
                        height: '40px',
                        marginLeft: '-7px',
                        marginTop: '-5px',
                        marginRight: '8px',
                        marginBottom: '-5px',
                    }}
                />
            ) : (
                <></>
            )}
            <StaticSizeLabel style={{ opacity: user.isOnNewStarter ? 0.5 : 1 }}>ClickUp</StaticSizeLabel>
            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForClickUp && !isComingSoon ? (
                <ConnectedTicketSourceRowContents
                    label={'Connected'}
                    ticketSource={SpinachIntegration.Clickup}
                    hoveredRow={ticketSourceHoveredRow}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsClickUp);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Remove ClickUp Modal`,
                        });
                    }}
                />
            ) : (
                <ConnectRowContents
                    onConnected={() => setModalKind(IntegrationSettingsModalKind.SettingsClickUp)}
                    isComingSoon={isComingSoon}
                    showProjectSelectionModal={showProjectSelectionModal}
                    ticketSource={SpinachIntegration.Clickup}
                />
            )}
            <span style={{ flexGrow: 1 }} />
        </Row>
    );
}

export function TrelloIntegrationSection({
    setModalKind,
    hoverRowProps,
    isComingSoon = false,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
    isComingSoon?: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { isDesktopView } = useWindowSize();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const ticketSourceHoveredRow = isTicketSource(hoveredRow) ? hoveredRow : null;

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Trello)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="Trello" />
            ) : isDesktopView ? (
                <TrelloLogo style={{ minWidth: '24px', maxWidth: '24px', height: '24px', marginRight: '15px' }} />
            ) : (
                <></>
            )}
            <StaticSizeLabel style={{ opacity: user.isOnNewStarter ? 0.5 : 1 }}>Trello</StaticSizeLabel>
            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForTrello && !isComingSoon ? (
                <ConnectedTicketSourceRowContents
                    label={'Connected'}
                    ticketSource={SpinachIntegration.Trello}
                    hoveredRow={ticketSourceHoveredRow}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsTrello);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Remove Trello Modal`,
                        });
                    }}
                />
            ) : (
                <ConnectRowContents
                    onConnected={() => setModalKind(IntegrationSettingsModalKind.SettingsTrello)}
                    isComingSoon={isComingSoon}
                    ticketSource={SpinachIntegration.Trello}
                />
            )}
            <span style={{ flexGrow: 1 }} />
        </Row>
    );
}

const shouldPreferMondaySsoFlow = window.location.search.includes('mondaySso');

export function MondayDotComIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const track = useExperienceTracking();
    const { isDesktopView } = useWindowSize();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const ticketSourceHoveredRow = isTicketSource(hoveredRow) ? hoveredRow : null;
    const [showApiKeyModal, setShowApiKeyModal] = useState(false);

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.MondayDotCom)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="Monday.com" />
            ) : isDesktopView ? (
                <MondayDotComLogo style={{ minWidth: '24px', maxWidth: '24px', height: '24px', marginRight: '15px' }} />
            ) : (
                <></>
            )}
            <StaticSizeLabel style={{ opacity: user.isOnNewStarter ? 0.5 : 1 }}>Monday.com</StaticSizeLabel>
            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForMondayDotCom ? (
                <ConnectedTicketSourceRowContents
                    label={'Connected'}
                    ticketSource={SpinachIntegration.MondayDotCom}
                    hoveredRow={ticketSourceHoveredRow}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsMondayDotCom);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Remove Monday Modal`,
                        });
                    }}
                />
            ) : (
                <ConnectRowContents
                    onConnected={() => setModalKind(IntegrationSettingsModalKind.SettingsMondayDotCom)}
                    customConnectOnClick={shouldPreferMondaySsoFlow ? undefined : () => setShowApiKeyModal(true)}
                    isComingSoon={false}
                    ticketSource={SpinachIntegration.MondayDotCom}
                />
            )}
            <MondayApiKeyModel
                isOpen={showApiKeyModal}
                onClose={() => setShowApiKeyModal(false)}
                onSuccess={() => {
                    setModalKind(IntegrationSettingsModalKind.SettingsMondayDotCom);
                }}
            />
            <span style={{ flexGrow: 1 }} />
        </Row>
    );
}

export function TicketingIntegrationSection({
    setModalKind,
    setModalMetadata,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    setModalMetadata: (metadata: IntegrationSettingsModalMetadata) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    return (
        <Column>
            <Row>
                <FocusedHeader tabIndex={-1} id={ElementId.AISettingsTicketingSection}>
                    Ticketing
                </FocusedHeader>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Automatically link and create tickets</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <JiraIntegrationSection
                setModalMetadata={setModalMetadata}
                setModalKind={setModalKind}
                hoverRowProps={hoverRowProps}
            />
            <Spacing factor={1 / 2} />
            <AsanaIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
            <Spacing factor={1 / 2} />
            <LinearIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
            <Spacing factor={1 / 2} />
            <ClickUpIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
            <Spacing factor={1 / 2} />
            <TrelloIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
            <Spacing factor={1 / 2} />
            {user.featureToggles[FeatureToggle.MondayDotComUI] ? (
                <MondayDotComIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
            ) : null}
        </Column>
    );
}

export function ConnectRowContents({
    ticketSource,
    onConnected,
    isComingSoon = false,
    showProjectSelectionModal = true,
    customConnectOnClick,
}: {
    ticketSource: TicketSource;
    onConnected: () => void;
    isComingSoon?: boolean;
    showProjectSelectionModal?: boolean;
    customConnectOnClick?: () => void;
}) {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();
    const startDetection = useIntegrationDetection(() => {
        if (showProjectSelectionModal && !!user.actionItemCreationEnabledTicketSources.includes(ticketSource)) {
            onConnected();
        }
    });
    return isComingSoon ? (
        <ComingSoonBanner />
    ) : (
        <OutlinedButton
            title={'Connect'}
            onClick={() => {
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: `${capitalizeString(ticketSource)} Connect`,
                });
                if (customConnectOnClick) {
                    customConnectOnClick();
                } else {
                    openTicketSourceAuth(ticketSource, user.spinachUserId);
                    startDetection();
                }
            }}
            size={ButtonSize.Mini}
            endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
            labelStyles={{
                display: 'flex',
                alignItems: 'center',
            }}
        />
    );
}
