import { Box } from '@material-ui/core';
import { CallMade } from '@material-ui/icons';
import React, { useState } from 'react';

import { ClientEventType, SlackTeamType } from '@spinach-shared/types';
import { StoredSpinachSeries } from '@spinach-shared/utils';

import { deleteSlackSeriesSettings } from '../../../../apis/deleteSlackSeriesSettings';
import { postSlackChannel } from '../../../../apis/postSlackChannel';
import { GlobalModal } from '../../../../atoms';
import {
    useEmailSummaryToInviterOnly,
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useGlobalNullableStoredSeries,
    useIntegrationDetection,
    useProFeatures,
} from '../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { useSelectSummaryAccess } from '../../../../hooks/useSelectSummaryAccess';
import { useGlobalSlack } from '../../../../hooks/useSlack';
import { BodyRegularOnboard, ButtonSize, lightTheme } from '../../../../styles';
import { ClientLogger, URLUtil } from '../../../../utils';
import { Anchor, Column, Row } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { EmailOptions } from '../EmailOptions';
import { SlackChannelSelection } from '../integrations/SlackChannelSelection';
import { SettingsContent, SettingsSubTitle } from './CombinedMeetingDetails';

export function MeetingCommunicationDetails({ storedSeries }: { storedSeries: StoredSpinachSeries }): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { storedSeriesListState, setStoredSeriesList } = useGlobalStoredSeriesList();

    const hasProFeatures = useProFeatures();
    const { slackState } = useGlobalSlack();
    const startDetection = useIntegrationDetection();
    const { setToastText } = useGlobalAiDashboard();

    const isEmailInviterSummaryInviterOnly = useEmailSummaryToInviterOnly();
    const [, setGlobalModal] = useGlobalModal();
    const navigateToUpgrade = useNavigateToUpgrade();

    const track = useExperienceTracking();

    const [, setGlobalStoredSeries] = useGlobalNullableStoredSeries();
    let preseelectedChannel: { code: string; label: string; isPrivate?: boolean } | null = null;
    if (storedSeries.slackChannel?.channelId && storedSeries.slackChannel?.channelName) {
        preseelectedChannel = {
            code: storedSeries.slackChannel?.channelId,
            label: storedSeries.slackChannel?.channelName,
            isPrivate: storedSeries.slackChannel.isPrivate,
        };
    }

    const [selectedChannel, setSelectedChannel] = useState<{ code: string; label: string; isPrivate?: boolean } | null>(
        preseelectedChannel
    );
    const { isEmailingIcpOnly, onSelect: onSelectSummaryAccess } = useSelectSummaryAccess({
        storedSeries,
        from: 'Meeting Section',
    });

    return (
        <>
            <SettingsSubTitle>{'Summary access'}</SettingsSubTitle>
            <SettingsContent>
                <Row style={{ alignItems: 'center', flexWrap: 'wrap' }}>
                    <Box marginY="auto" marginRight="5px">
                        {'Dashboard and Emails:'}
                    </Box>
                    <BodyRegularOnboard>
                        <>
                            {/* if some folks received early access to email inviter only but havent paid yet, still give them access */}
                            {hasProFeatures || isEmailInviterSummaryInviterOnly ? (
                                <EmailOptions
                                    isEmailingIcpOnlySetting={isEmailingIcpOnly}
                                    series={storedSeries}
                                    onSelect={onSelectSummaryAccess}
                                />
                            ) : (
                                <>
                                    <span>All participants</span> (
                                    <Anchor
                                        onClick={() => {
                                            navigateToUpgrade('Click Upgrade Email Options (Meeting Section)');
                                        }}
                                    >
                                        Upgrade
                                    </Anchor>{' '}
                                    to limit)
                                </>
                            )}
                        </>
                    </BodyRegularOnboard>
                </Row>
                <Row>
                    <SecondaryButton
                        labelStyles={{ fontWeight: 'normal' }}
                        color={lightTheme.primary.midnight}
                        size={ButtonSize.Small}
                        title={`Manage Additional Recipients (${storedSeries.dailySubscribedEmails.length})`}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Manage additional recipients (Meeting Section)',
                            });
                            setGlobalStoredSeries(storedSeries);
                            setGlobalModal(GlobalModal.SubscribeSeriesOutput);
                        }}
                    />
                </Row>
            </SettingsContent>
            <SettingsSubTitle>{'Slack Settings'}</SettingsSubTitle>

            <SettingsContent>
                <Row>
                    {user.isAuthedForSlack ? (
                        <>
                            <Column>
                                <Row style={{ alignItems: 'center' }}>
                                    <Box marginY="auto" marginRight="5px">
                                        {'Send summaries to:'}
                                    </Box>
                                    <SlackChannelSelection
                                        from="MeetingCommunicationDetails"
                                        selectedChannel={selectedChannel}
                                        setSelectedChannel={setSelectedChannel}
                                        updateChannel={async (channel, slackSettings) => {
                                            try {
                                                setSelectedChannel(channel);

                                                track(ClientEventType.SelectSlackChannelInSettingsClick, {
                                                    Action: !!channel ? 'setting-channel' : 'removing-channel',
                                                    IsPrivate: false,
                                                });

                                                let updatedSeries;

                                                if (channel) {
                                                    updatedSeries = await postSlackChannel(
                                                        slackSettings.teamId,
                                                        slackSettings.teamType as SlackTeamType,
                                                        channel.code,
                                                        channel.label.replaceAll('#', ''),
                                                        storedSeries.slug,
                                                        false
                                                    );
                                                } else {
                                                    updatedSeries = await deleteSlackSeriesSettings(storedSeries.slug);
                                                }

                                                if (updatedSeries) {
                                                    const instance = new StoredSpinachSeries(updatedSeries);
                                                    const newList = storedSeriesListState.storedSeriesList.map(
                                                        (series) => {
                                                            if (series.id === instance.id) {
                                                                return instance;
                                                            } else {
                                                                return series;
                                                            }
                                                        }
                                                    );
                                                    setStoredSeriesList(newList);
                                                }
                                            } catch (e) {
                                                ClientLogger.error('Error updating slack settings', {
                                                    spinachUserId: user.spinachUserId,
                                                    error: e,
                                                });
                                                setToastText('Try again later');
                                            }
                                        }}
                                    />
                                </Row>
                            </Column>
                        </>
                    ) : (
                        <Column>
                            <Row>
                                <OutlinedButton
                                    containerStyles={{ marginLeft: '10px' }}
                                    title={'Connect'}
                                    disabled={!slackState.installUrl}
                                    onClick={() => {
                                        if (!slackState.installUrl) {
                                            return;
                                        }
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: `Connect to Slack via Meeting Details`,
                                        });
                                        startDetection();
                                        URLUtil.openURL(slackState.installUrl);
                                    }}
                                    size={ButtonSize.Mini}
                                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                                    labelStyles={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                />
                            </Row>
                        </Column>
                    )}
                </Row>
            </SettingsContent>
        </>
    );
}
