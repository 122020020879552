import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import { ClientSocketEvent, VideoAgentControlCommand } from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import SpinachLogoImage from '../../assets/logo-spinachio.svg';
import { useGlobalAuthedUser, useGlobalVideoAgent } from '../../hooks';
import { AgendaContent } from './AgendaContent';
import { BottomNavigation } from './BottomNavigation';
import { MeetingSelection } from './MeetingSelection';
import { MeetingWideControls } from './MeetingWideControls';
import { TabContent } from './TabContent';

const SpinachLogo = () => (
    <div
        style={{
            textAlign: 'center',
            marginBottom: '20px',
        }}
    >
        <img
            src={SpinachLogoImage}
            alt="SpinachAI"
            style={{
                height: '24px',
                verticalAlign: 'middle',
                marginLeft: '5px',
            }}
        />
    </div>
);

const ControlContainer = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

interface MeetingOption {
    botId: string;
    videoToken: string;
    seriesId: string;
    meetingTitle: string;
}

export function VideoAgentControlPanel({
    botId,
    videoToken,
    seriesId,
    socket,
    availableMeetings,
}: {
    botId: string;
    videoToken: string;
    seriesId: string;
    socket: Socket;
    availableMeetings: MeetingOption[];
}) {
    const { session } = useGlobalVideoAgent();
    const [user] = useGlobalAuthedUser();

    const sendCommand = (command: VideoAgentControlCommand) => {
        if (isLocalStage()) {
            console.log('Sending command via postMessage:', command);
            if (window.opener) {
                window.opener.postMessage({ type: 'local.VideoAgentControlCommand', command }, '*');
            } else {
                console.error('No parent window found. Make sure the control page is opened as a popup.');
            }
        } else if (socket && botId) {
            socket.emit(ClientSocketEvent.VideoAgentControlling, { botId, ...command });
        }
    };

    return (
        <ControlContainer>
            <SpinachLogo />

            <MeetingSelection botId={botId} availableMeetings={availableMeetings} />

            <MeetingWideControls onCommand={sendCommand} />

            {user.isEnabledForVideoAgentAiControlChat ? (
                <TabContent onCommand={sendCommand} />
            ) : (
                <AgendaContent onCommand={sendCommand}  />
            )}

            <BottomNavigation onCommand={sendCommand} />
        </ControlContainer>
    );
}
