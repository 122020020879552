import { Modal } from '@material-ui/core';
import { CalendarToday, Check as CheckIcon } from '@material-ui/icons';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientEventType, WebUrlQuery } from '@spinach-shared/types';

import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal } from '../../../hooks';
import { BodyBig, BodyRegular, BodyRegularOnboard, HeaderThreeOnboard } from '../../../styles';
import { PrimaryButton } from '../../stand-up';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Hairline, Row } from '../framing';

export function ActivateTrialModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();
    const [, setSearchParams] = useSearchParams();

    const closeModal = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Activate Trial Modal',
        });
        setGlobalModal(null);
    };

    if (globalModal !== GlobalModal.ActivateTrial || user.isOnLiveReverseTrial) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.ActivateTrial} onClose={closeModal}>
            <SpinachModalContent onClose={closeModal} style={{ overflow: 'hidden', minHeight: 'unset' }}>
                <Column>
                    <HeaderThreeOnboard style={{ textAlign: 'center', marginBottom: '16px' }}>
                        Activate your {user.restartedTrialDaysLong}-day trial
                    </HeaderThreeOnboard>
                    <BodyRegular style={{ textAlign: 'center', marginBottom: '32px' }}>
                        To kick off 2025 we're offering an extended trial to try Spinach Pro!
                    </BodyRegular>

                    <Column style={{ gap: '5px', marginBottom: '32px', maxWidth: '400px' }}>
                        <Row>
                            <BodyRegular style={{ fontWeight: 600 }}>What you get:</BodyRegular>
                        </Row>
                        <Row style={{ alignItems: 'center', gap: '12px' }}>
                            <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                            <BodyRegularOnboard>Unlimited meeting hours</BodyRegularOnboard>
                        </Row>

                        <Row style={{ alignItems: 'center', gap: '12px' }}>
                            <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                            <BodyRegularOnboard>Advanced AI Summaries</BodyRegularOnboard>
                        </Row>

                        <Row style={{ alignItems: 'center', gap: '12px' }}>
                            <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                            <BodyRegularOnboard>Ask Spinach - AI assistant</BodyRegularOnboard>
                        </Row>

                        <Row style={{ alignItems: 'center', gap: '12px' }}>
                            <CheckIcon style={{ color: '#4CAF50', fontSize: '20px' }} />
                            <BodyRegularOnboard>All integrations</BodyRegularOnboard>
                        </Row>
                        <Hairline />

                        <Row style={{ alignItems: 'center', gap: '12px', marginBottom: '32px' }}>
                            <CalendarToday style={{ color: '#4CAF50', fontSize: '24px' }} />
                            <Column>
                                <BodyRegular style={{ fontSize: '18px' }}>
                                    Spinach will be added to your meetings
                                </BodyRegular>
                                <BodyRegular>
                                    (you can modify this in the <b>Future Meetings</b> page)
                                </BodyRegular>
                            </Column>
                        </Row>
                    </Column>

                    <PrimaryButton
                        onClick={async () => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Start Trial from Activate Trial Modal',
                            });
                            setSearchParams({ [WebUrlQuery.StartTrial]: 'true' });
                            // TODO unsure if we still need this
                            setGlobalModal(null);
                        }}
                        title="Start Trial"
                        labelStyles={{
                            paddingLeft: '24px',
                            paddingRight: '24px',
                        }}
                    />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}
