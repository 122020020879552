import { useGlobalAuthedUser } from '../../../..';
import { restartTrialGuidePacket, starterGuidePacket } from './slides';
import { ShowcasePacket } from './types';

export function useShowcaseGuidePacket(): ShowcasePacket | null {
    const [user] = useGlobalAuthedUser();

    // If user is eligible for restart trial promo, show only that guide
    if (user.shouldShowRestartTrialGuide) {
        return restartTrialGuidePacket;
    }

    // Otherwise, show starter guide if enabled
    if (!user.isEnabledForStarterGuide) {
        return null;
    }

    return starterGuidePacket;
}
