import { CalendarToday, Done } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { WebUrlQuery } from '@spinach-shared/types';

import { Column, LoginWithGoogle } from '..';
import { ClientLogger, HeaderThreeOnboard, lightTheme } from '../..';
import { BodyRegular } from '../..';
import { ReactComponent as LogoSpinachio } from '../../assets/logo-spinachio.svg';
import { ViewContainer } from '../common/OnboardContainer';
import { SpinachStationary } from '../common/Stationary';
import { Row, Spacing } from '../common/framing';
import { LoginWithMicrosoft } from './LoginWithMicrosoft';

const FeatureList = styled.ul`
    list-style-type: none;
    padding: 0;
`;

const HorizontalDivider = styled.hr`
    background: #dfe5e5;
    color: #dfe5e5;
    width: 100%;
    max-width: 360px;
    height: 1px;
    border-style: none;
`;

export function ActivateTrialRoute() {
    const [_, setSearchParams] = useSearchParams();
    const [microsoftState, setMicrosoftState] = useState<string>();

    useEffect(() => {
        try {
            const base64 = btoa(`?${WebUrlQuery.StartTrial}=true`);
            const urlSafeBase64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
            setMicrosoftState(urlSafeBase64);
        } catch (error) {
            ClientLogger.error('Failed to encode state', error);
        }
    }, []);

    const featureList = [
        'Unlimited users',
        'Unlimited meeting hours',
        'Advanced AI Summaries',
        'Ask Spinach - AI assistant',
        'All integrations',
    ];
    return (
        <ViewContainer>
            <SpinachStationary style={{ maxWidth: 500, boxSizing: 'border-box' }}>
                <LogoSpinachio style={{ height: '35px' }} />

                <Spacing />
                <HeaderThreeOnboard style={{ fontSize: '26px' }}>Activate your 30-day trial</HeaderThreeOnboard>

                <Column style={{ maxWidth: '360px' }}>
                    <Spacing factor={1 / 2} />
                    <BodyRegular style={{ textAlign: 'left' }}>
                        To kick off 2025 we're offering an extended trial to try Spinach Pro!
                    </BodyRegular>
                </Column>
                <Spacing factor={1 / 2} />

                <LoginWithGoogle
                    variant="orange"
                    label="Continue with Google"
                    onSuccessCallback={() => {
                        setSearchParams((params) => {
                            params.set(WebUrlQuery.StartTrial, 'true');
                            return params;
                        });
                    }}
                    buttonStyle={{ maxWidth: '360px', minWidth: '200px', width: '70vw', justifyContent: 'center' }}
                />
                <Spacing factor={1 / 3} />
                <LoginWithMicrosoft
                    variant="orange"
                    label="Continue with Microsoft"
                    buttonStyle={{ maxWidth: '360px', minWidth: '200px', width: '70vw', justifyContent: 'center' }}
                    state={microsoftState}
                />

                <Spacing factor={1 / 2} />

                <Row style={{ maxWidth: '360px' }} vCenter={true}>
                    <CalendarToday style={{ marginRight: 12 }} />
                    <BodyRegular>Spinach will be added to your meetings. (Customize to your preference)</BodyRegular>
                </Row>
                <Spacing factor={1 / 3} />
                <HorizontalDivider />
                <FeatureList>
                    {featureList.map((feature) => (
                        <li key={feature}>
                            <Row vCenter={true} style={{ marginTop: '1px', marginBottom: '1px' }}>
                                <Done style={{ marginRight: 12, color: lightTheme.primary.greenLight }} />
                                <BodyRegular style={{ fontSize: '16px' }}>{feature}</BodyRegular>
                            </Row>
                        </li>
                    ))}
                </FeatureList>
            </SpinachStationary>
        </ViewContainer>
    );
}
