import { CallMade, InfoOutlined } from '@material-ui/icons';
import { useEffect, useState } from 'react';

import {
    ClientEventType,
    SpinachAPIPath,
    SpinachIntegration,
    SpinachIntegrations,
    WebUrlQuery,
} from '@spinach-shared/types';

import AttioExample from '../../../../assets/integrations/attio-example.png';
import AttioLogo from '../../../../assets/integrations/attio.png';
import HubSpotExample from '../../../../assets/integrations/hubspot-example.png';
import { ReactComponent as HubspotLogo } from '../../../../assets/integrations/hubspot.svg';
import SalesforceExample from '../../../../assets/integrations/salesforce-example.png';
import SalesforceLogo from '../../../../assets/integrations/salesforce.png';
import ZohoExample from '../../../../assets/integrations/zoho-example.png';
import ZohoLogo from '../../../../assets/integrations/zoho.png';
import { useIntegrationDetection } from '../../../../hooks';
import { useExperienceTracking, useGlobalAuthedUser } from '../../../../hooks';
import { BodyRegularOnboard, HeaderThree, lightTheme } from '../../../../styles';
import { ButtonSize } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { getClientConfigValue } from '../../../../utils/getClientConfigValue';
import { BetaFeatureTag, BootstrapTooltip, Row, Spacing, UpgradeLockIcon } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { IntegrationSettingsModalKind, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { Label } from './common';

const PreloadedImage = ({ src }: { src: string }): JSX.Element => {
    /** @NOTE preloads images to avoid weird flickering when they attempt to load in tooltips */
    useEffect(() => {
        const img = new Image();
        img.src = src;
    }, [src]);
    return <></>;
};

export function HubspotIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.HubSpot)}>
            <PreloadedImage src={HubSpotExample} />
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="HubSpot" />
            ) : (
                <HubspotLogo style={{ width: '24px', height: '24px', marginRight: '15px' }} />
            )}
            <Label
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    opacity: user.isOnNewStarter ? 0.5 : 1,
                }}
            >
                {SpinachIntegrations[SpinachIntegration.HubSpot].displayName}
                <Row style={{ width: 'unset' }}>
                    <BootstrapTooltip
                        PopperProps={{
                            style: {
                                border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            },
                        }}
                        arrowStyle={{
                            border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            backgroundColor: lightTheme.neutrals.white,
                            color: lightTheme.neutrals.white,
                        }}
                        tooltipStyle={{
                            maxWidth: 'unset',
                            padding: '25px',
                            margin: 'unset',
                            backgroundColor: lightTheme.neutrals.white,
                        }}
                        title={
                            <>
                                <Row style={{ width: 'unset' }} centered>
                                    <img
                                        src={HubSpotExample}
                                        style={{
                                            maxWidth: '350px',
                                            maxHeight: '350px',
                                            flexShrink: 1,
                                        }}
                                    />
                                </Row>
                                <Spacing factor={1 / 3} />
                                <BodyRegularOnboard style={{ textAlign: 'center' }}>
                                    Automatically sync meeting notes to contact in HubSpot after the call
                                </BodyRegularOnboard>
                                {user.isEnabledForOrgLevelHubSpot ? (
                                    <BodyRegularOnboard>
                                        Note: <strong>Will apply to all users in your organization</strong>
                                    </BodyRegularOnboard>
                                ) : null}
                            </>
                        }
                    >
                        <InfoOutlined
                            style={{ marginLeft: '5px', color: lightTheme.primary.greenLight }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    </BootstrapTooltip>
                </Row>
            </Label>

            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForHubSpot ? (
                <ConnectedRowContents
                    label={`Connected`}
                    isSettingsVisible={hoveredRow === SpinachIntegration.HubSpot}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsHubSpot);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open HubSpot Settings Modal`,
                        });
                    }}
                />
            ) : (
                <OutlinedButton
                    title={'Connect'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `HubSpot Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.IntegrationAuth.replace(
                                ':oauthProvider',
                                SpinachIntegration.HubSpot
                            )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                            { redirectThroughBackendWhenOnZoom: true }
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            )}
        </Row>
    );
}

export function SalesforceIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Salesforce)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="Salesforce" />
            ) : (
                <>
                    <PreloadedImage src={SalesforceExample} />
                    <img src={SalesforceLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                </>
            )}
            <Label
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    opacity: user.isOnNewStarter ? 0.5 : 1,
                }}
            >
                {SpinachIntegrations[SpinachIntegration.Salesforce].displayName}
                <Row style={{ width: 'unset' }}>
                    <BootstrapTooltip
                        PopperProps={{
                            style: {
                                border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            },
                        }}
                        arrowStyle={{
                            border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            backgroundColor: lightTheme.neutrals.white,
                            color: lightTheme.neutrals.white,
                        }}
                        tooltipStyle={{
                            maxWidth: 'unset',
                            padding: '25px',
                            margin: 'unset',
                            backgroundColor: lightTheme.neutrals.white,
                        }}
                        title={
                            <>
                                <Row style={{ width: 'unset' }} centered>
                                    <img
                                        src={SalesforceExample}
                                        style={{
                                            maxWidth: '350px',
                                            maxHeight: '350px',
                                            flexShrink: 1,
                                        }}
                                    />
                                </Row>
                                <Spacing factor={1 / 3} />
                                <BodyRegularOnboard style={{ textAlign: 'center' }}>
                                    Automatically sync meeting notes to contact in Salesforce after the call
                                </BodyRegularOnboard>
                                {user.isEnabledForOrgLevelSalesforce ? (
                                    <BodyRegularOnboard>
                                        Note: <strong>Will apply to all users in your organization</strong>
                                    </BodyRegularOnboard>
                                ) : null}
                            </>
                        }
                    >
                        <InfoOutlined
                            style={{ marginLeft: '5px', color: lightTheme.primary.greenLight }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    </BootstrapTooltip>
                </Row>
            </Label>

            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForSalesforce ? (
                <ConnectedRowContents
                    label={`Connected`}
                    isSettingsVisible={hoveredRow === SpinachIntegration.Salesforce}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsSalesforce);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Salesforce Settings Modal`,
                        });
                    }}
                />
            ) : (
                <OutlinedButton
                    title={'Connect'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Salesforce Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.IntegrationAuth.replace(
                                ':oauthProvider',
                                SpinachIntegration.Salesforce
                            )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                            { redirectThroughBackendWhenOnZoom: true }
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            )}
        </Row>
    );
}

export function AttioIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Attio)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="Attio" />
            ) : (
                <>
                    <PreloadedImage src={AttioExample} />
                    <img src={AttioLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                </>
            )}
            <Label
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    opacity: user.isOnNewStarter ? 0.5 : 1,
                }}
            >
                {SpinachIntegrations[SpinachIntegration.Attio].displayName}
                <Row style={{ width: 'unset' }}>
                    <BootstrapTooltip
                        PopperProps={{
                            style: {
                                border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            },
                        }}
                        arrowStyle={{
                            border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            backgroundColor: lightTheme.neutrals.white,
                            color: lightTheme.neutrals.white,
                        }}
                        tooltipStyle={{
                            maxWidth: 'unset',
                            padding: '25px',
                            margin: 'unset',
                            backgroundColor: lightTheme.neutrals.white,
                        }}
                        title={
                            <>
                                <Row style={{ width: 'unset' }} centered>
                                    <img
                                        src={AttioExample}
                                        style={{
                                            maxWidth: '350px',
                                            maxHeight: '350px',
                                            flexShrink: 1,
                                        }}
                                    />
                                </Row>
                                <Spacing factor={1 / 3} />
                                <BodyRegularOnboard style={{ textAlign: 'center' }}>
                                    Automatically sync meeting notes to contact in Attio after the call
                                </BodyRegularOnboard>
                                {user.isEnabledForOrgLevelAttio ? (
                                    <BodyRegularOnboard>
                                        Note: <strong>Will apply to all users in your organization</strong>
                                    </BodyRegularOnboard>
                                ) : null}
                            </>
                        }
                    >
                        <InfoOutlined
                            style={{ marginLeft: '5px', color: lightTheme.primary.greenLight }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    </BootstrapTooltip>
                </Row>
            </Label>

            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForAttio ? (
                <ConnectedRowContents
                    label={`Connected`}
                    isSettingsVisible={hoveredRow === SpinachIntegration.Attio}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsAttio);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Attio Settings Modal`,
                        });
                    }}
                />
            ) : (
                <OutlinedButton
                    title={'Connect'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Attio Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.IntegrationAuth.replace(
                                ':oauthProvider',
                                SpinachIntegration.Attio
                            )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                            { redirectThroughBackendWhenOnZoom: true }
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            )}
        </Row>
    );
}

export function ZohoIntegrationSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();
    const startDetection = useIntegrationDetection();

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Zoho)}>
            {user.isOnNewStarter ? (
                <UpgradeLockIcon analyticsPrefix="Zoho" />
            ) : (
                <>
                    <PreloadedImage src={ZohoExample} />
                    <img src={ZohoLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                </>
            )}
            <Label
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    opacity: user.isOnNewStarter ? 0.5 : 1,
                }}
            >
                {SpinachIntegrations[SpinachIntegration.Zoho].displayName}
                <Row style={{ width: 'unset' }}>
                    <BootstrapTooltip
                        interactive
                        placement="top"
                        PopperProps={{
                            style: {
                                border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            },
                        }}
                        arrowStyle={{
                            border: `1px ${lightTheme.neutrals.grayDark} solid`,
                            backgroundColor: lightTheme.neutrals.white,
                            color: lightTheme.neutrals.white,
                        }}
                        tooltipStyle={{
                            maxWidth: 'unset',
                            padding: '25px',
                            margin: 'unset',
                            backgroundColor: lightTheme.neutrals.white,
                        }}
                        title={
                            <>
                                <Row style={{ width: 'unset' }} centered>
                                    <img
                                        src={ZohoExample}
                                        style={{
                                            maxWidth: '350px',
                                            maxHeight: '350px',
                                            flexShrink: 1,
                                        }}
                                    />
                                </Row>
                                <Spacing factor={1 / 3} />
                                <BodyRegularOnboard style={{ textAlign: 'center' }}>
                                    Automatically sync meeting notes to contact in Zoho after the call
                                </BodyRegularOnboard>
                                {user.isEnabledForOrgLevelZoho ? (
                                    <BodyRegularOnboard>
                                        Note: <strong>Will apply to all users in your organization</strong>
                                    </BodyRegularOnboard>
                                ) : null}
                            </>
                        }
                    >
                        <InfoOutlined
                            style={{ marginLeft: '5px', color: lightTheme.primary.greenLight }}
                            htmlColor={lightTheme.primary.greenLight}
                        />
                    </BootstrapTooltip>
                </Row>
            </Label>

            {user.isOnNewStarter ? (
                <></>
            ) : user.isAuthedForZoho ? (
                <ConnectedRowContents
                    label={`Connected`}
                    isSettingsVisible={hoveredRow === SpinachIntegration.Zoho}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsZoho);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Zoho Settings Modal`,
                        });
                    }}
                />
            ) : (
                <OutlinedButton
                    title={'Connect'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Zoho Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.IntegrationAuth.replace(
                                ':oauthProvider',
                                SpinachIntegration.Zoho
                            )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                            { redirectThroughBackendWhenOnZoom: true }
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            )}
        </Row>
    );
}

export function CRMIntegrationsSection({
    setModalKind,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;

    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();

    const [isLearnMoreHovered, setIsLearnMoreHovered] = useState(false);
    return (
        <>
            <Row vCenter>
                <HeaderThree>CRM Integrations</HeaderThree>
                <BetaFeatureTag />
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>
                    Sync your meeting data with your CRM (
                    <span
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            color: isLearnMoreHovered ? '#0068b1' : lightTheme.primary.midnight,
                        }}
                        onMouseEnter={() => {
                            setIsLearnMoreHovered(true);
                        }}
                        onMouseLeave={() => setIsLearnMoreHovered(false)}
                        onClick={() => window.Intercom?.('showArticle', '9961431')}
                    >
                        learn more
                    </span>
                    )
                </BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            {user.isHubSpotEnabled ? (
                <>
                    <HubspotIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                    <Spacing factor={1 / 2} />
                </>
            ) : (
                <></>
            )}

            {user.isSalesforceEnabled ? (
                <>
                    <SalesforceIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                    <Spacing factor={1 / 2} />
                </>
            ) : (
                <></>
            )}
            {user.isAttioEnabled ? (
                <>
                    <AttioIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                    <Spacing factor={1 / 2} />
                </>
            ) : (
                <></>
            )}

            {user.isZohoEnabled ? (
                <>
                    <ZohoIntegrationSection setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
                    <Spacing factor={1 / 2} />
                </>
            ) : (
                <></>
            )}
        </>
    );
}
