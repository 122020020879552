import { Modal } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientEventType, SpinachAPIPath, WebUrlQuery } from '@spinach-shared/types';

import GoogleCalPermissionImage from '../../../assets/goog-cal-permission.png';
import { GlobalModal } from '../../../atoms';
import { useExperienceTracking, useGlobalAuthedUser, useGlobalModal, useIntegrationDetection } from '../../../hooks';
import { BodyRegular, BodyRegularOnboard, ButtonSize, HeaderThreeOnboard, lightTheme } from '../../../styles';
import { URLUtil } from '../../../utils';
import { getClientConfigValue } from '../../../utils/getClientConfigValue';
import { PrimaryButton } from '../../stand-up/PrimaryButton';
import SecondaryButton from '../../stand-up/SecondaryButton';
import { Badge } from '../ProBadge';
import { SpinachModalContent } from '../SpinachModalContent';
import { Column, Hairline, Row, Spacing } from '../framing';

export function NeedCalendarAccessToActivateTrialModal(): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const [globalModal, setGlobalModal] = useGlobalModal();
    const [hasCleanedUpQueryParamOnOpen, setHasCleanedUpQueryParamOnOpen] = useState(false);
    const track = useExperienceTracking();

    useEffect(() => {
        if (globalModal !== GlobalModal.CalendarAccessNeededForTrial) {
            return;
        }

        if (!hasCleanedUpQueryParamOnOpen) {
            // remove start-trial query param upon opening this modal
            const updatedSearchParams = new URLSearchParams(searchParams);
            updatedSearchParams.delete(WebUrlQuery.StartTrial);
            setSearchParams(updatedSearchParams);
            setHasCleanedUpQueryParamOnOpen(true);
        }
    }, [globalModal, searchParams, setSearchParams, hasCleanedUpQueryParamOnOpen]);

    async function onSuccessfulConnect() {
        // add start-trial query param
        await new Promise((resolve) => setTimeout(resolve, 2100));
        const updatedSearchParams = new URLSearchParams(searchParams);
        updatedSearchParams.set(WebUrlQuery.StartTrial, 'true');
        setSearchParams(updatedSearchParams);
        setGlobalModal(null);
        track(ClientEventType.AIDashboardActivity, {
            Activity: 'Calendar Upgraded via Activate Trial Flow',
        });
    }

    const startDetection = useIntegrationDetection(onSuccessfulConnect);
    const [isLoading, setIsLoading] = useState(false);

    const closeModal = () => {
        setGlobalModal(null);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Close Calendar Access Needed for Trial Modal',
        });
    };

    if (globalModal !== GlobalModal.CalendarAccessNeededForTrial) {
        return <></>;
    }

    return (
        <Modal open={globalModal === GlobalModal.CalendarAccessNeededForTrial} onClose={closeModal}>
            <SpinachModalContent style={{ overflow: 'hidden', minHeight: 'unset' }}>
                <Column style={{ padding: '24px', maxWidth: '400px' }}>
                    <Badge title="New" />
                    <Spacing factor={1 / 2} />
                    <HeaderThreeOnboard style={{ textAlign: 'center', marginBottom: '16px' }}>
                        Upgraded calendar integration
                    </HeaderThreeOnboard>
                    <BodyRegularOnboard>
                        We've significantly improved the performance, usability, and available features with our new
                        calendar integration.
                    </BodyRegularOnboard>
                    <Hairline />
                    <BodyRegularOnboard>
                        To access trial features, reconnect your calendar to add Spinach on your meetings.
                    </BodyRegularOnboard>

                    <Spacing factor={1 / 2} />

                    <Row style={{ marginBottom: '5px' }}>
                        <BodyRegular style={{ color: lightTheme.primary.green }}>Check on the next screen</BodyRegular>
                    </Row>

                    <Row style={{ alignItems: 'center', gap: '12px', justifyContent: 'center' }}>
                        <img
                            src={GoogleCalPermissionImage}
                            alt="Google Calendar permissions"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </Row>

                    <Spacing factor={1.5} />

                    <PrimaryButton
                        title="Connect Calendar"
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Upgrade Calendar for Trial',
                            });
                            setIsLoading(true);
                            startDetection();
                            URLUtil.openURL(
                                `${getClientConfigValue('REACT_APP_AUTH_URL')}${
                                    SpinachAPIPath.GoogleRecallV2CalendarAuth
                                }/?${WebUrlQuery.Suid}=${user.spinachUserId}&spinachSource=activateTrialModal`
                            );
                        }}
                        isLoading={isLoading}
                    />

                    <Spacing factor={1 / 2} />

                    <SecondaryButton
                        size={ButtonSize.Mini}
                        disabled={isLoading}
                        title="Don't activate the trial"
                        onClick={closeModal}
                        buttonStyles={{
                            justifyContent: 'center',
                            textDecoration: 'none',
                        }}
                    />
                </Column>
            </SpinachModalContent>
        </Modal>
    );
}
